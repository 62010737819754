import * as React from "react";
import Button from "../../../../components/atoms/Button";
import Modal from "../../../../components/atoms/Modal";
import NewContractTemplateCard from "../../../../components/organism/NewContractTemplateCard";
import { useContractTemplate } from "../../../../hooks/useContractTemplate";
import Swal from "sweetalert2";
import TextData from "../../../../components/atoms/TextData";
import Loading from "../../../../components/atoms/Loading";
import PopUpCard from "../../../../components/molecules/PopUpCard";

export default function UploadContractTemplate(
    props: uploadContractTemplateProps,
) {
    const [openPopup, setOpenPopup] = React.useState(false);
    const {
        newContractTemplate,
        setContractTemplate,
        postNewContractTemplate,
    } = useContractTemplate();
    const [file, setFile] = React.useState(null);
    const handleUploadFile = (file: any) => {
        setFile(file);
    };
    const handleTitle = (name: string) => {
        setContractTemplate({ name });
    };
    const handleType = (type: string) => {
        setContractTemplate({ type });
    };
    const handleOnCancelButton = () => {
        setOpenPopup(false);
    };
    const handleOnSubmitButton = async () => {
        if (newContractTemplate?.name && newContractTemplate?.type && file) {
            await setIsLoading(true);
            const res = await postNewContractTemplate(file);
            await setIsLoading(false);
            console.log(res);
            if (res?.error === false) {
                setOpenPopup(false);
                setContractTemplate({ name: "", type: "" });
                Swal.fire({
                    title: "Succés!",
                    text: "Votre modéle a été importé avec succés!",
                    icon: "success",
                });
            } else if (res?.error === true) {
                Swal.fire({
                    title: "Erreur!",
                    text: res?.message,
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            }
        } else {
            Swal.fire({
                title: "Erreur!",
                text: "Veuillez remplir tous les champs!",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
    };
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <div className="upload-contract-template">
            <Button
                onClick={() => setOpenPopup(true)}
                width="auto"
                height="auto"
                backgroundColor="#eaf4f7"
                textColor="#1a7290"
                padding="7px 15px"
            >
                <TextData variant="div">Importer un contrat</TextData>
            </Button>
            {isLoading ? (
                <Modal
                    modalEnabled={openPopup}
                    onClose={() => {
                        Swal.fire({
                            title: "Votre document est en cours de chargement. Merci de votre patience.",
                            icon: "warning",
                            timer: 2500,
                            timerProgressBar: true,
                        });
                    }}
                >
                    <PopUpCard
                        display={true}
                        onClose={() => {}}
                        header="Le modèle de contrat est en cours de chargement"
                        button1Text=""
                        button2Text=""
                        activateFooter={false}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "30vh",
                            }}
                        >
                            <Loading width="400px" height="150px" />
                        </div>
                    </PopUpCard>
                </Modal>
            ) : (
                <Modal
                    modalEnabled={openPopup}
                    onClose={() => setOpenPopup(false)}
                >
                    <NewContractTemplateCard
                        headerCardTitle="Importer un modéle de contrat"
                        display={openPopup}
                        onClose={() => setOpenPopup(false)}
                        onClickButton1={handleOnCancelButton}
                        onClickButton2={handleOnSubmitButton}
                        setUploadFile={handleUploadFile}
                        setTitle={handleTitle}
                        setType={handleType}
                    />
                </Modal>
            )}
        </div>
    );
}
export interface uploadContractTemplateProps {}
UploadContractTemplate.defaultProps = {};
