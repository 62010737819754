import React, { useEffect, useState } from "react";
import SelectInput from "../../components/atoms/SelectInput";
import moment from "moment";
import { useBranch } from "../../hooks/useBranch";
import ProgressBar from "../../components/molecules/ProgressBar/ProgressBar";
import AccordionBody from "./components/AccordionBody";
import FeuilleAccordion from "./components/FeuilleAccordion";
import { extractNumber } from "../../_helpers/Functions";
import DatePicker from "./components/DatePicker";
import AccorditionHeader from "./components/AccorditionHeader";
import NewDepartmentCard from "../../components/organism/NewDepartmentCard";
import Modal from "../../components/atoms/Modal";
import Button from "../../components/atoms/ZButton";
import { Signature } from "../../Icons/Signature";
import { Link } from "react-router-dom";

const FeuilleAmargement = () => {
    const [usedYear, setUsedYear] = useState<any>(moment().year());
    const [activeMonth, setActiveMonth] = useState<number | null>(null);
    const { selectedBranchSettings } = useBranch();

    const handleAccordionClick = (monthIndex: number | null) => {
        setActiveMonth(activeMonth === monthIndex ? null : monthIndex);
    };

    // const [signature, setSignature] = useState<number>(50); // Default value
    // const [signatureConsumed, setSignatureConsumed] = useState<number>(0); // Default value

    // Initialize state from local storage
    // useEffect(() => {
    //     const storedSignature = localStorage.getItem("signature");
    //     const storedSignatureConsumed =
    //         localStorage.getItem("signatureConsumed");

    //     if (storedSignature) {
    //         setSignature(parseInt(storedSignature, 10));
    //     } else {
    //         localStorage.setItem("signature", "50"); // Initialize if not present
    //     }

    //     if (storedSignatureConsumed) {
    //         setSignatureConsumed(parseInt(storedSignatureConsumed, 10));
    //     } else {
    //         localStorage.setItem("signatureConsumed", "0"); // Initialize if not present
    //     }
    // }, []);

    // const updateSignatureConsumed = (newSignatureConsumed: number) => {
    //     setSignatureConsumed((prev) => {
    //         const updatedValue = prev + newSignatureConsumed;
    //         localStorage.setItem("signatureConsumed", updatedValue.toString());
    //         return updatedValue;
    //     });
    // };

    if (!selectedBranchSettings._id) {
        return (
            <div
                style={{
                    marginTop: "20px",
                    padding: "20px",
                    backgroundColor: "rgb(173 235 255)",
                    borderRadius: "8px",
                    color: "#4c5690",
                }}
            >
                Veuillez sélectionner un établissement
            </div>
        );
    }
    return (
        <>
            <div className="w-full flex flex-col space-y-1 h-full">
                <div className="flex justify-between w-full items-center h-[80px] pl-5">
                    <div className="flex justify-start items-center space-x-10 ">
                        <h1 className="text-v-700 font-bold text-[34px]">
                            Feuille d'émargement
                        </h1>

                        <DatePicker
                            usedYear={usedYear}
                            setUsedYear={setUsedYear}
                        />
                    </div>
                    {/* {signature ? (
                        <>
                            <ProgressBar
                                value={signatureConsumed}
                                valueTotal={signature}
                                completedMessage="signatures utilisées"
                                incompletedMessage="signatures utilisées"
                            />
                        </>
                    ) : (
                        <>
                            <Link
                                to="/payement-signature"
                                className="rounded-lg flex items-center justify-center gap-1 text-default bg-n-0 hover:bg-n-0-hover py-3 px-5 w-auto border border-default"
                            >
                                <Signature /> Commander des signatures
                            </Link>
                        </>
                    )} */}
                </div>
                <div className="overflow-y-auto  flex flex-col space-y-2 pb-[40px]">
                    {moment.months().map((month, monthIndex) => (
                        <FeuilleAccordion
                            key={monthIndex}
                            header={
                                <AccorditionHeader
                                    month={month}
                                    year={usedYear}
                                    siteId={selectedBranchSettings._id}
                                />
                            }
                            bodyStyle="bg-white rounded-b-lg"
                            body={
                                <AccordionBody
                                    siteId={selectedBranchSettings._id}
                                    month={month}
                                    year={usedYear}
                                    // signature={signature}
                                    // updateSignatureConsumed={
                                    //     updateSignatureConsumed
                                    // }
                                />
                            }
                            isOpen={activeMonth === monthIndex}
                            onClick={() => handleAccordionClick(monthIndex)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
export default FeuilleAmargement;
