import React from "react";
import { CheckBox } from "../../../../Icons";

interface StatusButtonProps {
    progress?: { completed: number; total: number }; // For progress state
    onClick: (value: any) => void; // Optional click handler
    week: any;
    setSelectedWeek: (value: any) => void;
}

const StatusButton: React.FC<StatusButtonProps> = ({
    progress,
    onClick,
    week,
    setSelectedWeek,
}) => {
    return (
        <button
            onClick={() => {
                onClick((prev: boolean) => !prev);
                setSelectedWeek(week);
            }}
            className={`flex w-auto items-center p-3  rounded-md transition  bg-t-500 text-white hover:bg-t-500-op-5 `}
        >
            Envoyer les documents
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M18.3333 10.4176C18.3333 10.0085 18.3292 9.1818 18.32 8.77097C18.2658 6.2168 18.2383 4.9393 17.2958 3.99347C16.3533 3.0468 15.0417 3.0143 12.4175 2.94847C10.8061 2.9078 9.19392 2.9078 7.58251 2.94847C4.95834 3.0143 3.64667 3.0468 2.70417 3.99347C1.76167 4.9393 1.73417 6.2168 1.67917 8.77097C1.66163 9.59087 1.66163 10.4111 1.67917 11.231C1.73417 13.7851 1.76167 15.0626 2.70417 16.0085C3.64667 16.9551 4.95834 16.9876 7.58251 17.0535C8.2514 17.0701 8.91834 17.0801 9.58334 17.0835"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.83331 7.08398L8.28498 8.53398C9.71415 9.37898 10.285 9.37898 11.715 8.53398L14.1666 7.08398M18.3333 14.584H11.6666M18.3333 14.584C18.3333 14.0007 16.6716 12.9107 16.25 12.5007M18.3333 14.584C18.3333 15.1673 16.6716 16.2582 16.25 16.6673"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};

export default StatusButton;
