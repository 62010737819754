import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import {
  fetchShiftsWeekUrl,
  fetchShiftsListUrl,
  validateShiftUrl,
  addEmployeeToPointeuseUrl,
  updatePointageUrl,
  updateShiftUrl,
  validateJourneyUrl,
  fetchDepartementsUrl,
  fetchEmployeeByWeek,
} from "../api.routes";
import {
  FetchShiftListRequest,
  ValidateShiftRequest,
  AddEmployeeToPointeuseRequest,
  UpdatePointageRequest,
  UpdateShiftRequest,
  ValidateJourneyRequest,
  FetchDeparementsRequest,
  fetchEmployeesByWeekRequest,
} from "./types";
export const fetchShiftList = createAsyncThunk(
  "pointeuse/fetchShiftList",
  async (payload: FetchShiftListRequest, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    if (payload.siteId && payload.from && payload.to && payload.licenseId) {
      try {
        const res = await fetch(fetchShiftsListUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            console.log("before json, ", res);
            return res;
          })
          .then((res) => res.json())
          .then((res) => {
            if (res.error) {
              throw new Error(res.message);
            }
            console.log("then res :", res);
            return res;
          });
        console.log("payload :", payload);
        console.log("res :", res);
        return res;
      } catch (err) {
        return rejectWithValue(err);
      }
    }
  }
);
// export const fetchShiftList = createAsyncThunk(
//     "pointeuse/fetchShiftList",
//     async (payload: FetchShiftListRequest, { rejectWithValue }) => {
//         try {
//             console.log(" payload = ", payload);
//             const data = await fetch(fetchShiftsListUrl, {
//                 method: "POST",
//                 headers: {
//                     "Content-Type": "application/json",
//                     authorization: `Bearer ${localStorage.getItem("token")}`,
//                 },
//                 body: JSON.stringify(payload),
//             }).then((res) => res.json());
//             // let data = await res.json();
//             console.log(
//                 " just get the data hh = ",
//                 data,
//                 " -- payload = ",
//                 payload
//             );
//             // return {};
//             return {
//                 status: "success",
//                 data: data,
//             };
//         } catch (error) {
//             const err = error as AxiosError;
//             if (err) {
//                 return rejectWithValue({
//                     status: "fail",
//                     data: err.response?.data,
//                 });
//             }
//         }
//     }
// );

export const fetchShiftWeek = createAsyncThunk(
  "pointeuse/fetchShiftWeek",
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await axios.post(fetchShiftsWeekUrl, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const validateShift = createAsyncThunk(
  "pointeuse/validateShift",
  async (payload: ValidateShiftRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${validateShiftUrl}/${payload}`,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const addEmployeeToPointeuse = createAsyncThunk(
  "pointeuse/addEmployeeToPointeuse",
  async (payload: AddEmployeeToPointeuseRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(addEmployeeToPointeuseUrl, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const updatePointage = createAsyncThunk(
  "pointeuse/updatePointage",
  async (payload: UpdatePointageRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${updatePointageUrl}/${payload.id}`,
        payload,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const updateShift = createAsyncThunk(
  "pointeuse/updateShift",
  async (payload: UpdateShiftRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${updateShiftUrl}/${payload.id}`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const validateJourney = createAsyncThunk(
  "pointeuse/validateJourney",
  async (payload: ValidateJourneyRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(validateJourneyUrl, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return {
        status: "success",
        data: res.data,
        payload,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const fetchDepartements = createAsyncThunk(
  "pointeuse/fetchDepartements",
  async (payload: FetchDeparementsRequest, { rejectWithValue }) => {
    try {
      const res = await axios.get(`${fetchDepartementsUrl}/${payload.siteId}`, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);

export const fetchEmployeesByWeek = createAsyncThunk(
  "pointeuse/fetchEmployeesByWeek",
  async (payload: fetchEmployeesByWeekRequest, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${fetchEmployeeByWeek}`, payload, {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log(res.data);
      return {
        status: "success",
        data: res.data,
      };
    } catch (error) {
      const err = error as AxiosError;
      if (err) {
        return rejectWithValue({
          status: "fail",
          data: err.response?.data,
        });
      }
    }
  }
);
