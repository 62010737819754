import React, { useState } from "react";
import * as Icons from "../../../Icons";
import NewDepartmentCard from "../../../components/organism/NewDepartmentCard";

export default function FeuilleAccordion({
    header,
    body,
    bodyStyle = "",
    isOpen,
    onClick,
}: FeuilleAccordionProps) {
    return (
        <div className="rounded-lg border border-n-0">
            <div
                className={`cursor-pointer flex items-center justify-between rounded-lg   px-5 py-3  ${
                    isOpen ? "#EDEFF5" : "bg-white"
                }`}
                onClick={onClick}
            >
                <div>{header}</div>
                <div
                    className={`text-n-600 hover:text-n-800 transition-[1000] ${
                        isOpen ? "rotate-90" : ""
                    }`}
                >
                    <Icons.ChevronRight />
                </div>
            </div>
            {isOpen && (
                <div
                    className={`transition-[1000]  ${bodyStyle} ${
                        isOpen ? "px-5 py-3" : "max-h-[0] overflow-hidden"
                    }`}
                >
                    {body}
                </div>
            )}
        </div>
    );
}

interface FeuilleAccordionProps {
    header: React.ReactNode;
    body: React.ReactNode;
    bodyStyle?: string;
    isOpen?: boolean;
    onClick?: () => void;
}
