import moment from "moment";
import React, { useEffect, useState } from "react";
import usePointeuse from "../../../hooks/usePointeuse";

const AccorditionHeader = ({
    month,
    year,
    siteId,
}: {
    month: string;
    year: number;
    siteId: any;
}) => {
    const { getShiftWeek, weekDetails, listLoading } = usePointeuse();
    const [weeks, setWeeks] = useState<any>([]);
    const [shiftData, setShiftData] = useState<any>([]);
    const getWeeksInMonth = (monthIndex: number, year: any) => {
        const startOfMonth = moment()
            .year(year)
            .month(monthIndex)
            .startOf("month");
        const endOfMonth = moment().year(year).month(monthIndex).endOf("month");
        const weeks = [];
        let currentWeek = startOfMonth.clone();

        while (currentWeek.isBefore(endOfMonth)) {
            weeks.push({
                weekNumber: currentWeek.week(),
            });

            currentWeek.add(1, "week");
        }

        return weeks;
    };
    useEffect(() => {
        const monthIndex = moment().month(month).month(); // Convert month name to index
        const fetchedWeeks = getWeeksInMonth(monthIndex, year);

        setWeeks(fetchedWeeks);

        // Fetch shift data for all weeks in the month
        const fetchShiftData = async () => {
            const shiftPromises = fetchedWeeks.map((week) =>
                getShiftWeek({
                    siteId,
                    year,
                    weekNumber: week.weekNumber,
                }),
            );
            const results = await Promise.all(shiftPromises);
            setShiftData(results);
        };

        fetchShiftData();
    }, [siteId, month, year]);
    return (
        <div className="text-xl flex items-center space-x-3">
            <h1 className="text-v-800 font-semibold capitalize w-[100px]">
                {month}
            </h1>
            <div className="isolate inline-flex rounded-md shadow-sm">
                {weeks.map((week: any, index: any, weeksArray: any) => {
                    const shift = shiftData[index] || {};
                    return (
                        <>
                            {listLoading ? (
                                <></>
                            ) : (
                                <p
                                    key={index}
                                    className={`relative ${
                                        index === 0
                                            ? "rounded-l-[4px]"
                                            : index === weeksArray.length - 1
                                              ? "rounded-r-[4px]"
                                              : ""
                                    } ${shift?.payload?.data?.validated == true ? "bg-employee" : "bg-error"}  inline-flex items-center px-[5px] py-[2px] text-[12px] mx-[1px]  text-white  `}
                                >
                                    S{week?.weekNumber}
                                </p>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default AccorditionHeader;
