import * as React from "react";

import TextData from "../../components/atoms/TextData";
import Button from "../../components/atoms/Button";
import DashboardTemplate from "../../components/templates/Dashboard";
import SettingSection from "../../components/organism/SettingSection";
import IntegrationCard from "../../components/molecules/IntegrationCard";
import CommingSoon from "./Elements/CommingSoon";
import NoAccess from "./Elements/NoAccess";
import { useBranch } from "../../hooks/useBranch";

import Input from "../../components/atoms/Input";
import ToggleButton from "../../components/atoms/Toggle";
import Radio from "../../components/atoms/Radio";

import Jalia from "./icons/jalia.svg";
import Popina from "./icons/popina.svg";
import Silae from "./icons/silae.svg";
import Modal from "../../components/atoms/Modal";
import PopUpCard from "../../components/molecules/PopUpCard";

export default function Integration(props: IntegrationProps) {
    const {
        settings,
        commingSoon,
        includeButtons,
        submitButtonText,
        handleSubmitButton,
        cancelButtonText,
        handleCancelButton,
    } = props;
    const { selectedBranchSettings, getAnalysisConfigs } = useBranch();
    React.useEffect(() => {
        getAnalysisConfigs();
    }, [selectedBranchSettings]);
    return (
        <div className="integration-container">
            {!selectedBranchSettings._id && <NoAccess />}
            {/* {commingSoon && <CommingSoon />} */}
            <div
                style={{
                    height: "calc(100vh - 290px)",
                    overflowY: "auto",
                }}
            >
                {settings?.map((elem: any, index: number) => {
                    return (
                        <SettingSection
                            groupTitle={elem.headerTitle}
                            groupDescription={elem.headerDescription}
                            configOptions={elem.configOptions}
                        />
                    );
                })}
            </div>
            {includeButtons && (
                <div className="settings-footer">
                    <Button
                        style={{
                            backgroundColor: "#EDEFF5",
                            color: "#2A346D",
                            fontSize: "14px",
                        }}
                        backgroundColor="#EDEFF5"
                        textColor="#2A346D"
                        onClick={handleCancelButton}
                        width="120px"
                        height="40px"
                    >
                        {cancelButtonText}
                    </Button>
                    <Button
                        style={{
                            backgroundColor: "#2A8BAB",
                            color: "white",
                            fontSize: "14px",
                        }}
                        backgroundColor="#2A8BAB"
                        textColor="white"
                        onClick={handleSubmitButton}
                        width="120px"
                        height="40px"
                    >
                        {/* {submitButtonText} */}
                        Enregistrer
                    </Button>
                </div>
            )}
        </div>
    );
}

interface ConfigOptionTemplate {
    optionTitle: string;
    titleColor?: string;
    titleSize?: string;
    titleWeight?: string;
    optionDescription: string;
    descriptionColor?: string;
    descriptionSize?: string;
    descriptionWeight?: string;
    optionInput: React.ReactNode;
}
interface IntegrationProps {
    settings?: any;
    // settings?: {
    //     headerTitle?: string;
    //     headerDescription?: string;
    //     configOptions?: React.ReactNode | ConfigOptionTemplate[];
    // }[];
    commingSoon?: boolean;
    includeButtons?: boolean;
    submitButtonText?: string;
    handleSubmitButton?: () => void;
    cancelButtonText?: string;
    handleCancelButton?: () => void;
}
Integration.defaultProps = {
    // settings: [],
    commingSoon: true,
    includeButtons: true,
    submitButtonText: "Modifier",
    handleSubmitButton: () => {},
    cancelButtonText: "Annuler",
    handleCancelButton: () => {},
};
