import React from "react";
import "./style.css";
import { ReactComponent as InfoSVG } from "./info.svg";
import { ReactComponent as NoPhotoSVG } from "./no-photo.svg";
import { ReactComponent as EditDaySVG } from "./editDay.svg";
import { ReactComponent as LockDaySVG } from "./lockDay.svg";
import { ReactComponent as CommentSVG } from "./comment.svg";
import ProfilePicture from "../../molecules/ProfilePicture";
import TimingComponent from "../../molecules/TimingComponent";
import ImageSliderCard from "../../organism/ImageSliderCard";
import { ROUTER } from "../../../constants/env";
import Checkbox from "../../atoms/Checkbox";
import usePointeuse from "../../../hooks/usePointeuse";
import { employeeRoleColor } from "../../../_helpers/Functions";
import { useEmployees } from "../../../hooks/useEmployees";
import { useBranch } from "../../../hooks/useBranch";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
import PointageNoRecord from "../../templates/PointageNoRecord";
import {
    fomatStartEndTime,
    formatTime,
    convertMinutesToHours,
} from "../../../_helpers/Functions";
import PointeusePopupDetails from "../../../components/organism/PointeusePopupDetails";
import TextData from "../../atoms/TextData";
import Button from "../../atoms/Button";
import Swal from "sweetalert2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";

export default function PointeuseDataBody(props: any) {
    const {
        isAllChecked,
        selectedDay,
        handleValidateShift,
        photoAllowed,
        validated,
        locked,
        onValidateJourney,
        setEmpShiftsRowsNumber,
    } = props;
    const { selectedBranchSettings } = useBranch();
    function convertTimeToTimestamp(timeString: string) {
        // Split the time string into hours and minutes
        const [hours, minutes] = timeString.split(":");

        // Convert hours and minutes to integers
        const hour = parseInt(hours, 10);
        const minute = parseInt(minutes, 10);

        // Calculate the total minutes from the time string
        return hour * 60 + minute;
    }
    const { filteredFromAllSites } = useEmployees();
    const {
        // data,
        addEmployeeToPointeuse,
        updatePointage,
        pointeuseOptions,
        filteredDataByDepartments,
        // uniqueEmployees,
        getShifList,
    } = usePointeuse();

    const [openedPopup, setOpenedPopup] = React.useState(false);
    React.useEffect(() => {
        if (!openedPopup) {
            setToEdit(null);
            setToEditShifs(null);
            setShiftStatus("");
        }
    }, [openedPopup]);
    const [selectedShift, setSelectedShift] = React.useState<any>(null);
    const onValidatePointage = async (shifts: any) => {
        let res: any = {};
        for (let i = 0; i < shifts?.length; i++) {
            if (!shifts[i].shiftId) {
                res = await addEmployeeToPointeuse({
                    day: selectedDay,
                    employeeId: selectedEmployee?._id,
                    licenseId: selectedEmployee?.license[0],
                    siteId: selectedBranchSettings._id,
                    start: shifts[i].start,
                    end: shifts[i].end,
                    absence: shifts[i].absence,
                    isAbsent: shifts[i].isAbsent,
                    ignorer: shifts[i].deleted,
                    repas: shifts[i].repas,
                });
            } else {
                res = await updatePointage({
                    id: shifts[i].shiftId,
                    day: selectedDay,
                    employeeId: selectedEmployee?._id,
                    licenseId: selectedEmployee?.license[0],
                    siteId: selectedBranchSettings._id,
                    comment: shifts[i]?.comment,
                    isAbsent: shifts[i].isAbsent,
                    shift: {
                        start: "",
                        end: "",
                    },
                    repas: shifts[i].repas,
                    ignorer: shifts[i].deleted,
                    absence: shifts[i].absence,
                    extraDays: shifts[i].extraDays,
                });
            }
        }
        if (res?.payload?.status === "success") {
            setOpenedPopup(false);
            setToEdit(null);
            setToEditShifs(null);
            await getShifList({
                from: selectedDay,
                to: selectedDay,
                siteId: selectedBranchSettings._id,
                licenseId: selectedBranchSettings.license,
            });
            return true;
        }
        return false;
    };
    const [images, setImages] = React.useState<any>([]);
    // const [realImages, setImages] = React.useState<any>([]);
    const [selectedEmployee, setSelectedEmployee] = React.useState<any>(null);
    const [selectedIdex, setSelectedIndex] = React.useState<any>(-1);
    const onSlide = (index: number) => {
        if (slidingImages[index]) {
            setImages(slidingImages[index].images);
            setSelectedEmployee(slidingImages[index].employee);
            setSelectedIndex(index);
        }
    };

    const [openImagesSlider, setOpenImagesSlider] = React.useState(false);
    const handleClodeSlider = () => {
        setOpenImagesSlider(false);
    };
    const { employeesById } = usePointeuse();
    const [toEdit, setToEdit] = React.useState(null);
    const [toEditShifts, setToEditShifs] = React.useState<any>(null);
    const [shiftStatus, setShiftStatus] = React.useState<any>("");

    const [slidingImages, setSlidingObject] = React.useState<any>([]);
    const [employeesImages, setEmployeesImages] = React.useState<any>([]);
    React.useEffect(() => {
        if (filteredFromAllSites.length > 0) {
            let slidingObject: any = [];
            let mp: any = {};
            let empSet = new Set();
            for (let emp of filteredFromAllSites) {
                if (
                    (filteredDataByDepartments?.[emp?._id]?.normal ||
                        filteredDataByDepartments?.[emp?._id]?.absent?.length ||
                        filteredDataByDepartments?.[emp?._id]?.deleted
                            ?.length) &&
                    employeesById?.[emp?._id]
                ) {
                    empSet.add(emp._id);
                }
                if (
                    (filteredDataByDepartments?.[emp?._id]?.normal ||
                        filteredDataByDepartments?.[emp?._id]?.absent?.length ||
                        filteredDataByDepartments?.[emp?._id]?.deleted
                            ?.length) &&
                    employeesById?.[emp?._id]
                ) {
                    let imagesArr: any = [];
                    let shifts =
                        filteredDataByDepartments?.[emp?._id]?.normal?.shifts ||
                        [];

                    filteredDataByDepartments?.[emp?._id]?.deleted?.map(
                        (ob: any) => {
                            shifts = [...shifts, ...ob.shifts];
                        },
                    );
                    filteredDataByDepartments?.[emp?._id]?.absent?.map(
                        (ob: any) => {
                            shifts = [...shifts, ...ob.shifts];
                        },
                    );

                    // if (shifts.length === 0) {
                    //     continue;
                    // }
                    shifts?.map((shift: any, index: number) => {
                        if (shift) {
                            if (shift.shiftbadge?.arriver[0]?.filePath) {
                                imagesArr.push(shift.shiftbadge?.arriver[0]);
                            }
                            if (shift.shiftbadge?.arriver[0]?.signaturePath) {
                                imagesArr.push({
                                    ...shift.shiftbadge?.arriver[0],
                                    filePath:
                                        shift.shiftbadge?.arriver[0]
                                            ?.signaturePath,
                                });
                            }
                            if (shift.shiftbadge?.quitter[0]?.filePath) {
                                imagesArr.push(shift.shiftbadge?.quitter[0]);
                            }
                            if (shift.shiftbadge?.quitter[0]?.signaturePath) {
                                imagesArr.push({
                                    ...shift.shiftbadge?.quitter[0],
                                    filePath:
                                        shift.shiftbadge?.quitter[0]
                                            ?.signaturePath,
                                });
                            }
                            for (let pause of shift?.pauseHistory) {
                                if (pause?.filePath) {
                                    imagesArr.push(pause);
                                }
                                if (pause?.signaturePath) {
                                    imagesArr.push({
                                        ...pause,
                                        filePath: pause.signaturePath,
                                    });
                                }
                            }
                        }
                    });

                    imagesArr = imagesArr.sort((a: any, b: any) => {
                        const timeA = convertTimeToTimestamp(a.time);
                        const timeB = convertTimeToTimestamp(b.time);
                        return timeA - timeB;
                    });
                    slidingObject.push({
                        employee: emp,
                        images: imagesArr,
                    });
                    mp[emp._id] = imagesArr;
                }
            }
            setEmpShiftsRowsNumber(empSet.size);
            setSlidingObject(slidingObject);
            setEmployeesImages(mp);
        }
    }, [filteredFromAllSites, filteredDataByDepartments]);
    let normalShiftsIndex = -1;
    const BlockedPage = () => {
        return (
            <div
                style={{
                    backgroundColor: "#ffffffcf",
                    position: "absolute",
                    width: "100%",
                    zIndex: "1",
                    height: "calc(100vh - 250px)",
                    // maxHeight: `calc(100vh - 250px)`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <LockDaySVG height={45} width={45} color="#11106e" />
                <div
                    style={{
                        fontSize: "16px",
                        fontWeight: "semibold",
                        marginBottom: "3px",
                        marginTop: "3px",
                        textTransform: "capitalize",
                        color: "#11106e",
                    }}
                >
                    {moment(selectedDay).format("dddd D MMMM")}
                </div>
                <div
                    style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginBottom: "12px",
                        color: "#11106e",
                    }}
                >
                    Journée traitée et verrouillée.
                </div>
                <div
                    style={{
                        fontSize: "14px",
                        fontWeight: "normal",
                        marginBottom: "7px",
                        color: "#11106e",
                    }}
                >
                    Si vous souhaitez apporter des modifications
                </div>
                <Button
                    width="fit-content"
                    padding="7px 15px"
                    textColor="white"
                    backgroundColor="#2d2aad"
                    style={{
                        display: "flex",
                        fontSize: "16px",
                        gap: "0.25rem",
                        width: "max-content",
                        alignItems: "center",
                    }}
                    height="40px"
                    onClick={() =>
                        onValidateJourney({
                            validate: true,
                            locked: false,
                        })
                    }
                >
                    <EditDaySVG color="white" height={25} width={25} />
                    <TextData size="16px" color="white">
                        Déverrouiller la journée
                    </TextData>
                </Button>
            </div>
        );
    };
    if (Object.keys(filteredDataByDepartments).length <= 0) {
        return (
            <tr>
                <td colSpan={8}>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            // height: "calc(100vh - 250px)",
                        }}
                    >
                        {locked && <BlockedPage />}

                        <PointageNoRecord />
                    </div>
                </td>
            </tr>
        );
    }
    return (
        <tbody
            className="pointeuse-table-body"
            style={{
                overflow: "auto",
                maxHeight: "calc(100vh - 250px)",
            }}
        >
            {locked && <BlockedPage />}
            <PointeusePopupDetails
                display={openedPopup}
                onClose={() => {
                    setOpenedPopup(false);
                }}
                employee={selectedEmployee}
                shiftId={selectedShift}
                day={selectedDay}
                onValidate={onValidatePointage}
                toEdit={toEdit}
                toEditShifts={toEditShifts}
                shiftStatus={shiftStatus}
                // pauseHistory={}
            />
            <ImageSliderCard
                display={openImagesSlider}
                onClose={handleClodeSlider}
                images={images}
                onSlide={onSlide}
                employee={selectedEmployee}
                day={selectedDay}
                selectedIdex={selectedIdex}
            />

            {/* absent shifts */}
            {filteredFromAllSites?.map((emp: any, index: number) => {
                if (
                    filteredDataByDepartments?.[emp?._id]?.absent?.length > 0 &&
                    employeesById?.[emp?._id]
                ) {
                    return filteredDataByDepartments?.[emp?._id]?.absent?.map(
                        (absentShift: any, index: number) => (
                            <PointeuseEmployeeRow
                                key={`${emp?._id}-absent`}
                                id={index}
                                isAllChecked={isAllChecked}
                                employee={emp}
                                plans={absentShift?.plans || []}
                                shifts={absentShift?.shifts || []}
                                handleValidateShift={handleValidateShift}
                                images={
                                    employeesImages[emp?._id] || []
                                    // imagesArr
                                }
                                setImages={setImages}
                                isIgnored={false}
                                isAbsent={true}
                                validated={0}
                                setOpenImagesSlider={setOpenImagesSlider}
                                setSelectedEmployee={setSelectedEmployee}
                                onClick={() => {
                                    setSelectedEmployee(
                                        employeesById?.[emp?._id],
                                    );
                                    setShiftStatus("absent");
                                    if (absentShift?.shifts?.[0]) {
                                        setToEdit(absentShift?.shifts?.[0]);
                                        setToEditShifs(
                                            absentShift?.shifts || [],
                                        );
                                    }
                                    setOpenedPopup(true);
                                    setSelectedShift(
                                        absentShift?.shifts?.[0]?._id || null,
                                    );
                                }}
                                pointeuseOptions={pointeuseOptions}
                                selectedDay={selectedDay}
                                photoAllowed={photoAllowed}
                            />
                        ),
                    );
                }
            })}

            {/* normal négative durations shifts */}
            {filteredFromAllSites?.map((emp: any, index: number) => {
                if (
                    filteredDataByDepartments?.[emp?._id]?.normal &&
                    filteredDataByDepartments?.[emp?._id]?.normal?.duration <
                        0 &&
                    employeesById?.[emp?._id]
                ) {
                    normalShiftsIndex++;
                    return (
                        <PointeuseEmployeeRow
                            key={`${emp?._id}-normal-negative`}
                            id={index}
                            isAllChecked={isAllChecked}
                            employee={emp}
                            plans={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.plans || []
                            }
                            shifts={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts || []
                            }
                            handleValidateShift={handleValidateShift}
                            images={
                                employeesImages[emp?._id] || []
                                // imagesArr
                            }
                            setSelectedIndex={setSelectedIndex}
                            shiftIndex={normalShiftsIndex}
                            setImages={setImages}
                            isIgnored={false}
                            isAbsent={false}
                            validated={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts[0]?.validated || false
                            }
                            setOpenImagesSlider={setOpenImagesSlider}
                            setSelectedEmployee={setSelectedEmployee}
                            onClick={() => {
                                setSelectedEmployee(employeesById?.[emp?._id]);
                                if (
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]
                                ) {
                                    setToEdit(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts?.[0],
                                    );

                                    setToEditShifs(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts || [],
                                    );
                                }
                                setOpenedPopup(true);
                                setSelectedShift(
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]?._id || null,
                                );
                            }}
                            pointeuseOptions={pointeuseOptions}
                            selectedDay={selectedDay}
                            photoAllowed={photoAllowed}
                        />
                    );
                }
            })}

            {/* normal zero durations shifts */}
            {filteredFromAllSites?.map((emp: any, index: number) => {
                if (
                    (filteredDataByDepartments?.[emp?._id]?.normal?.shifts
                        ?.length ||
                        (filteredDataByDepartments?.[emp?._id]?.normal?.plans
                            ?.length &&
                            !filteredDataByDepartments?.[emp?._id]?.absent
                                ?.length &&
                            !filteredDataByDepartments?.[emp?._id]?.deleted
                                ?.length)) &&
                    filteredDataByDepartments?.[emp?._id]?.normal?.duration ===
                        0 &&
                    employeesById?.[emp?._id]
                ) {
                    normalShiftsIndex++;
                    return (
                        <PointeuseEmployeeRow
                            key={`${emp?._id}-normal-zero`}
                            id={index}
                            isAllChecked={isAllChecked}
                            employee={emp}
                            plans={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.plans || []
                            }
                            shifts={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts || []
                            }
                            handleValidateShift={handleValidateShift}
                            images={
                                employeesImages[emp?._id] || []
                                // imagesArr
                            }
                            setSelectedIndex={setSelectedIndex}
                            shiftIndex={normalShiftsIndex}
                            setImages={setImages}
                            isIgnored={false}
                            isAbsent={false}
                            validated={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts[0]?.validated || false
                            }
                            setOpenImagesSlider={setOpenImagesSlider}
                            setSelectedEmployee={setSelectedEmployee}
                            onClick={() => {
                                setSelectedEmployee(employeesById?.[emp?._id]);
                                if (
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]
                                ) {
                                    setToEdit(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts?.[0],
                                    );
                                    setToEditShifs(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts || [],
                                    );
                                }
                                setOpenedPopup(true);
                                setSelectedShift(
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]?._id || null,
                                );
                            }}
                            pointeuseOptions={pointeuseOptions}
                            selectedDay={selectedDay}
                            photoAllowed={photoAllowed}
                        />
                    );
                }
            })}

            {/* normal positive duration shifts */}
            {filteredFromAllSites?.map((emp: any, index: number) => {
                if (
                    filteredDataByDepartments?.[emp?._id]?.normal &&
                    filteredDataByDepartments?.[emp?._id]?.normal?.duration >
                        0 &&
                    employeesById?.[emp?._id]
                ) {
                    normalShiftsIndex++;
                    return (
                        <PointeuseEmployeeRow
                            key={`${emp?._id}-normal-positive`}
                            id={index}
                            isAllChecked={isAllChecked}
                            employee={emp}
                            plans={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.plans || []
                            }
                            shifts={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts || []
                            }
                            handleValidateShift={handleValidateShift}
                            images={employeesImages[emp?._id] || []}
                            setSelectedIndex={setSelectedIndex}
                            shiftIndex={normalShiftsIndex}
                            setImages={setImages}
                            isIgnored={false}
                            isAbsent={false}
                            validated={
                                filteredDataByDepartments?.[emp?._id]?.normal
                                    ?.shifts[0]?.validated || false
                            }
                            setOpenImagesSlider={setOpenImagesSlider}
                            setSelectedEmployee={setSelectedEmployee}
                            onClick={() => {
                                setSelectedEmployee(employeesById?.[emp?._id]);

                                if (
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]
                                ) {
                                    setToEdit(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts?.[0],
                                    );
                                    setToEditShifs(
                                        filteredDataByDepartments?.[emp?._id]
                                            ?.normal?.shifts || [],
                                    );
                                }
                                setOpenedPopup(true);
                                setSelectedShift(
                                    filteredDataByDepartments?.[emp?._id]
                                        ?.normal?.shifts?.[0]?._id || null,
                                );
                            }}
                            pointeuseOptions={pointeuseOptions}
                            selectedDay={selectedDay}
                            photoAllowed={photoAllowed}
                        />
                    );
                }
            })}
            {/* deleted shifts */}
            {filteredFromAllSites?.map((emp: any, index: number) => {
                if (
                    filteredDataByDepartments?.[emp?._id]?.deleted?.length >
                        0 &&
                    employeesById?.[emp?._id]
                ) {
                    return filteredDataByDepartments?.[emp?._id]?.deleted?.map(
                        (deletedShift: any, index: number) => (
                            <PointeuseEmployeeRow
                                key={`${emp?._id}-deleted`}
                                id={index}
                                isAllChecked={isAllChecked}
                                employee={emp}
                                plans={deletedShift?.plans || []}
                                shifts={deletedShift?.shifts || []}
                                handleValidateShift={handleValidateShift}
                                images={[]}
                                setImages={setImages}
                                isIgnored={true}
                                isAbsent={false}
                                validated={0}
                                setOpenImagesSlider={setOpenImagesSlider}
                                setSelectedEmployee={setSelectedEmployee}
                                onClick={() => {
                                    setSelectedEmployee(
                                        employeesById?.[emp?._id],
                                    );
                                    if (deletedShift?.shifts?.[0]) {
                                        setToEdit(
                                            filteredDataByDepartments?.[
                                                emp?._id
                                            ]?.deleted[0]?.shifts?.[0],
                                        );
                                        setShiftStatus("deleted");
                                        setToEditShifs(
                                            deletedShift?.shifts || [],
                                        );
                                    }
                                    setOpenedPopup(true);
                                    setSelectedShift(
                                        deletedShift?.shifts?.[0]?._id || null,
                                    );
                                }}
                                pointeuseOptions={pointeuseOptions}
                                selectedDay={selectedDay}
                                photoAllowed={photoAllowed}
                            />
                        ),
                    );
                }
            })}
        </tbody>
    );
}
function RealShiftsInputs(props: any) {
    const {
        shiftInputs,
        index,
        pointeuseOptions,
        isIgnored,
        isAbsent,
        selectedDay,
        shiftId,
        employee,
        key,
        disabled,
    } = props;
    const {
        validateShift,
        updateShift,
        pointeuseFilterBy,
        getShifList,
        addEmployeeToPointeuse,
    } = usePointeuse();
    const { selectedBranchSettings, branchSettings } = useBranch();
    const [shiftsValue, setshiftsValue] = React.useState(
        shiftInputs || {
            start: "",
            end: "",
            pause: "",
        },
    );
    React.useEffect(() => {
        if (shiftInputs) {
            setshiftsValue(shiftInputs);
        }
    }, [shiftInputs]);
    const handleUpdateShift = async (time: any, type: string) => {
        if (shiftId) {
            let res: any = await updateShift({
                index,
                type,
                data:
                    type === "PAUSE"
                        ? time
                        : {
                              type,
                              time,
                              day: selectedDay,
                          },
                id: shiftId,
            });
            // if (res?.payload?.status === "success") {
            //     await getShifList({
            //         from: selectedDay,
            //         to: selectedDay,
            //         siteId: selectedBranchSettings._id,
            //         licenseId: selectedBranchSettings.license,
            //     });
            // }
        } else {
            let res: any = await addEmployeeToPointeuse({
                employeeId: employee._id,
                licenseId: employee.license[0],
                siteId: selectedBranchSettings._id,
                day: selectedDay,
            });
            if (res?.payload?.status === "success") {
                let res1: any = await updateShift({
                    index,
                    type,
                    data:
                        type === "PAUSE"
                            ? time
                            : {
                                  type,
                                  time,
                                  day: selectedDay,
                              },
                    id: res?.payload.data._id,
                });
                // if (res1?.payload?.status === "success") {
                //     await getShifList({
                //         from: selectedDay,
                //         to: selectedDay,
                //         siteId: selectedBranchSettings._id,
                //         licenseId: selectedBranchSettings.license,
                //     });
                // }
            }
        }
    };
    return (
        <div
            key={shiftId ?? `${employee._id}-shift`}
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    width: "37%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <input
                    type="time"
                    defaultValue={shiftsValue.start}
                    onBlur={(e: any) =>
                        handleUpdateShift(e.target.value, "ARRIVER")
                    }
                    onChange={(e) =>
                        setshiftsValue((prev: any) => ({
                            ...prev,
                            start: e.target.value,
                        }))
                    }
                    style={{
                        border: "1px solid #c7cbe2",
                        borderRadius: "3px",
                        fontSize: "12px",
                        padding: "2px 4px",
                        width: "calc(100% - 8px)",
                        height: "28px",
                    }}
                    disabled={isAbsent || isIgnored || disabled}
                />
            </div>

            <div
                style={{
                    width: "37%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <input
                    type="time"
                    defaultValue={shiftsValue.end}
                    onBlur={(e: any) =>
                        handleUpdateShift(e.target.value, "QUITTER")
                    }
                    onChange={(e) =>
                        setshiftsValue((prev: any) => ({
                            ...prev,
                            end: e.target.value,
                        }))
                    }
                    style={{
                        border: "1px solid #c7cbe2",
                        borderRadius: "3px",
                        fontSize: "12px",
                        padding: "2px 4px",
                        width: "calc(100% - 8px)",
                        height: "28px",
                    }}
                    disabled={isAbsent || isIgnored || disabled}
                />
            </div>

            {!pointeuseOptions.hidePause && branchSettings.usePause && (
                <div
                    style={{
                        width: "26%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <input
                        type="number"
                        defaultValue={shiftsValue.pause}
                        onBlur={(e: any) =>
                            Number(e.target.value) >= 0 &&
                            Number(e.target.value) <= 999 &&
                            handleUpdateShift(e.target.value, "PAUSE")
                        }
                        onChange={(e) =>
                            setshiftsValue((prev: any) => ({
                                ...prev,
                                pause:
                                    Number(e.target.value) >= 0 &&
                                    Number(e.target.value) <= 999
                                        ? e.target.value
                                        : prev.pause,
                            }))
                        }
                        style={{
                            border: "1px solid #c7cbe2",
                            borderRadius: "3px",
                            fontSize: "12px",
                            padding: "2px 4px",
                            width: "calc(100% - 8px)",
                            height: "28px",
                        }}
                        max="999"
                        disabled={isAbsent || isIgnored || disabled}
                    />
                </div>
            )}
        </div>
    );
}
function PointeuseEmployeeRow(props: any) {
    const {
        key,
        id,
        isAllChecked,
        employee,
        shifts,
        plans,
        onClick,
        isIgnored,
        isAbsent,
        validated,
        pointeuseOptions,
        selectedDay,
        images,
        setOpenImagesSlider,
        setSelectedEmployee,
        handleValidateShift,
        setImages,
        setSelectedIndex,
        shiftIndex,
        photoAllowed,
    } = props;
    const { timeTracker } = useTimeTracker();
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee.picture),
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    const timeFomatter = (timeInMinutes: any) => {
        if (!pointeuseOptions?.showTimeInMinutes)
            return convertMinutesToHours(timeInMinutes);
        else return formatTime(timeInMinutes);
    };
    const [duration, setDuration] = React.useState(0);
    const [meals, setMeals] = React.useState(0);
    const [days, setDays] = React.useState(0);
    const [isComment, setIsComment] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState<boolean>(
        validated ?? false,
    );
    React.useEffect(() => {
        if (validated === true || isChecked === false) {
            setIsChecked(validated);
        } else setIsChecked(false);
    }, [validated]);
    React.useEffect(() => {
        if (shifts.length > 0) {
            let du = 0,
                me = 0,
                days = 0,
                day = false,
                hasComment = false;
            for (let shift of shifts) {
                du += shift.duree;
                me += Number(shift.repas);
                if (
                    shift.shiftbadge.arriver[0]?.comment ||
                    shift.shiftbadge.quitter[0]?.comment
                )
                    hasComment = true;
                if (shift.pauseHistory && !hasComment) {
                    shift.pauseHistory?.map((pause: any) => {
                        if (pause?.comment) {
                            hasComment = true;
                        }
                    });
                }
                setIsComment(hasComment);
                days += shift.extraDays;
                if (
                    (shift.shiftbadge?.arriver?.length &&
                        shift.shiftbadge?.arriver?.[0]?.time) ||
                    (shift.shiftbadge?.quitter?.length &&
                        shift.shiftbadge?.quitter?.[0]?.time) ||
                    (shift.shiftrenumere?.arriver?.length &&
                        shift.shiftrenumere?.arriver?.[0]?.time) ||
                    (shift.shiftrenumere?.quitter?.length &&
                        shift.shiftrenumere?.quitter?.[0]?.time)
                ) {
                    day = true;
                }
            }
            if (day) days++;
            setDuration(du);
            setMeals(me);
            setDays(days);
        } else {
            setDuration(0);
            setMeals(0);
            setDays(0);
            setIsComment(false);
        }
    }, [shifts]);
    const { branchSettings } = useBranch();
    return (
        <tr
            key={key}
            style={{
                backgroundColor: isAbsent
                    ? "#ff00001e"
                    : isIgnored
                      ? "#ffff002e"
                      : validated
                        ? "#cfe0e687"
                        : "transparent",
                opacity: isAbsent || isIgnored ? "0.5" : "1",
                position: "relative",
                // display: "flex",
            }}
        >
            {/* {images?.length} */}
            <td
                onClick={onClick}
                style={{
                    cursor: "pointer",
                    height: "inherit",
                }}
            >
                <div
                    style={{
                        padding: "10px",
                        paddingLeft: "15px",
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: 8,
                        color: "#101844",
                        fontSize: "14px",
                        fontWeight: "600",
                    }}
                >
                    <div
                        style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            height: "100%",
                            width: "5px",
                            borderTop: "1px solid #edeff5",
                            backgroundColor: isIgnored
                                ? "#ff0"
                                : isAbsent
                                  ? "#f00"
                                  : "transparent",
                        }}
                    ></div>
                    <ProfilePicture
                        profileImage={employee.picture}
                        firstName={employee.fname}
                        lastName={employee.lname}
                        pictureProps={pictureProps}
                        altColor={employeeRoleColor(employee.role)}
                    />
                    <div className="name">
                        {employee.fname + " " + employee.lname}
                    </div>
                    {isComment && (
                        <CommentSVG
                            height={18}
                            width={18}
                            style={{
                                marginLeft: "auto",
                            }}
                        />
                    )}
                </div>
            </td>
            <td
                onClick={onClick}
                style={{
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                    }}
                >
                    <div>
                        {plans?.map((plan: any, index: number) => {
                            return (
                                <TimingComponent
                                    startTime={fomatStartEndTime(plan.from)}
                                    endTime={fomatStartEndTime(plan.to)}
                                    hasPause={
                                        pointeuseOptions.hidePause ||
                                        !branchSettings.usePause
                                            ? null
                                            : plan.pause
                                    }
                                    size="small"
                                    sideWidth="50px"
                                    decoratorWidth="160px"
                                />
                            );
                        })}
                        {(!plans || plans?.length === 0) && <div>-</div>}
                    </div>
                </div>
            </td>
            <td
                onClick={onClick}
                style={{
                    cursor: "pointer",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                    }}
                >
                    {shifts && shifts?.length > 0 ? (
                        shifts?.map((shift: any, index: number) => {
                            if (
                                shift.shiftbadge?.arriver[0]?.time ||
                                shift.shiftbadge?.quitter[0]?.time
                            ) {
                                return (
                                    <TimingComponent
                                        startTime={
                                            shift.shiftbadge?.arriver[0]
                                                ?.time || "-"
                                        }
                                        endTime={
                                            shift.shiftbadge?.quitter[0]
                                                ?.time || "-"
                                        }
                                        hasPause={
                                            pointeuseOptions.hidePause ||
                                            !branchSettings.usePause
                                                ? null
                                                : shift.shiftbadge?.pause || 0
                                        }
                                        size="small"
                                    />
                                );
                            } else
                                return <div style={{ height: "28px" }}>-</div>;
                        })
                    ) : (
                        <div style={{ height: "28px" }}>-</div>
                    )}
                </div>
            </td>
            <td
                style={{
                    verticalAlign: "top",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 5,
                    }}
                >
                    {shifts && shifts?.length > 0 ? (
                        shifts?.map((shift: any, index: number) => {
                            let shiftInputs = {
                                start:
                                    shift.shiftrenumere?.arriver[0]?.time || "",
                                end:
                                    shift.shiftrenumere?.quitter[0]?.time || "",
                                pause: shift.shiftrenumere?.pause || 0,
                            };

                            return (
                                <>
                                    <RealShiftsInputs
                                        shiftInputs={shiftInputs}
                                        index={0}
                                        key={shift._id}
                                        pointeuseOptions={pointeuseOptions}
                                        isIgnored={isIgnored}
                                        isAbsent={isAbsent}
                                        selectedDay={selectedDay}
                                        shiftId={shift._id}
                                        employee={employee}
                                        disabled={
                                            employee.role === "Manager" &&
                                            JSON.parse(
                                                sessionStorage.getItem(
                                                    "user",
                                                ) || "",
                                            )._id === employee._id
                                        }
                                    />
                                </>
                            );
                        })
                    ) : (
                        <RealShiftsInputs
                            index={0}
                            pointeuseOptions={pointeuseOptions}
                            isIgnored={isIgnored}
                            isAbsent={isAbsent}
                            selectedDay={selectedDay}
                            shiftId={null}
                            employee={employee}
                            disabled={
                                employee.role === "Manager" &&
                                JSON.parse(sessionStorage.getItem("user") || "")
                                    ._id === employee._id
                            }
                        />
                    )}
                </div>
            </td>
            {photoAllowed && (
                <td>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "30px",
                                width:
                                    images.length <= 0
                                        ? "100%"
                                        : images.length === 1
                                          ? "30px"
                                          : images.length <= 2
                                            ? "50px"
                                            : "70px",
                                position: "relative",
                            }}
                        >
                            {/* {images.length} */}
                            {images?.length === 0 && (
                                <div
                                    style={{
                                        fontSize: "12px",
                                        fontWeight: "700",
                                        // color: "#fb7179",
                                        color: "#d1d1e8",
                                        // width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // gap: "3px",
                                        // width: "max-content",
                                        flexDirection: "column",
                                    }}
                                >
                                    <NoPhotoSVG
                                        color="d1d1e8"
                                        height={18}
                                        width={18}
                                    />

                                    <div>Pas de photo/signature</div>
                                </div>
                            )}
                            {images
                                ?.slice(0, 2)
                                ?.map((item: any, index: number) => {
                                    // return <div>item</div>;
                                    return (
                                        <div
                                            style={{
                                                height: "30px",
                                                width: "30px",
                                                border: "2px solid white",
                                                backgroundColor: "#bbb",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                top: "0",
                                                left: `${index * 20}px`,
                                                cursor: "pointer",
                                                overflow: "hidden",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            onClick={() => {
                                                setSelectedEmployee?.(employee);
                                                setImages?.(images);
                                                setSelectedIndex?.(shiftIndex);
                                                setOpenImagesSlider?.(true);
                                            }}
                                        >
                                            {/* {" "}
                                        {index} */}
                                            <LazyLoadImage
                                                alt={"Pointage user - "}
                                                height={80}
                                                src={
                                                    // "https://scontent.esperoo.fr/cdn-cgi/image/width=80,quality=75/files/tally" +
                                                    "https://scontent.esperoo.fr/files/tally" +
                                                    item?.filePath
                                                }
                                                style={{
                                                    width: "100%",
                                                    transform:
                                                        timeTracker.rotatePhoto
                                                            ? "rotate(180deg)"
                                                            : "",
                                                }}
                                                width={80}
                                            />
                                        </div>
                                    );
                                })}
                            {images?.length > 2 && (
                                <div
                                    style={{
                                        height: "30px",
                                        width: "30px",
                                        border: "2px solid white",
                                        backgroundColor: "#bbb",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: "0",
                                        left: "40px",
                                        fontSize: "13px",
                                        fontWeight: "bold",
                                        color: "#101844",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setOpenImagesSlider(true)}
                                >
                                    +{images?.length - 2}
                                </div>
                            )}
                        </div>
                    </div>
                </td>
            )}
            <td
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    verticalAlign: "top",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        width: "100%",
                        fontSize: "14px",
                        color: "#101844",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "calc(100% / 3)",
                            cursor: "pointer",
                            height: "28px",
                        }}
                        onClick={onClick}
                    >
                        {duration <= 0 ? (
                            <b style={{ color: "red" }}>
                                {duration === 0 ? "0" : timeFomatter(duration)}
                            </b>
                        ) : (
                            timeFomatter(duration)
                        )}
                    </div>
                    {!pointeuseOptions.hideDays && (
                        <div
                            style={{
                                width: "calc(100% / 3)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "28px",
                            }}
                        >
                            {days}
                        </div>
                    )}
                    {!pointeuseOptions.hideMeals && branchSettings.useRepas && (
                        <div
                            style={{
                                width: "calc(100% / 3)",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 5,
                            }}
                        >
                            {shifts?.map((shift: any) => {
                                return (
                                    <div
                                        key={`shift_${shift._id}`}
                                        style={{
                                            height: "28px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {!shift.repas
                                            ? "0"
                                            : String(shift.repas) === "0"
                                              ? "-1"
                                              : String(shift.repas)}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div
                        style={{
                            fontWeight: "300",
                            fontSize: "inherit",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 3,
                            width: "calc(100% / 3)",
                        }}
                    >
                        {isIgnored ? (
                            <div
                                style={{
                                    color: "#222",
                                    backgroundColor: "#ff0",
                                    padding: "2px 6px",
                                    borderRadius: "5px",
                                }}
                            >
                                Ignoré
                            </div>
                        ) : isAbsent ? (
                            <div className="absent-btn">
                                <div>{shifts?.[0].absence}</div>
                                Absent
                            </div>
                        ) : (
                            <Checkbox
                                id={id.toString()}
                                text={isChecked ? "Traitée" : "À traiter"}
                                // textColor={}
                                // title={}
                                // className={}
                                // disabled={}
                                forceChange={true}
                                checked={isChecked}
                                disabled={!shifts[0]?._id || duration <= 0}
                                onClick={() => {
                                    if (!isChecked) {
                                        if (!shifts[0]?._id) {
                                            Swal.fire({
                                                title: "Attention!",
                                                text: "Pas de shift",
                                                icon: "error",
                                                timer: 3000,
                                            });
                                        } else {
                                            setIsChecked(
                                                (prev: boolean) => true,
                                            );
                                            handleValidateShift(shifts[0]?._id);
                                        }
                                    }
                                }}
                                rounded={true}
                                style={{
                                    iconStyle: {
                                        width: "15px",
                                        height: "15px",
                                        opacity:
                                            !shifts[0]?._id || duration <= 0
                                                ? "0.5"
                                                : "1",
                                    },
                                    labelStyle: {
                                        fontSize: "13px",
                                        opacity:
                                            !shifts[0]?._id || duration <= 0
                                                ? "0.5"
                                                : "1",
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
}
