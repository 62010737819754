import { useEffect } from "react";
import Swal from "sweetalert2";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";

import {
    getContractTemplateTagsService,
    getContractTemplateBySiteService,
    postNewContractTemplateService,
    updateContractTemplateService,
    deleteContractTemplateService,
} from "../redux/features/settings/service";

import { updateAdministrativeNewContractTemplate } from "../redux/features/settings/settingsSlice";
import { NewTemplatePayload } from "../redux/features/settings/types";

export const useContractTemplate = () => {
    const dispatch = useAppDispatch();

    const selectedBranchSettings = useAppSelector(
        (state: any) => state.settings,
    ).selectedBranch;

    const contractTemplate = useAppSelector((state: any) => state.settings)
        .administrative.contractTemplate;
    const tags = contractTemplate?.tags;
    const contractTemplates = contractTemplate?.existingTemplate;
    const newContractTemplate = contractTemplate?.newTemplate;

    const getContractTemplateTags = () => {
        const token = localStorage.getItem("token");
        if (token) {
            dispatch(
                getContractTemplateTagsService({
                    token,
                }),
            ).then((res) => {});
        }
    };
    const getContractTemplates = () => {
        const token = localStorage.getItem("token");

        if (selectedBranchSettings._id && token) {
            dispatch(
                getContractTemplateBySiteService({
                    token,
                    branchID: selectedBranchSettings._id,
                }),
            ).then((res) => {});
        }
    };
    const setContractTemplate = (payload: NewTemplatePayload) => {
        dispatch(updateAdministrativeNewContractTemplate(payload));
    };
    const postNewContractTemplate = (file: any) => {
        const token = localStorage.getItem("token");
        if (selectedBranchSettings._id && token) {
            return dispatch(
                postNewContractTemplateService({
                    token,
                    branchID: selectedBranchSettings._id,
                    file,
                    name: newContractTemplate.name,
                    type: newContractTemplate.type,
                }),
            )
                .then((res) => {
                    console.log("res :: ", res);
                    if (res?.meta?.requestStatus === "rejected") {
                        return {
                            error: true,
                            message: res?.payload?.message,
                        };
                    }
                    return {
                        error: false,
                        payload: res?.payload,
                    };
                })
                .catch((err) => {
                    return {
                        error: true,
                        message: err?.payload?.message,
                    };
                });
        }
        return {
            error: true,
            message: "Veuillez contacter le support concernant cette erreur",
        };
    };

    const updateContractTemplate = (
        idContractTemplate?: string,
        file?: any,
    ) => {
        const token = localStorage.getItem("token");
        if (token && idContractTemplate) {
            return dispatch(
                updateContractTemplateService({
                    token,
                    idContractTemplate: idContractTemplate,
                    file,
                    name: newContractTemplate.name,
                    type: newContractTemplate.type,
                }),
            )
                .then((res) => {
                    if (res?.meta?.requestStatus === "rejected") {
                        return false;
                    }
                    return true;
                })
                .catch((err) => {
                    return false;
                });
        }
        return false;
    };

    const deleteContractTemplate = (idContractTemplate?: string) => {
        // deleteContractTemplateService;
        const token = localStorage.getItem("token");
        if (token && idContractTemplate) {
            return dispatch(
                deleteContractTemplateService({
                    token,
                    idContractTemplate: idContractTemplate,
                }),
            )
                .then((res) => {
                    if (res?.meta?.requestStatus === "rejected") {
                        return false;
                    }
                    return true;
                })
                .catch((err) => {
                    return false;
                });
        }
        return false;
    };

    return {
        contractTemplate,
        tags,
        contractTemplates,
        newContractTemplate,

        getContractTemplateTags,
        getContractTemplates,
        setContractTemplate,
        postNewContractTemplate,
        updateContractTemplate,
        deleteContractTemplate,
    };
};
