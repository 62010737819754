import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ReportExportRequest,
  ReportAttendancePDFRequest,
  ReportCustomSortRequest,
  ReportMonthlyWorkHoursRequest,
  ReportWeeklyWorkHoursRequest,
  EmployeeFullReportRequest,
} from "./types";
import { API } from "../../../constants/env";
export const exportClassicReportService = createAsyncThunk(
  "report/exportClassicReportService",
  async (payload: ReportExportRequest, { rejectWithValue }) => {
    if (payload.token && payload.exportPayload) {
      try {
        const response = await fetch(API.client.report.exportWorkedHours, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.exportPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);
export const exportDelayReportService = createAsyncThunk(
  "report/exportClassicReportService",
  async (payload: any, { rejectWithValue }) => {
    if (payload.token && payload.exportPayload) {
      try {
        const response = await fetch(API.client.report.exportDelay, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.exportPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);
export const generateAttendancePDFService = createAsyncThunk(
  "report/generateAttendancePDFService",
  async (payload: ReportAttendancePDFRequest, { rejectWithValue }) => {
    if (payload.token && payload.attendancePDFPayload) {
      try {
        const response = await fetch(API.client.report.generateAttendancePDF, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.attendancePDFPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);
export const getCustomSortService = createAsyncThunk(
  "report/getCustomSortService",
  async (payload: ReportCustomSortRequest, { rejectWithValue }) => {
    if (payload.token && payload.branchID) {
      try {
        const response = await fetch(
          API.client.report.customSort(payload.branchID, payload.department),
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${payload.token}`,
            },
          }
        );
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);
export const getMonthlyWorkHoursService = createAsyncThunk(
  "report/getMonthlyWorkHoursService",
  async (payload: ReportMonthlyWorkHoursRequest, { rejectWithValue }) => {
    if (payload.token && payload.monthlyWorkHoursPayload) {
      try {
        const response = await fetch(API.client.report.monthlyWorkHours, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.monthlyWorkHoursPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

//TODO: we can clean this from the entire project
export const getWeeklyWorkHoursService = createAsyncThunk(
  "report/getWeeklyWorkHoursService",
  async (payload: ReportWeeklyWorkHoursRequest, { rejectWithValue }) => {
    if (payload.token && payload.weeklyWorkHoursPayload) {
      try {
        const response = await fetch(API.client.report.weeklyWorkHours, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.weeklyWorkHoursPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const getEmployeeFullReportService = createAsyncThunk(
  "report/getEmployeeFullReportService",
  async (payload: EmployeeFullReportRequest, { rejectWithValue }) => {
    if (payload.token && payload.employeeFullReportPayload) {
      try {
        const response = await fetch(API.client.report.employeeFullReport, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${payload.token}`,
          },
          body: JSON.stringify(payload.employeeFullReportPayload),
        });
        const data = await response.json();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);
