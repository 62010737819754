import { useEffect, useMemo, useState } from "react";
import TextData from "../../../components/atoms/TextData";
import Table from "../../../components/organism/Table";
import usePointeuse from "../../../hooks/usePointeuse";
import { DownloadButton } from "./tableColumns/DownloadButton";
import StatusButton from "./tableColumns/StatusButton";
import { WeekDate } from "./tableColumns/WeekDate";
import moment from "moment";
import Loading from "../../../components/atoms/Loading";
import FeuillePopup from "./tableColumns/FeuillePopup";
import SignaturePopup from "./tableColumns/SignaturePopup";
// should be deleted after webinare 21/01/2025
type SignatureRecord = {
    week: number;
    year: number;
    siteId: string;
    userList: string[];
};

const AccordionBody = ({
    siteId,
    month,
    year,
    signature = false,
    updateSignatureConsumed,
}: {
    siteId: string;
    month: string;
    year: any;
    signature?: any;
    updateSignatureConsumed?: (value: any) => void;
}) => {
    const {
        getShiftWeek,
        listLoading,
        getEmployeesByWeek,
        pointeuseEmployees,
    } = usePointeuse();
    const [weeks, setWeeks] = useState<any>([]);
    const [selectedWeek, setSelectedWeek] = useState<any>(null);
    const [open, SetOpen] = useState(false);
    const [openSignature, SetOpenSignature] = useState(false);
    const [shiftData, setShiftData] = useState<any>([]);
    const [userLists, setUserLists] = useState<any>([]);

    const getWeeksInMonth = (monthIndex: number, year: any) => {
        const startOfMonth = moment()
            .year(year)
            .month(monthIndex)
            .startOf("month");
        const endOfMonth = moment().year(year).month(monthIndex).endOf("month");
        const weeks = [];
        let currentWeek = startOfMonth.clone();

        while (currentWeek.isBefore(endOfMonth)) {
            const startOfWeek = currentWeek.clone().startOf("week");
            const endOfWeek = currentWeek
                .clone()
                .endOf("week")
                .isAfter(endOfMonth)
                ? endOfMonth
                : currentWeek.clone().endOf("week");

            weeks.push({
                weekNumber: currentWeek.week(),
                start: startOfWeek.format("DD/MM/YYYY"),
                end: endOfWeek.format("DD/MM/YYYY"),
            });

            currentWeek.add(1, "week");
        }

        return weeks;
    };
    const getUserList = ({
        week,
        year,
        siteId,
    }: {
        week: number;
        year: number;
        siteId: string;
    }): string[] => {
        const storedSignature = localStorage.getItem("SignatureRecords");

        if (!storedSignature) {
            return [];
        }

        const records: SignatureRecord[] = JSON.parse(storedSignature);

        const matchingRecord = records.find(
            (record) =>
                record.week === week &&
                record.year === year &&
                record.siteId === siteId,
        );

        return matchingRecord ? matchingRecord.userList : [];
    };
    useEffect(() => {
        const monthIndex = moment().month(month).month(); // Convert month name to index
        const fetchedWeeks = getWeeksInMonth(monthIndex, year);

        setWeeks(fetchedWeeks);

        const fetchShiftDataForWeeks = async (weeks: any) => {
            const shiftPromises = weeks.map((week: any) =>
                getShiftWeek({
                    siteId,
                    year,
                    weekNumber: week.weekNumber,
                }),
            );
            const shiftResults = await Promise.all(shiftPromises);
            setShiftData(shiftResults);
        };

        const fetchUserListsForWeeks = async (weeks: any) => {
            const userPromises = weeks.map((week: any) =>
                getUserList({
                    siteId,
                    year,
                    week: week.weekNumber,
                }),
            );
            const userResults = await Promise.all(userPromises);
            setUserLists(userResults);
        };

        const fetchData = async () => {
            try {
                await Promise.all([
                    fetchShiftDataForWeeks(fetchedWeeks),
                    fetchUserListsForWeeks(fetchedWeeks),
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [siteId, month, year]);

    const tableData = useMemo(() => {
        if (
            listLoading ||
            !weeks.length ||
            !shiftData.length ||
            !userLists.length
        )
            return [];
        return weeks.map((week: any, index: any) => {
            const shift = shiftData[index] || {}; // Handle undefined shiftData gracefully
            const sendIt = userLists[index] || [];
            return {
                dataRow: signature
                    ? [
                          {
                              name: "fname",
                              value: WeekDate(week, shift),
                              style: {
                                  padding: "10px 0",
                                  paddingLeft: "15px",
                                  color: "#4c5690",
                                  backgroundColor: "#fff",
                                  textAlign: "left" as const,
                              },
                          },

                          {
                              name: "Nombre des jours",
                              value: sendIt?.length,
                              style: {
                                  padding: "10px 0",
                                  textAlign: "center" as const,
                                  color: "#4C5690",
                                  backgroundColor: "#fff",
                              },
                          },
                          {
                              name: "type",
                              value:
                                  week?.weekNumber == 2 && year == 2025 ? 1 : 0,
                              style: {
                                  padding: "10px 0",
                                  textAlign: "center" as const,
                                  color: "#4C5690",
                                  backgroundColor: "#fff",
                              },
                          },
                          {
                              name: "actions",
                              value: (
                                  <div className="flex items-center space-x-1 w-full justify-end">
                                      <DownloadButton
                                          validWeek={shift}
                                          week={week}
                                          SetOpen={SetOpen}
                                          setSelectedWeek={setSelectedWeek}
                                      />
                                      {shift?.payload?.data?.validated ==
                                          true && (
                                          <StatusButton
                                              progress={{
                                                  completed: 0,
                                                  total: 12,
                                              }}
                                              onClick={SetOpenSignature}
                                              week={week}
                                              setSelectedWeek={setSelectedWeek}
                                          />
                                      )}
                                  </div>
                              ),
                              style: {
                                  padding: "10px 10px 10px 0",
                                  backgroundColor: "#fff",
                                  textAlign: "center" as const,
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "end",
                              },
                          },
                      ]
                    : [
                          {
                              name: "fname",
                              value: WeekDate(week, shift),
                              style: {
                                  padding: "10px 0",
                                  paddingLeft: "15px",
                                  color: "#4c5690",
                                  backgroundColor: "#fff",
                                  textAlign: "left" as const,
                              },
                          },

                          {
                              name: "actions",
                              value: (
                                  <div className="w-full flex items-center space-x-1 justify-end">
                                      <DownloadButton
                                          validWeek={shift}
                                          week={week}
                                          SetOpen={SetOpen}
                                          setSelectedWeek={setSelectedWeek}
                                      />
                                  </div>
                              ),
                              style: {},
                          },
                      ],
                style: {
                    borderBottom: "1px solid #EDEFF5",
                },
            };
        });
    }, [weeks, shiftData, listLoading]);
    const HolidayTableProps = {
        fields: {
            // Toggle this value to control the table structure

            fieldRow: signature
                ? [
                      {
                          title: (
                              <TextData
                                  color="#4c5690"
                                  variant="label"
                                  className={[
                                      "cursor-pointer",
                                      "font-medium",
                                      "text-lg",
                                  ]}
                              >
                                  Semaine
                              </TextData>
                          ),
                          name: "name",
                          variant: "label",
                          style: {
                              paddingLeft: "15px",
                              borderTop: "none",
                              width: "40%",
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "left",
                              background: "#fff",
                          },
                      },
                      {
                          title: (
                              <TextData
                                  color="#4c5690"
                                  variant="label"
                                  className={[
                                      "cursor-pointer",
                                      "font-medium",
                                      "text-lg",
                                  ]}
                              >
                                  Envoyés
                              </TextData>
                          ),
                          name: "name",
                          variant: "label",
                          style: {
                              borderTop: "none",
                              width: "10%",
                              padding: "10px 0",
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                          },
                      },

                      {
                          title: (
                              <TextData
                                  color="#4c5690"
                                  variant="label"
                                  className={[
                                      "cursor-pointer",
                                      "font-medium",
                                      "text-lg",
                                  ]}
                              >
                                  Signés
                              </TextData>
                          ),
                          name: "name",
                          variant: "label",
                          style: {
                              paddingLeft: "15px",
                              borderTop: "none",
                              width: "10%",
                              padding: "10px 0",
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                          },
                      },
                      {
                          title: <></>,
                          name: "name",
                          variant: "label",
                          style: {
                              paddingRight: "15px",
                              borderTop: "none",
                              width: "40%",
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                          },
                      },
                  ]
                : [
                      // Reduced structure when signature=false
                      {
                          title: (
                              <TextData
                                  color="#4c5690"
                                  variant="label"
                                  className={[
                                      "cursor-pointer",
                                      "font-medium",
                                      "text-lg",
                                  ]}
                              >
                                  Semaine
                              </TextData>
                          ),
                          name: "name",
                          variant: "label",
                          style: {
                              paddingLeft: "15px",
                              borderTop: "none",
                              width: "80%", // Adjust width to fill space
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "left",
                              background: "#fff",
                          },
                      },
                      {
                          title: (
                              <TextData
                                  color="#4c5690"
                                  variant="label"
                                  className={[
                                      "cursor-pointer",
                                      "font-medium",
                                      "text-lg",
                                  ]}
                              >
                                  {" "}
                              </TextData>
                          ),
                          name: "name",
                          variant: "label",
                          style: {
                              paddingLeft: "15px",
                              borderTop: "none",
                              width: "20%", // Adjust width to fill space
                          },
                          titleContainerStyle: {
                              display: "flex",
                              alignItems: "right",
                              background: "#000",
                          },
                      },
                  ],

            onClickRow: () => {},
            style: {
                borderBottom: "4px solid #EDEFF5",
                position: "sticky" as "sticky",
                top: 0,
                backgroundColor: "white",
            },
            className: [],
        },

        data: tableData,
    };
    const fetchEmployees = () => {
        getEmployeesByWeek({
            siteId: siteId,
            year: year,
            week: selectedWeek?.weekNumber,
        });
    };

    useEffect(() => {
        if (open || openSignature) {
            fetchEmployees();
        }
    }, [open, openSignature, siteId, month, year]);

    const sendSignature = ({
        week,
        year,
        siteId,
        userList,
    }: SignatureRecord) => {
        const storedSignature = localStorage.getItem("SignatureRecords");
        const records: SignatureRecord[] = storedSignature
            ? JSON.parse(storedSignature)
            : [];

        const existingRecordIndex = records.findIndex(
            (record) =>
                record.siteId === siteId &&
                record.week === week &&
                record.year === year,
        );

        if (existingRecordIndex !== -1) {
            // Update the existing record by adding to the userList without duplication
            const existingRecord = records[existingRecordIndex];
            const updatedUserList = Array.from(
                new Set([...existingRecord.userList, ...userList]), // Merge and remove duplicates
            );
            records[existingRecordIndex] = {
                ...existingRecord,
                userList: updatedUserList,
            };
        } else {
            // Add a new record
            records.push({ week, year, siteId, userList });
        }

        // Save the updated records back to local storage
        localStorage.setItem("SignatureRecords", JSON.stringify(records));
    };

    return (
        <>
            {listLoading ? (
                <>
                    <div style={{ height: 400 }}>
                        <Loading width="400px" height="200px" />
                    </div>
                </>
            ) : (
                <>
                    <Table {...HolidayTableProps} />
                    <FeuillePopup
                        week={selectedWeek?.weekNumber}
                        year={year}
                        siteId={siteId}
                        SetOpen={SetOpen}
                        open={open}
                        pointeuseEmployees={pointeuseEmployees}
                    />
                    {/* <SignaturePopup
                        week={selectedWeek?.weekNumber}
                        year={year}
                        siteId={siteId}
                        SetOpen={SetOpenSignature}
                        open={openSignature}
                        pointeuseEmployees={pointeuseEmployees}
                        onSubmit={sendSignature}
                        updateSignatureConsumed={updateSignatureConsumed}
                        getUserList={getUserList}
                    /> */}
                </>
            )}
        </>
    );
};
export default AccordionBody;
