 
import { useEffect } from "react";
import Swal from "sweetalert2";
import { shallowEqual } from "react-redux";

import { useAppDispatch, useAppSelector } from "./useReduxHooks";
import { API } from "../constants/env";

// import {
//     getListOfManagedBranchs,
//     getBranch,
//     updateBranch,
//     updateSiteSettings,
//     fetchBranchSettings,
//     fetchPauseRepasConditions,
//     addPauseRepasCondition,
//     removePauseRepasCondition,
// } from "../redux/features/settings/service";

// import {
//     BranchSettingRequest,
//     SelectedBranchPayload,
// } from "../redux/features/settings/types";

import {
    setDateRange,
    setReportData,
    setReportConfigs,
    setHasConfig,
} from "../redux/features/analytics/analyticsSlice";
export const useAnalytics = () => {
    const dispatch = useAppDispatch();
    const { dateRange, data, loading, configs, hasConfig } = useAppSelector(
        (state: any) => state.analytics,
        shallowEqual,
    );
    const getAnalytics = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch && dateRange.startDate && dateRange.endDate) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;

            try {
                dispatch(setReportData({ loading: true }));
                const response = await fetch(
                    API.client.productivity.getReport(siteId) +
                        `?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`,
                    {
                        method: "GET",
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    dispatch(setReportData({ data }));
                }
            } catch (err) {
                console.error(err);
                dispatch(setReportData({ loading: false }));
            }
        }
        dispatch(setReportData({ loading: false }));
    };
    const getAnalyticsConfigs = async () => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (selectedBranch) {
            const parsedBranch = JSON.parse(selectedBranch);
            const siteId = parsedBranch._id;
            try {
                const response = await fetch(
                    API.client.productivity.getConfig(siteId),
                    {
                        method: "GET",
                        headers: {
                            authorization: `Bearer ${localStorage.getItem(
                                "token",
                            )}`,
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (response.ok) {
                    const data = await response.json();
                    console.log(" data config = ", data);
                    dispatch(setReportConfigs(data));
                } else {
                    dispatch(setReportConfigs(null));
                    dispatch(setHasConfig(false));
                }
            } catch (err) {
                console.error(err);
                dispatch(setHasConfig(false));
            }
        }
    };
    const updateAnalyticsPerDay = async ({
        reportID,
        reportDate,
        sales,
        reportType,
    }: {
        reportID: string;
        reportDate: string;
        sales: number;
        reportType: "estimatedReport" | "actualReport";
    }) => {
        try {
            const response = await fetch(
                API.client.productivity.setDaySales(reportID),
                {
                    method: "PATCH",
                    headers: {
                        authorization: `Bearer ${localStorage.getItem(
                            "token",
                        )}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        reportDate,
                        sales,
                        reportType,
                    }),
                },
            );
            if (response.ok) {
                const data = await response.json();
                dispatch(setReportData({ data }));
            }
        } catch (err) {
            console.error(err);
        }
    };
    const setIntitialConfig = () => {};
    const updateDateRange = (dates: { startDate: string; endDate: string }) => {
        dispatch(setDateRange(dates));
    };
    return {
        dateRange,
        data,
        loading,
        configs,
        hasConfig,
        updateDateRange,
        getAnalytics,
        getAnalyticsConfigs,
        updateAnalyticsPerDay,
        setIntitialConfig,
    };
};
