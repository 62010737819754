import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    SettingsState,
    BranchSettingResponse,
    multiplePlanningPayload,
    ticketPayload,
    ServiceResponse,
    BonusTypeResponse,
    absencePayload,
    timeTrackerPayload,
    GetSiteSettingsPayload,
    DPAE_Payload,
    TagsPayload,
    ExistingTemplatePayload,
    ExistingTemplateResponse,
    nightTimeRule,
} from "./types";
import {
    absences,
    isHourRegex,
    isNumber,
    isValidSalaryFrequencyType,
} from "../../../_helpers/Functions";
import {
    getListOfManagedBranchs,
    getBranch,
    fetchBranchSettings,
    fetchPauseRepasConditions,
    updateBranch,
    getMultiplePlanning,
    getTicket,
    getService,
    getBonusType,
    getAbsence,
    saveMultiplePlanning,
    deleteMultiplePlanning,
    saveTicket,
    removeTicket,
    saveService,
    removeService,
    generateBonusType,
    modifyBonusType,
    removeBonusType,
    modifyAbsence,
    getTimeTrackerService,
    updateTimeTrackerService,
    updateMobileService,
    getMobileService,
    getDPAEService,
    getContractTemplateTagsService,
    getContractTemplateBySiteService,
    postNewContractTemplateService,
    updateDPAEinfo,
    getAdministrativeService,
    getVacationService,
    getAllCollectiveAgreementsService,
    getConfigs,
    updateConfigs,
} from "./service";
import { API, ROUTER } from "../../../constants/env";
const branchfakedata = {
    _id: null,
    ouverture: "",
    fermeture: "",
    is24Hours: false,
    pause: null,
    payed: false,
    joursIndisponibilite: null,
    joursConge: null,
    changementShift: true,
    paie: true,
    multiplePlanning: true,
    salaries: "daily" as "daily",
    daysPerMonth: null,
    register: true,
    activateEmergencePage: false,
    usePause: false,
    useRepas: false,
    pauseRepasConditions: [],
    showNightHoursReport: false,
    nightHours: { start: "", end: "" },
    showSundayHoursReport: false,
    showHolidaysHoursReport: false,
    offDays: [],
    showOnlyThisMonthRapport: false,
    enableSilae: false,
    canSeeCaisse: false,
};
const initializeSelectedBranch = () => {
    const var_selectedBranch = sessionStorage.getItem("selectedBranch");
    if (var_selectedBranch && var_selectedBranch != "") {
        return JSON.parse(var_selectedBranch);
    } else {
        return {
            _idl: null,
            active: null,
            address: null,
            codeNaf: null,
            created_at: null,
            deleted: null,
            lastSync: null,
            license: null,
            name: null,
            numeroTva: null,
            serialKey: null,
            siret: null,
            updatedAt: null,
            ville: null,
            zipCode: null,
        };
    }
};
const initialScheduleData = {
    authorization: false,
    multiplePlanningStatus: false,
    multiplePlanning: [],
    selectedPlan: {},
    tickets: [],
    selectedTicket: {},
    services: [],
    selectedService: {},
    bonusTypes: [],
    selectedBonusType: {},
    absences: absences,
    selectedAbsence: {},
};
const initialTimeTrackerData = {
    useIt: false,
    allowPauses: false,
    takePhoto: false,
    rotatePhoto: false,
    alerttime: "30",
    alerttimego: "30",
    allowCameBefore: false,
    allowCameAfter: false,
    allowGoBefore: false,
    allowGoAfter: false,
    toleranceCame: "5",
    toleranceGo: "5",
    validateWeek: false,
    comments: false,
    repas: false,
    commentInPointeuse: false,
};
const initialMobile = {
    downloadPlanningFromMobile: false,
    seeOthersPlanning: false,
    employeeConges: false,
    employeeIndisponibility: false,
    reportPageVisibility: false,
    pauseInReportVisibility: false,
};
const initialAdministrative = {
    enableDPAE: false,
    enableContractTemplates: false,
    enableCollectiveAgreement: false,
    enforceCollectiveAgreement: false,
    dpae: {
        status: false,
        siret: "",
        enseigne: "",
        apeCode: "",
        urssafCode: "",
        address: "",
        ville: "",
        zipCode: "",
        phoneNum: "",
    },
    contractTemplate: null,
    listOfCollectiveAgreements: null,
    selectedCollectiveAgreement: null,
};
const initialVacation = {
    entitlementCalculationDate: 29,
    enableLeaveRuleEntitlement: false,
};
const initialState: SettingsState = {
    loading: false,
    error: false,
    success: false,
    site: "",
    branch: branchfakedata,
    schedule: initialScheduleData,
    timeTracker: initialTimeTrackerData,
    mobile: initialMobile,
    selectedBranch: initializeSelectedBranch(),
    administrative: initialAdministrative,
    vacation: initialVacation,
    analysisConfig: null,
};
const settings = createSlice({
    name: "settings",
    initialState,
    reducers: {
        resetAllSettings: (state) => {
            state = initialState;
        },
        // Status
        resetStatus: (state) => {
            state.error = false;
            state.success = false;
            state.loading = false;
        },
        // Site update
        updateSiteSetting: (state, action) => {
            if (action.payload?.site) {
                state.site = action.payload?.site;
            }
        },
        // Branch Settings
        updateBranchID: (state, action) => {
            if (action.payload?._id) {
                state.branch._id = action.payload?._id;
            }
        },
        updateBranchis24Hours: (state, action) => {
            if (action.payload?.is24Hours !== undefined) {
                state.branch.is24Hours = action.payload?.is24Hours;
            }
        },
        updateBranchOpeningHour: (state, action) => {
            if (
                action.payload?.openingHour &&
                isHourRegex(action.payload?.openingHour)
            ) {
                state.branch.ouverture = action.payload?.openingHour;
            }
        },
        toggleSilae: (state) => {
            state.branch.enableSilae = !!!state.branch.enableSilae;
        },
        toggleShowCaisse: (state) => {
            state.branch.canSeeCaisse = !!!state.branch.canSeeCaisse;
        },
        updateBranchClosingHour: (state, action) => {
            if (
                action.payload?.closingHour &&
                isHourRegex(action.payload?.closingHour)
            ) {
                state.branch.fermeture = action.payload?.closingHour;
            }
        },
        updateBranchBreakDuration: (state, action) => {
            if (
                action.payload?.breakDuration !== undefined &&
                isNumber(action.payload?.breakDuration)
            ) {
                state.branch.pause = action.payload?.breakDuration;
            }
        },
        updateAnalysisConfigs: (state, action) => {
            // if (
            //     action.payload?.productivity !== undefined &&
            //     state.analysisConfig !== null &&
            //     state.analysisConfig.productivity !== undefined
            // ) {
            state.analysisConfig = {
                ...state.analysisConfig,
                productivity: action.payload?.productivity,
            };
            // }
        },
        updateBranchPayed: (state, action) => {
            if (typeof action.payload?.payed == "boolean") {
                state.branch.payed = action.payload?.payed;
            }
        },
        updateBranchDaysForUnavailabilityNotice: (state, action) => {
            if (
                action.payload?.daysForUnavailabilityNotice &&
                isNumber(action.payload?.daysForUnavailabilityNotice)
            ) {
                state.branch.joursIndisponibilite =
                    action.payload?.daysForUnavailabilityNotice;
            }
        },
        updateBranchDaysForVacationNotice: (state, action) => {
            if (
                action.payload?.daysForVacationNotice &&
                isNumber(action.payload?.daysForVacationNotice)
            ) {
                state.branch.joursConge = action.payload?.daysForVacationNotice;
            }
        },
        updateBranchShiftChange: (state, action) => {
            if (typeof action.payload?.changementShift == "boolean") {
                state.branch.changementShift = action.payload?.changementShift;
            }
        },
        updateBranchEnablePayroll: (state, action) => {
            if (action.payload?.enablePayroll != null) {
                state.branch.paie = action.payload?.enablePayroll;
            }
        },
        updateBranchMultiPlanning: (state, action) => {
            if (typeof action.payload?.multiplePlanning == "boolean") {
                state.branch.multiplePlanning =
                    action.payload?.multiplePlanning;
            }
        },
        updateBranchSalaryCalculationFrequency: (state, action) => {
            if (
                action.payload?.salaryCalculationFrequency &&
                isValidSalaryFrequencyType(
                    action.payload?.salaryCalculationFrequency,
                )
            ) {
                state.branch.salaries =
                    action.payload?.salaryCalculationFrequency;
            }
        },
        updateBranchWorkDaysPerMonth: (state, action) => {
            const workDaysPerMonth = action.payload?.workDaysPerMonth;
            if (
                workDaysPerMonth &&
                isNumber(workDaysPerMonth) &&
                workDaysPerMonth > 0 &&
                workDaysPerMonth < 32
            ) {
                state.branch.daysPerMonth = workDaysPerMonth;
            }
        },
        updateBranchEnableRegister: (state, action) => {
            if (action.payload?.enableRegister != null) {
                state.branch.register = action.payload?.enableRegister;
            }
        },
        updateActivateEmergencePage: (state, action) => {
            if (action.payload?.activateEmergencePage != null) {
                state.branch.activateEmergencePage =
                    action.payload?.activateEmergencePage;
            }
        },
        updateBranchUseRepas: (state, action) => {
            if (action.payload?.useRepas != null) {
                state.branch.useRepas = action.payload?.useRepas;
            }
        },
        updateBranchUsePause: (state, action) => {
            if (action.payload?.usePause != null) {
                state.branch.usePause = action.payload?.usePause;
            }
        },
        updateBranchShowHours: (state, action) => {
            if (action.payload?.showNightHoursReport !== undefined) {
                state.branch.showNightHoursReport =
                    action.payload?.showNightHoursReport;
            }
            if (action.payload?.showSundayHoursReport !== undefined) {
                state.branch.showSundayHoursReport =
                    action.payload?.showSundayHoursReport;
            }
            if (action.payload?.showHolidaysHoursReport !== undefined) {
                state.branch.showHolidaysHoursReport =
                    action.payload?.showHolidaysHoursReport;
            }
            if (action.payload?.nightHours !== undefined) {
                state.branch.nightHours = {
                    ...state.branch.nightHours,
                    ...action.payload?.nightHours,
                };
            }
        },
        updateBranchDaysOff: (state, action) => {
            if (action.payload !== null) {
                state.branch.offDays = action.payload;
            }
        },
        // Selected Settings
        resetSelectedBranch: (state) => {
            state.selectedBranch._id = null;
            state.selectedBranch.active = null;
            state.selectedBranch.address = null;
            state.selectedBranch.codeNaf = null;
            state.selectedBranch.created_at = null;
            state.selectedBranch.deleted = null;
            state.selectedBranch.lastSync = null;
            state.selectedBranch.license = null;
            state.selectedBranch.name = null;
            state.selectedBranch.numeroTva = null;
            state.selectedBranch.serialKey = null;
            state.selectedBranch.siret = null;
            state.selectedBranch.updatedAt = null;
            state.selectedBranch.ville = null;
            state.selectedBranch.zipCode = null;
        },
        updateSelectedBranchID: (state, action) => {
            if (action.payload) {
                state.selectedBranch._id = action.payload;
            }
        },
        updateSelectedBranchActive: (state, action) => {
            if (action.payload !== undefined) {
                state.selectedBranch.active = action.payload;
            }
        },

        updateSelectedBranchAddress: (state, action) => {
            if (action.payload) {
                state.selectedBranch.address = action.payload;
            }
        },

        updateSelectedBranchCodeNaf: (state, action) => {
            if (action.payload) {
                state.selectedBranch.codeNaf = action.payload;
            }
        },

        updateSelectedBranchCreatedAt: (state, action) => {
            if (action.payload) {
                state.selectedBranch.created_at = action.payload;
            }
        },

        updateSelectedBranchDeleted: (state, action) => {
            if (action.payload !== undefined) {
                state.selectedBranch.deleted = action.payload;
            }
        },

        updateSelectedBranchLastSync: (state, action) => {
            if (action.payload) {
                state.selectedBranch.lastSync = action.payload;
            }
        },

        updateSelectedBranchLicense: (state, action) => {
            if (action.payload) {
                state.selectedBranch.license = action.payload;
            }
        },

        updateSelectedBranchName: (state, action) => {
            if (action.payload) {
                state.selectedBranch.name = action.payload;
            }
        },

        updateSelectedBranchNumeroTva: (state, action) => {
            if (action.payload) {
                state.selectedBranch.numeroTva = action.payload;
            }
        },
        updateSelectedBranchSerialKey: (state, action) => {
            if (action.payload) {
                state.selectedBranch.serialKey = action.payload;
            }
        },
        updateSelectedBranchSiret: (state, action) => {
            if (action.payload) {
                state.selectedBranch.siret = action.payload;
            }
        },
        updateSelectedBranchUpdatedAt: (state, action) => {
            if (action.payload) {
                state.selectedBranch.updatedAt = action.payload;
            }
        },
        updateSelectedBranchVille: (state, action) => {
            if (action.payload) {
                state.selectedBranch.ville = action.payload;
            }
        },
        updateSelectedBranchZipCode: (state, action) => {
            if (action.payload) {
                state.selectedBranch.zipCode = action.payload;
            }
        },
        //schedule selectedplan
        updateSelectedPlan: (state, action) => {
            if (state.schedule && state.schedule.selectedPlan) {
                if (action.payload._id !== undefined) {
                    state.schedule.selectedPlan._id = action.payload._id;
                }
                if (action.payload.employees !== undefined) {
                    state.schedule.selectedPlan.employees =
                        action.payload.employees;
                }
                if (action.payload.name !== undefined) {
                    state.schedule.selectedPlan.name = action.payload.name;
                }
                if (action.payload.site !== undefined) {
                    state.schedule.selectedPlan.site = action.payload.site;
                }
            }
        },
        updateSelectedTicket: (state, action) => {
            if (state.schedule && state.schedule.selectedTicket) {
                if (action.payload._id !== undefined) {
                    state.schedule.selectedTicket._id = action.payload._id;
                }
                if (action.payload.color !== undefined) {
                    state.schedule.selectedTicket.color = action.payload.color;
                }
                if (action.payload.name !== undefined) {
                    state.schedule.selectedTicket.name = action.payload.name;
                }
                if (action.payload.site !== undefined) {
                    state.schedule.selectedTicket.site = action.payload.site;
                }
                if (action.payload.fromHour !== undefined) {
                    state.schedule.selectedTicket.fromHour =
                        action.payload.fromHour;
                }
                if (action.payload.toHour !== undefined) {
                    state.schedule.selectedTicket.toHour =
                        action.payload.toHour;
                }
            }
        },
        updateSelectedService: (state, action) => {
            if (state.schedule && state.schedule.selectedService) {
                if (action.payload.id !== undefined) {
                    state.schedule.selectedService.id = action.payload.id;
                }
                if (action.payload.from !== undefined) {
                    state.schedule.selectedService.from = action.payload.from;
                }
                if (action.payload.to !== undefined) {
                    state.schedule.selectedService.to = action.payload.to;
                }
                if (action.payload.name !== undefined) {
                    state.schedule.selectedService.name = action.payload.name;
                }
                if (action.payload.siteId !== undefined) {
                    state.schedule.selectedService.siteId =
                        action.payload.siteId;
                }
            }
        },
        updateSelectedBonusType: (state, action) => {
            if (state.schedule && state.schedule.selectedBonusType) {
                if (action.payload.acompteTypeId !== undefined) {
                    state.schedule.selectedBonusType.acompteTypeId =
                        action.payload.acompteTypeId;
                }
                if (action.payload.affectSalary !== undefined) {
                    state.schedule.selectedBonusType.affectSalary =
                        action.payload.affectSalary;
                }
                if (action.payload.affectType !== undefined) {
                    state.schedule.selectedBonusType.affectType =
                        action.payload.affectType;
                }
                if (action.payload.name !== undefined) {
                    state.schedule.selectedBonusType.name = action.payload.name;
                }
                if (action.payload.siteId !== undefined) {
                    state.schedule.selectedBonusType.siteId =
                        action.payload.siteId;
                }
            }
        },
        updateSelectedAbsence: (state, action) => {
            if (state.schedule && state.schedule.selectedAbsence) {
                if (action.payload._id !== undefined) {
                    state.schedule.selectedAbsence._id = action.payload._id;
                }
                if (action.payload.absence !== undefined) {
                    state.schedule.selectedAbsence.absence =
                        action.payload.absence;
                }
                if (action.payload.color !== undefined) {
                    state.schedule.selectedAbsence.color = action.payload.color;
                }
                if (action.payload.site !== undefined) {
                    state.schedule.selectedAbsence.site = action.payload.site;
                }
            }
        },
        //schedule settings
        updateScheduleAuthorization: (state, action) => {
            if (action.payload?.authorization !== undefined) {
                state.schedule.authorization = action.payload?.authorization;
            }
        },
        updateScheduleMultiplePlanningStatus: (state, action) => {
            if (action.payload?.multiplePlanningStatus !== undefined) {
                state.schedule.multiplePlanningStatus =
                    action.payload?.multiplePlanningStatus;
            }
        },
        // Pointeuse Settings
        updateTimeTrackerSetting: (state, action) => {
            if (action.payload?.useIt !== undefined) {
                state.timeTracker.useIt = action.payload?.useIt;
            }
            if (action.payload?.allowPauses !== undefined) {
                state.timeTracker.allowPauses = action.payload?.allowPauses;
            }
            if (action.payload?.takePhoto !== undefined) {
                state.timeTracker.takePhoto = action.payload?.takePhoto;
            }
            if (action.payload?.rotatePhoto !== undefined) {
                state.timeTracker.rotatePhoto = action.payload?.rotatePhoto;
            }
            if (action.payload?.alerttime !== undefined) {
                state.timeTracker.alerttime = action.payload?.alerttime;
            }
            if (action.payload?.alerttimego !== undefined) {
                state.timeTracker.alerttimego = action.payload?.alerttimego;
            }
            if (action.payload?.allowCameBefore !== undefined) {
                state.timeTracker.allowCameBefore =
                    action.payload?.allowCameBefore;
            }
            if (action.payload?.allowCameAfter !== undefined) {
                state.timeTracker.allowCameAfter =
                    action.payload?.allowCameAfter;
            }
            if (action.payload?.allowGoBefore !== undefined) {
                state.timeTracker.allowGoBefore = action.payload?.allowGoBefore;
            }
            if (action.payload?.allowGoAfter !== undefined) {
                state.timeTracker.allowGoAfter = action.payload?.allowGoAfter;
            }
            if (action.payload?.toleranceCame !== undefined) {
                state.timeTracker.toleranceCame = action.payload?.toleranceCame;
            }
            if (action.payload?.toleranceGo !== undefined) {
                state.timeTracker.toleranceGo = action.payload?.toleranceGo;
            }
            if (action.payload?.validateWeek !== undefined) {
                state.timeTracker.validateWeek = action.payload?.validateWeek;
            }
            if (action.payload?.comments !== undefined) {
                state.timeTracker.comments = action.payload?.comments;
            }
            if (action.payload?.commentInPointeuse !== undefined) {
                state.timeTracker.commentInPointeuse =
                    action.payload?.commentInPointeuse;
            }
            if (action.payload?.repas !== undefined) {
                state.timeTracker.repas = action.payload?.repas;
            }
            if (action.payload?.mustGoPause !== undefined) {
                state.timeTracker.mustGoPause = action.payload?.mustGoPause;
            }
            if (action.payload?.mustGoPauseTime !== undefined) {
                state.timeTracker.mustGoPauseTime =
                    action.payload?.mustGoPauseTime;
            }
            if (action.payload?.signatureDepartArriver !== undefined) {
                state.timeTracker.signatureDepartArriver =
                    action.payload?.signatureDepartArriver;
            }
            if (action.payload?.signaturePauses !== undefined) {
                state.timeTracker.signaturePauses =
                    action.payload?.signaturePauses;
            }
        },
        updateMobileSetting: (state, action) => {
            if (action.payload?.seeOthersPlanning !== undefined) {
                state.mobile.seeOthersPlanning =
                    action.payload?.seeOthersPlanning;
                if (action.payload?.seeOthersPlanning === false) {
                    state.mobile.downloadPlanningFromMobile = false;
                }
            }
            if (
                action.payload?.downloadPlanningFromMobile !== undefined &&
                state.mobile.seeOthersPlanning
            ) {
                state.mobile.downloadPlanningFromMobile =
                    action.payload?.downloadPlanningFromMobile;
            }
            if (action.payload?.employeeConges !== undefined) {
                state.mobile.employeeConges = action.payload?.employeeConges;
            }
            if (action.payload?.employeeIndisponibility !== undefined) {
                state.mobile.employeeIndisponibility =
                    action.payload?.employeeIndisponibility;
            }
            if (action.payload?.reportPageVisibility !== undefined) {
                state.mobile.reportPageVisibility =
                    action.payload?.reportPageVisibility;
            }
            if (action.payload?.pauseInReportVisibility !== undefined) {
                state.mobile.pauseInReportVisibility =
                    action.payload?.pauseInReportVisibility;
            }
        },
        // Administrative Settings
        updateAdministrativeNewContractTemplate: (state, action) => {
            if (
                state.administrative.contractTemplate === null ||
                state.administrative.contractTemplate === undefined ||
                state.administrative.contractTemplate.newTemplate === undefined
            ) {
                state.administrative.contractTemplate = {
                    ...state.administrative.contractTemplate,
                    newTemplate: {},
                };
            }
            if (
                state.administrative.contractTemplate.newTemplate !== undefined
            ) {
                if (action.payload.name !== undefined) {
                    state.administrative.contractTemplate.newTemplate.name =
                        action.payload.name;
                }
                if (action.payload.type !== undefined) {
                    state.administrative.contractTemplate.newTemplate.type =
                        action.payload.type;
                }
                if (action.payload.file !== undefined) {
                    state.administrative.contractTemplate.newTemplate.file =
                        action.payload.file;
                }
            }
        },
        updateAdministrativeDPAE: (state, action) => {
            if (
                state.administrative.dpae === null ||
                state.administrative.dpae === undefined
            ) {
                state.administrative.dpae = {};
            }
            if (state.administrative.dpae !== undefined) {
                if (action.payload?.status !== undefined) {
                    state.administrative.dpae.status = action.payload.status;
                }
                if (action.payload?.siret !== undefined) {
                    state.administrative.dpae.siret = action.payload?.siret;
                }
                if (action.payload?.enseigne !== undefined) {
                    state.administrative.dpae.enseigne =
                        action.payload?.enseigne;
                }
                if (action.payload?.apeCode !== undefined) {
                    state.administrative.dpae.apeCode = action.payload?.apeCode;
                }
                if (action.payload?.urssafCode !== undefined) {
                    state.administrative.dpae.urssafCode =
                        action.payload?.urssafCode;
                }
                console.log("address::", action.payload?.address);
                if (action.payload?.address !== undefined) {
                    state.administrative.dpae.address = action.payload?.address;
                }
                if (action.payload?.ville !== undefined) {
                    console.log("ville ::", action.payload?.ville);
                    state.administrative.dpae.ville = action.payload?.ville;
                }
                if (action.payload?.zipCode !== undefined) {
                    state.administrative.dpae.zipCode = action.payload?.zipCode;
                }
                if (action.payload?.phoneNum !== undefined) {
                    state.administrative.dpae.phoneNum =
                        action.payload?.phoneNum;
                }
                if (action.payload?.healthServiceCode !== undefined) {
                    state.administrative.dpae.healthServiceCode =
                        action.payload?.healthServiceCode;
                }
            }
        },
        updateAdministrativeState: (state, action) => {
            if (
                state.administrative !== undefined &&
                state.administrative !== null
            ) {
                if (action.payload.enableDPAE !== undefined) {
                    state.administrative.enableDPAE = action.payload.enableDPAE;
                }
                if (action.payload.enableContractTemplates !== undefined) {
                    state.administrative.enableContractTemplates =
                        action.payload.enableContractTemplates;
                }
            }
        },
        updateSelectedCollectiveAgreementReducer: (state, action) => {
            if (
                state.administrative !== undefined &&
                state.administrative !== null
            ) {
                if (action.payload !== null) {
                    state.administrative.selectedCollectiveAgreement =
                        action.payload;
                }
            }
        },
        updateEnableCollectiveAgreement: (state, action) => {
            state.administrative.enableCollectiveAgreement = action.payload;
        },
        updateEnforceCollectiveAgreement: (state, action) => {
            state.administrative.enforceCollectiveAgreement = action.payload;
        },
    },
    extraReducers: (builder) => {
        //get site settings
        builder.addCase(fetchBranchSettings.pending, (state: SettingsState) => {
            console.log(" pending");
        });
        builder.addCase(
            fetchBranchSettings.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<GetSiteSettingsPayload>,
            ) => {
                state.branch.useRepas = action?.payload?.useRepas ?? false;
                state.branch.usePause = action?.payload?.usePause ?? false;
                state.branch.showNightHoursReport =
                    action?.payload?.showNightHoursReport ?? false;
                state.branch.nightHours = action?.payload?.nightHours;
                state.branch.showSundayHoursReport =
                    action?.payload?.showSundayHoursReport ?? false;
                state.branch.showHolidaysHoursReport =
                    action?.payload?.showHolidaysHoursReport ?? false;
                state.branch.offDays = action?.payload?.offDays ?? [];
                state.branch.showOnlyThisMonthRapport =
                    action?.payload?.showOnlyThisMonthRapport ?? false;
                state.branch.enableSilae =
                    action?.payload?.enableSilae ?? false;
                state.branch.maxVacationDays =
                    action?.payload?.maxVacationDays ?? 0;
                state.branch.isJustifiedAbsenceCountedAsWorked =
                    action?.payload?.isJustifiedAbsenceCountedAsWorked ?? false;
                state.branch.justifiedAbsenceDuration =
                    action?.payload?.justifiedAbsenceDuration;
                state.branch.activateEmergencePage =
                    action.payload?.activateEmergencePage ?? false;
            },
        );
        builder.addCase(
            fetchBranchSettings.rejected,
            (state: SettingsState) => {
                console.log(" rejected");
            },
        );
        // pause et repas autotmatique
        builder.addCase(
            fetchPauseRepasConditions.pending,
            (state: SettingsState) => {},
        );
        builder.addCase(
            fetchPauseRepasConditions.fulfilled,
            (state: SettingsState, action: any) => {
                state.branch.pauseRepasConditions = action.payload;
            },
        );
        builder.addCase(
            fetchPauseRepasConditions.rejected,
            (state: SettingsState) => {
                console.log(" rejected conditions");
            },
        );
        // CASE 1 : get List Of Managed Branchs
        builder.addCase(
            getListOfManagedBranchs.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getListOfManagedBranchs.fulfilled,
            (state: SettingsState, action: PayloadAction<any>) => {
                if (JSON.stringify(action.payload) !== "undefined") {
                    state.success = true;
                    state.loading = false;
                    state.error = false;
                    sessionStorage.setItem(
                        "branchs",
                        JSON.stringify(action.payload),
                    );
                }
            },
        );
        builder.addCase(
            getListOfManagedBranchs.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 1 : user get branch settings
        builder.addCase(getBranch.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getBranch.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<BranchSettingResponse>,
            ) => {
                console.log(" here get site = ", action.payload);
                state.success = true;
                state.loading = false;
                state.error = false;

                state.branch.is24Hours = action.payload?.is24Hours;
                state.branch.ouverture = action.payload?.ouverture;
                state.branch.fermeture = action.payload?.fermeture;
                state.branch.pause = action.payload?.pause;

                state.branch.joursIndisponibilite =
                    action.payload.joursIndisponibilite;
                state.branch.joursConge = action.payload.joursConge;

                state.branch.register = action.payload.register;

                state.branch.paie = action.payload.paie;

                state.branch.salaries = action.payload.salaries;
                state.branch.daysPerMonth = action.payload.daysPerMonth;

                state.branch._id = action.payload._id;
                state.site = action.payload.site;
                state.branch.payed = action.payload.payed;
                state.branch.changementShift = action.payload.changementShift;
                state.schedule.authorization = action.payload.changementShift;
                state.branch.multiplePlanning = action.payload.multiplePlanning;
                state.schedule.multiplePlanningStatus =
                    action.payload.multiplePlanning;
            },
        );
        builder.addCase(getBranch.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        builder.addCase(getConfigs.fulfilled, (state: any, action: any) => {
            state.analysisConfig = action.payload;
        });
        builder.addCase(updateConfigs.fulfilled, (state: any, action: any) => {
            if (action.payload?._id) state.analysisConfig = action.payload;
        });
        //update site settings établissement
        //update site settings établissement

        // CASE 2 : get multiple planning
        builder.addCase(getMultiplePlanning.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getMultiplePlanning.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<multiplePlanningPayload[]>,
            ) => {
                state.success = false;
                state.loading = false;
                state.error = false;
                const lengthOfPayload = action.payload.length;
                state.schedule.multiplePlanning = [];
                for (let index = 0; index < lengthOfPayload; index++) {
                    state.schedule.multiplePlanning?.push({
                        _id: action.payload[index]._id,
                        employees: action.payload[index].employees,
                        name: action.payload[index].name,
                        site: action.payload[index].site,
                    });
                }
            },
        );
        builder.addCase(
            getMultiplePlanning.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        //CASE 2' : save multiple planning
        builder.addCase(
            saveMultiplePlanning.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            saveMultiplePlanning.fulfilled,
            (state: SettingsState) => {
                state.success = true;
                state.loading = false;
                state.error = false;
            },
        );
        builder.addCase(
            saveMultiplePlanning.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        //CASE 2'' : delete multiple planning
        builder.addCase(
            deleteMultiplePlanning.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            deleteMultiplePlanning.fulfilled,
            (state: SettingsState) => {
                state.success = true;
                state.loading = false;
                state.error = false;
            },
        );
        builder.addCase(
            deleteMultiplePlanning.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 3 : get tickets
        builder.addCase(getTicket.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getTicket.fulfilled,
            (state: SettingsState, action: PayloadAction<ticketPayload[]>) => {
                state.success = false;
                state.loading = false;
                state.error = false;
                const lengthOfPayload = action.payload.length;
                state.schedule.tickets = [];
                for (let index = 0; index < lengthOfPayload; index++) {
                    state.schedule.tickets?.push({
                        _id: action.payload[index]._id,
                        name: action.payload[index].name,
                        color: action.payload[index].color,
                        site: action.payload[index].site,
                        fromHour: action.payload[index].fromHour,
                        toHour: action.payload[index].toHour,
                    });
                }
            },
        );
        builder.addCase(getTicket.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 3' : save tickets
        builder.addCase(saveTicket.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(saveTicket.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(saveTicket.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 3'' : delete tickets
        builder.addCase(removeTicket.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(removeTicket.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(removeTicket.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 4 : get services
        builder.addCase(getService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getService.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<ServiceResponse[]>,
            ) => {
                state.success = false;
                state.loading = false;
                state.error = false;
                const lengthOfPayload = action.payload.length;
                state.schedule.services = [];
                for (let index = 0; index < lengthOfPayload; index++) {
                    state.schedule.services?.push({
                        id: action.payload[index]._id,
                        from: action.payload[index].from,
                        to: action.payload[index].to,
                        name: action.payload[index].name,
                        siteId: action.payload[index].site,
                    });
                }
            },
        );
        builder.addCase(getService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 4' : save services
        builder.addCase(saveService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(saveService.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(saveService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 4' : delete service
        builder.addCase(removeService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(removeService.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(removeService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 5 : get bonusTypes
        builder.addCase(getBonusType.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getBonusType.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<BonusTypeResponse[]>,
            ) => {
                state.success = false;
                state.loading = false;
                state.error = false;
                const lengthOfPayload = action.payload.length;
                state.schedule.bonusTypes = [];
                for (let index = 0; index < lengthOfPayload; index++) {
                    state.schedule.bonusTypes?.push({
                        acompteTypeId: action.payload[index]._id,
                        name: action.payload[index].name,
                        affectSalary: action.payload[index].affectSalary,
                        affectType: action.payload[index].affectType,
                        siteId: action.payload[index].site,
                    });
                }
            },
        );
        builder.addCase(getBonusType.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });

        // CASE 5' : create bonusTypes
        builder.addCase(generateBonusType.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(generateBonusType.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(generateBonusType.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 5'' : edit bonusTypes
        builder.addCase(modifyBonusType.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(modifyBonusType.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(modifyBonusType.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 5''' : delete bonusTypes
        builder.addCase(removeBonusType.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(removeBonusType.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(removeBonusType.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        //CASE 6 : get absences
        builder.addCase(getAbsence.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getAbsence.fulfilled,
            (state: SettingsState, action: PayloadAction<absencePayload[]>) => {
                state.success = false;
                state.loading = false;
                state.error = false;
                const lengthOfPayload = action.payload.length;
                let result: any = [];
                // for (let index = 0; index < lengthOfPayload; index++) {
                //     result = state.schedule.absences?.map((absence, i) => {
                //         if (absence.absence === action.payload[index].absence) {
                //             let selectedAbsence = state.schedule.absences?.[i];
                //             if (selectedAbsence) {
                //                 selectedAbsence.color =
                //                     action.payload[index].color;
                //                 selectedAbsence.site =
                //                     action.payload[index].site;
                //                 selectedAbsence._id = action.payload[index]._id;
                //             }
                //             console.log("selectedAbsence", selectedAbsence);
                //             return selectedAbsence;
                //         } else {
                //             return absence;
                //         }
                //     });
                // }
                result = state.schedule.absences?.map((absence, i) => {
                    const payloadItem = action.payload.find(
                        (item) => item.absence === absence.absence,
                    );

                    if (payloadItem) {
                        absence = {
                            ...absence,
                            color: payloadItem.color,
                            site: payloadItem.site,
                            _id: payloadItem._id,
                        };
                        console.log("selectedAbsence", absence);
                    }

                    return absence;
                });
                console.log("result", result);
                state.schedule.absences = result;
            },
        );
        builder.addCase(getAbsence.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });

        // CASE 6' : update absences
        builder.addCase(modifyAbsence.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(modifyAbsence.fulfilled, (state: SettingsState) => {
            state.success = true;
            state.loading = false;
            state.error = false;
        });
        builder.addCase(modifyAbsence.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 7 : get time tracker settings
        builder.addCase(
            getTimeTrackerService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getTimeTrackerService.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<GetSiteSettingsPayload>,
            ) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.timeTracker.useIt = action.payload.useIt;
                state.timeTracker.allowPauses = action.payload.allowPauses;
                state.timeTracker.takePhoto = action.payload.takePhoto;
                state.timeTracker.rotatePhoto = action.payload.rotatePhoto;
                state.timeTracker.alerttime = action.payload.alerttime;
                state.timeTracker.alerttimego = action.payload.alerttimego;
                state.timeTracker.allowCameBefore =
                    action.payload.allowCameBefore;
                state.timeTracker.allowCameAfter =
                    action.payload.allowCameAfter;
                state.timeTracker.allowGoBefore = action.payload.allowGoBefore;
                state.timeTracker.allowGoAfter = action.payload.allowGoAfter;
                state.timeTracker.toleranceCame = action.payload.toleranceCame;
                state.timeTracker.toleranceGo = action.payload.toleranceGo;
                state.timeTracker.validateWeek = action.payload.validateWeek;
                state.timeTracker.comments = action.payload.comments;
                state.timeTracker.comments = action.payload.comments;
                state.timeTracker.commentInPointeuse =
                    action.payload.commentInPointeuse;

                state.timeTracker.repas = action.payload.repas;
                state.timeTracker.mustGoPause = action.payload.mustGoPause;
                state.timeTracker.mustGoPauseTime =
                    action.payload.mustGoPauseTime;
                state.timeTracker.signatureDepartArriver =
                    action.payload.signatureDepartArriver;
                state.timeTracker.signaturePauses =
                    action.payload.signaturePauses;
            },
        );
        builder.addCase(
            getTimeTrackerService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 7 : get mobile settings
        builder.addCase(getMobileService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getMobileService.fulfilled,
            (
                state: SettingsState,
                action: any /*PayloadAction<GetSiteSettingsPayload>,*/,
            ) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.mobile.downloadPlanningFromMobile =
                    action.payload.downloadPlanningFromMobile;
                state.mobile.seeOthersPlanning =
                    action.payload.seeOthersPlanning;
                state.mobile.employeeConges = action.payload.employeeConges;
                state.mobile.employeeIndisponibility =
                    action.payload.employeeIndisponibility;
                state.mobile.reportPageVisibility =
                    action.payload.reportPageVisibility;
                state.mobile.pauseInReportVisibility =
                    action.payload.pauseInReportVisibility;
            },
        );
        builder.addCase(getMobileService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 8 : update time tracker settings
        builder.addCase(
            updateTimeTrackerService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            updateTimeTrackerService.fulfilled,
            (state: SettingsState) => {
                state.success = true;
                state.loading = false;
                state.error = false;
            },
        );
        builder.addCase(
            updateTimeTrackerService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 9 : update mobile settings
        builder.addCase(updateMobileService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            updateMobileService.fulfilled,
            (state: SettingsState) => {
                state.success = true;
                state.loading = false;
                state.error = false;
            },
        );
        builder.addCase(
            updateMobileService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 10 : get dpae settings
        builder.addCase(getDPAEService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getDPAEService.fulfilled,
            (state: SettingsState, action: PayloadAction<DPAE_Payload>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                if (state.administrative.dpae === null) {
                    state.administrative.dpae = {};
                }
                if (state.administrative.dpae !== undefined) {
                    if (action.payload.status !== undefined) {
                        state.administrative.dpae.status =
                            action.payload.status;
                    }
                    if (action.payload.siret !== undefined) {
                        state.administrative.dpae.siret = action.payload.siret;
                    }
                    if (action.payload.enseigne !== undefined) {
                        state.administrative.dpae.enseigne =
                            action.payload.enseigne;
                    }
                    if (action.payload.apeCode !== undefined) {
                        state.administrative.dpae.apeCode =
                            action.payload.apeCode;
                    }
                    if (action.payload.urssafCode !== undefined) {
                        state.administrative.dpae.urssafCode =
                            action.payload.urssafCode;
                    }
                    if (action.payload.address !== undefined) {
                        state.administrative.dpae.address =
                            action.payload.address;
                    }
                    if (action.payload.ville !== undefined) {
                        state.administrative.dpae.ville = action.payload.ville;
                    }
                    if (action.payload.zipCode !== undefined) {
                        state.administrative.dpae.zipCode =
                            action.payload.zipCode;
                    }
                    if (action.payload.phoneNum !== undefined) {
                        state.administrative.dpae.phoneNum =
                            action.payload.phoneNum;
                    }
                    if (action.payload.healthServiceCode !== undefined) {
                        state.administrative.dpae.healthServiceCode =
                            action.payload.healthServiceCode;
                    }
                }
            },
        );
        builder.addCase(getDPAEService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE : update dpae information
        builder.addCase(updateDPAEinfo.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            updateDPAEinfo.fulfilled,
            //TODO: change payload any type
            (state: SettingsState, action: PayloadAction<any>) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                if (action.payload.error.status) {
                    if (state.administrative.dpae === null) {
                        state.administrative.dpae = {};
                    }
                    state.administrative.dpae.status = true;
                } else {
                    if (state.administrative.dpae === null) {
                        state.administrative.dpae = {};
                    }
                    state.administrative.dpae.status = false;
                    if (state.administrative.dpae !== undefined) {
                        console.log("action.payload ::! ", action.payload);
                        if (action.payload.site.dpae.siret !== undefined) {
                            state.administrative.dpae.siret =
                                action.payload.site.dpae.siret;
                        }
                        if (action.payload.site.dpae.enseigne !== undefined) {
                            state.administrative.dpae.enseigne =
                                action.payload.site.dpae.enseigne;
                        }
                        if (action.payload.site.dpae.apeCode !== undefined) {
                            state.administrative.dpae.apeCode =
                                action.payload.site.dpae.apeCode;
                        }
                        if (action.payload.site.dpae.urssafCode !== undefined) {
                            state.administrative.dpae.urssafCode =
                                action.payload.site.dpae.urssafCode;
                        }
                        if (action.payload.site.dpae.address !== undefined) {
                            state.administrative.dpae.address =
                                action.payload.site.dpae.address;
                        }
                        if (action.payload.site.dpae.ville !== undefined) {
                            state.administrative.dpae.ville =
                                action.payload.site.dpae.ville;
                        }
                        if (action.payload.site.dpae.zipCode !== undefined) {
                            state.administrative.dpae.zipCode =
                                action.payload.site.dpae.zipCode;
                        }
                        if (action.payload.site.dpae.phoneNum !== undefined) {
                            state.administrative.dpae.phoneNum =
                                action.payload.site.dpae.phoneNum;
                        }
                    }
                }
            },
        );
        builder.addCase(updateDPAEinfo.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
        // CASE 11 : get contract templates tags
        builder.addCase(
            getContractTemplateTagsService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getContractTemplateTagsService.fulfilled,
            (state: SettingsState, action: PayloadAction<TagsPayload[]>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                if (action.payload) {
                    if (state.administrative.contractTemplate === null) {
                        state.administrative.contractTemplate = {
                            tags: [],
                        };
                    }
                    if (state.administrative.contractTemplate !== undefined) {
                        state.administrative.contractTemplate.tags = [];
                        for (const existingTag of action.payload) {
                            state.administrative.contractTemplate.tags.push({
                                tag: existingTag.tag,
                                designation: existingTag.designation,
                            });
                        }
                    }
                }
            },
        );
        builder.addCase(
            getContractTemplateTagsService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE 12 : get contract templates
        builder.addCase(
            getContractTemplateBySiteService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getContractTemplateBySiteService.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<ExistingTemplateResponse[]>,
            ) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                if (action.payload) {
                    if (state.administrative.contractTemplate === null) {
                        state.administrative.contractTemplate = {
                            existingTemplate: [],
                        };
                    }
                    if (state.administrative.contractTemplate !== undefined) {
                        state.administrative.contractTemplate.existingTemplate =
                            [];
                        for (const existingCT of action.payload) {
                            state.administrative.contractTemplate.existingTemplate.push(
                                {
                                    name: existingCT.name,
                                    type: existingCT.type,
                                    fileName: existingCT.fileName,
                                    fileType: existingCT.fileType,
                                    //TODO: uncomment this line in production
                                    // url: `${ROUTER.STATIC}${existingCT.link}`,
                                    url: `${ROUTER.STATIC_FILE2(existingCT?.link ?? "")}`,
                                    id: existingCT._id,
                                    fields: existingCT.fields,
                                },
                            );
                        }
                    }
                }
            },
        );
        builder.addCase(
            getContractTemplateBySiteService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE : getAdministrativeService
        builder.addCase(
            getAdministrativeService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getAdministrativeService.fulfilled,
            (
                state: SettingsState,
                action: PayloadAction<GetSiteSettingsPayload>,
            ) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.administrative.enableDPAE =
                    action.payload.enableDPAE ?? false;
                state.administrative.enableContractTemplates =
                    action.payload.enableContractTemplates ?? false;
                state.administrative.enableCollectiveAgreement =
                    action.payload.enableCollectiveAgreement ?? false;
                state.administrative.enforceCollectiveAgreement =
                    action.payload.enforceCollectiveAgreement ?? false;
                if (state.administrative.selectedCollectiveAgreement == null) {
                    if (
                        action.payload.collectiveAgreement !== null &&
                        action.payload.collectiveAgreement !== undefined
                    ) {
                        console.log(
                            "action.payload.collectiveAgreement.night_time_rules",
                            action.payload.collectiveAgreement.night_time_rules,
                        );
                        state.administrative.selectedCollectiveAgreement = {
                            _id: action.payload.collectiveAgreement._id,
                            designation:
                                action.payload.collectiveAgreement.designation,
                            min_consecutive_rest_hours:
                                action.payload.collectiveAgreement
                                    .min_consecutive_rest_hours,
                            max_weekly_work_hours:
                                action.payload.collectiveAgreement
                                    .max_weekly_work_hours,
                            max_consecutive_work_days:
                                action.payload.collectiveAgreement
                                    .max_consecutive_work_days,
                            max_daily_work_hours:
                                action.payload.collectiveAgreement
                                    .max_daily_work_hours,
                            break_when_max_hours_reached:
                                action.payload.collectiveAgreement
                                    .break_when_max_hours_reached,
                            max_consecutive_work_hours_without_rest:
                                action.payload.collectiveAgreement
                                    .max_consecutive_work_hours_without_rest,
                            overtime_details:
                                action.payload.collectiveAgreement
                                    .overtime_details,
                            night_time_rules:
                                action.payload.collectiveAgreement?.night_time_rules?.map(
                                    (rule: nightTimeRule) => {
                                        return {
                                            _id: rule._id,
                                            min_hours: rule.min_hours,
                                            max_hours: rule.max_hours,
                                            percentage: rule.percentage,
                                        };
                                    },
                                ),
                        };
                    }
                }
            },
        );
        builder.addCase(
            getAdministrativeService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE : get all collective agreements
        builder.addCase(
            getAllCollectiveAgreementsService.pending,
            (state: SettingsState) => {
                state.success = false;
                state.loading = true;
                state.error = false;
            },
        );
        builder.addCase(
            getAllCollectiveAgreementsService.fulfilled,
            (state: SettingsState, action: PayloadAction<any>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.administrative.listOfCollectiveAgreements = [];
                state.administrative.listOfCollectiveAgreements =
                    action.payload.map((collectiveAgreement: any) => {
                        return {
                            _id: collectiveAgreement._id,
                            designation: collectiveAgreement.designation,
                            min_consecutive_rest_hours:
                                collectiveAgreement.min_consecutive_rest_hours,
                            max_weekly_work_hours:
                                collectiveAgreement.max_weekly_work_hours,
                            max_consecutive_work_days:
                                collectiveAgreement.max_consecutive_work_days,
                            max_daily_work_hours:
                                collectiveAgreement.max_daily_work_hours,
                            break_when_max_hours_reached:
                                collectiveAgreement.break_when_max_hours_reached,
                            max_consecutive_work_hours_without_rest:
                                collectiveAgreement.max_consecutive_work_hours_without_rest,
                            overtime_details:
                                collectiveAgreement.overtime_details,
                            night_time_rules:
                                collectiveAgreement.night_time_rules.map(
                                    (rule: nightTimeRule) => {
                                        return {
                                            _id: rule._id,
                                            min_hours: rule.min_hours,
                                            max_hours: rule.max_hours,
                                            percentage: rule.percentage,
                                        };
                                    },
                                ),
                        };
                    });
            },
        );
        builder.addCase(
            getAllCollectiveAgreementsService.rejected,
            (state: SettingsState) => {
                state.success = false;
                state.loading = false;
                state.error = true;
            },
        );
        // CASE : getVacationService
        builder.addCase(getVacationService.pending, (state: SettingsState) => {
            state.success = false;
            state.loading = true;
            state.error = false;
        });
        builder.addCase(
            getVacationService.fulfilled,
            (state: SettingsState, action: PayloadAction<any>) => {
                state.success = true;
                state.loading = false;
                state.error = false;
                state.vacation.entitlementCalculationDate =
                    action.payload.entitlementCalculationDate;
                state.vacation.enableLeaveRuleEntitlement =
                    action.payload.enableLeaveRuleEntitlement;
            },
        );
        builder.addCase(getVacationService.rejected, (state: SettingsState) => {
            state.success = false;
            state.loading = false;
            state.error = true;
        });
    },
});

export const {
    resetAllSettings,
    resetStatus,
    // Site update
    updateSiteSetting,
    // Branch Settings
    updateBranchID,
    updateBranchis24Hours,
    updateBranchOpeningHour,
    updateBranchClosingHour,
    updateBranchBreakDuration,
    updateBranchPayed,
    updateBranchDaysForUnavailabilityNotice,
    updateBranchDaysForVacationNotice,
    updateBranchShiftChange,
    updateBranchEnablePayroll,
    updateBranchMultiPlanning,
    updateBranchSalaryCalculationFrequency,
    updateBranchWorkDaysPerMonth,
    updateBranchEnableRegister,
    updateBranchUsePause,
    updateBranchUseRepas,
    updateBranchShowHours,
    updateBranchDaysOff,
    updateActivateEmergencePage,

    updateAnalysisConfigs,
    // Selected Settings
    resetSelectedBranch,
    updateSelectedBranchID,
    updateSelectedBranchActive,
    updateSelectedBranchAddress,
    updateSelectedBranchCodeNaf,
    updateSelectedBranchCreatedAt,
    updateSelectedBranchDeleted,
    updateSelectedBranchLastSync,
    updateSelectedBranchLicense,
    updateSelectedBranchName,
    updateSelectedBranchNumeroTva,
    updateSelectedBranchSerialKey,
    updateSelectedBranchSiret,
    updateSelectedBranchUpdatedAt,
    updateSelectedBranchVille,
    updateSelectedBranchZipCode,
    //schedule selectedPlan
    updateSelectedPlan,
    updateSelectedTicket,
    updateSelectedService,
    updateSelectedBonusType,
    updateSelectedAbsence,
    // schedule settings
    updateScheduleAuthorization,
    updateScheduleMultiplePlanningStatus,
    // Pointeuse Settings
    updateTimeTrackerSetting,
    // Mobile Settings
    updateMobileSetting,
    // Administrative Settings
    updateAdministrativeNewContractTemplate,
    updateAdministrativeDPAE,
    updateAdministrativeState,
    //collectiveAgreement
    updateSelectedCollectiveAgreementReducer,
    updateEnableCollectiveAgreement,
    updateEnforceCollectiveAgreement,

    toggleSilae,
    toggleShowCaisse,
} = settings.actions;
export default settings.reducer;
