import React from "react";
import "./style.css";
import Modal from "../../atoms/Modal";
import Loading from "../../atoms/Loading";
import PopUpCard from "../../molecules/PopUpCard";
import TextData from "../../atoms/TextData";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as ChevronSVG } from "./Chevron.svg";
import { ReactComponent as EnterSVG } from "./enter.svg";
import { ReactComponent as ExistSVG } from "./exit.svg";
import { ReactComponent as CameraSVG } from "./camera.svg";
import { ReactComponent as RotateSVG } from "./rotate.svg";
import { ReactComponent as NoPhotoSVG } from "./no-photo.svg";
import img from "./img.jpg";
import img1 from "./img1.jpg";
import img2 from "./img2.jpg";
import moment from "moment";
import { employeeRoleColor } from "../../../_helpers/Functions";
import ProfilePicture from "../../molecules/ProfilePicture";
import usePointeuse from "../../../hooks/usePointeuse";

import { ROUTER } from "../../../constants/env";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
export default function ImageSliderCard(props: any) {
    const { employee, day, display, onClose, images, onSlide, selectedIdex } =
        props;
    const pictureProps = {
        imgPath: ROUTER.STATIC_IMG(employee?.picture),
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        style: { borderRadius: "50%" },
    };
    const files = React.useMemo(() => {
        let arr: any[] = [];
        images?.map((item: any) => {
            if (item.filePath) {
                arr.push({ ...item, file: item.filePath });
            }
        });
        return arr;
    }, [images]);
    React.useEffect(() => {
        setDisplayedImage(0);
    }, [images]);
    const [displayedImage, setDisplayedImage] = React.useState(0);
    React.useEffect(() => {
        if (!display) setDisplayedImage(0);
    }, [display]);
    const { employeesById, uniqueEmployees, filteredDataByDepartments } =
        usePointeuse();
    // const changeEmployeeImages = (added: number) => {
    //     let nextId: any = null;
    //     for (let i = 0; i < uniqueEmployees.length; i++) {
    //         if (uniqueEmployees[i] === employee._id) {
    //             if (added === 1 && i + 1 < uniqueEmployees.length) {
    //                 nextId = i + 1;
    //                 break;
    //             }
    //             if (added === -1 && i - 1 >= 0) {
    //                 nextId = i - 1;
    //                 break;
    //             }
    //         }
    //     }
    //     console.log(" nextId  ", nextId);
    //     console.log(" the id =   ", uniqueEmployees[nextId]);

    //     if (nextId) {
    //         let imagesArr: any = [];

    //         let shifts =
    //             filteredDataByDepartments[uniqueEmployees[nextId]].shifts;
    //         for (let shift of shifts) {
    //             if (shift) {
    //                 if (shift.shiftbadge?.arriver[0]?.filePath) {
    //                     imagesArr.push(shift.shiftbadge?.arriver[0]);
    //                 }
    //                 if (shift.shiftbadge?.quitter[0]?.filePath) {
    //                     imagesArr.push(shift.shiftbadge?.quitter[0]);
    //                 }
    //             }
    //         }
    //         setImages(imagesArr);
    //     }
    // };
    const { updateTimeTracker, timeTracker } = useTimeTracker();
    const [rotatePhoto, setRotatePhoto] = React.useState(false);

    React.useEffect(() => {
        setRotatePhoto(timeTracker.rotatePhoto);
    }, [timeTracker.rotatePhoto]);
    const handleToggleImageRotation = () => {
        updateTimeTracker({ rotatePhoto: !rotatePhoto });
        setRotatePhoto((prev: boolean) => !prev);
    };
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div
                style={{
                    height: "90vh",
                    width: "80vw",
                    backgroundColor: "rgba(255, 255,255, 100%)",
                    borderRadius: "10px",
                }}
            >
                <div
                    style={{
                        height: "100px",
                        display: "flex",
                        // justifyContent: "flex-end",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                        borderRadius: "10px 10px 0 0",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 20px",
                            padding: "5px",
                        }}
                    >
                        <ChevronSVG
                            height={50}
                            width={50}
                            color="inherit"
                            style={{ cursor: "pointer" }}
                            onClick={() => onSlide(selectedIdex - 1)}
                        />
                        <TextData
                            variant="label"
                            width="auto"
                            size="22px"
                            fontWeight="700"
                            color="#2A346D"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    width: "fit-content",
                                    height: "fit-content",
                                }}
                            >
                                <ProfilePicture
                                    profileImage={employee?.picture}
                                    firstName={employee?.fname}
                                    lastName={employee?.lname}
                                    pictureProps={pictureProps}
                                    altColor={employeeRoleColor(employee?.role)}
                                />
                            </div>
                            <div>
                                <div>
                                    {employee?.fname} {employee?.lname}
                                </div>
                                <div
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                    }}
                                >
                                    {moment(day, "YYYY-MM-DD")
                                        .locale("fr")
                                        .format("dddd D MMMM YYYY")}
                                </div>
                            </div>
                        </TextData>
                        <ChevronSVG
                            height={50}
                            width={50}
                            color="inherit"
                            style={{
                                transform: "rotate(180deg)",
                                cursor: "pointer",
                            }}
                            onClick={() => onSlide(selectedIdex + 1)}
                        />
                    </div>
                    {/* <CloseSVG
                        height={40}
                        width={40}
                        color='#0f556d'
                        style={{ cursor: "pointer" }}
                        onClick={onClose}
                    /> */}
                </div>
                {files.length === 0 ? (
                    <div
                        // className='image-stripe'
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "calc(100vh - 401px)",
                            backgroundColor: "#FFF",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            fontSize: "xx-large",
                            fontWeight: "600",
                            color: "#d1d1e8",
                        }}
                    >
                        {/* <Loading width='400px' height='200px' /> */}
                        <NoPhotoSVG height={250} width={250} color="#d1d1e8" />
                        <div>Pas de photos</div>
                    </div>
                ) : (
                    <div
                        style={{
                            height: "calc(100% - 100px)",
                        }}
                    >
                        <div
                            className="big-image-container"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                height: "78%",
                                position: "relative",
                                overflow: "hidden",
                            }}
                        >
                            <StripeImage
                                displayedImage={displayedImage}
                                files={files}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <div
                                    className="chevron-wrapper"
                                    style={{
                                        color: "#0f556d",
                                    }}
                                >
                                    <ChevronSVG
                                        height={100}
                                        width={100}
                                        color="inherit"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setDisplayedImage((prev: number) =>
                                                prev <= 0
                                                    ? files?.length - 1
                                                    : prev - 1,
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                className="image-container"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                }}
                            >
                                <img
                                    src={
                                        // "https://scontent.esperoo.fr/cdn-cgi/image/width=1200,quality=75/files/tally/" +
                                        "https://scontent.esperoo.fr/files/tally/" +
                                        files[displayedImage]?.file
                                    }
                                    alt="Description of the image"
                                    style={{
                                        // width: "100%",
                                        height: "100%",
                                        border: "2px solid white",
                                        borderRadius: "8px",
                                        transform: rotatePhoto
                                            ? "rotate(180deg)"
                                            : "",
                                    }}
                                />
                                <RotateSVG
                                    color="white"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        cursor: "pointer",
                                        opacity: "0.8",
                                    }}
                                    onClick={handleToggleImageRotation}
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%",
                                }}
                            >
                                <div
                                    className="chevron-wrapper"
                                    style={{
                                        color: "#0f556d",
                                    }}
                                >
                                    <ChevronSVG
                                        height={100}
                                        width={100}
                                        color="inherit"
                                        style={{
                                            transform: "rotate(180deg)",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setDisplayedImage((prev: number) =>
                                                prev >= files?.length - 1
                                                    ? 0
                                                    : prev + 1,
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                width: "100%",
                                height: "22%",

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: 10,
                            }}
                        >
                            {files?.map((item: any, index: number) => (
                                <div
                                    style={{
                                        height: "90%",
                                        borderRadius: "5px",
                                        position: "relative",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setDisplayedImage(index)}
                                >
                                    <div
                                        style={{
                                            height: "70%",
                                            width: "fit-content",
                                            padding:
                                                displayedImage === index
                                                    ? "8px"
                                                    : "0",
                                            backgroundColor:
                                                displayedImage === index
                                                    ? "#2a8bab"
                                                    : "transparent",
                                            borderRadius: "5px",
                                            transition: "0.1s ease",
                                        }}
                                    >
                                        {/* <div
                                            style={{
                                                position: "absolute",
                                                height: "100%",
                                                width: "100%",
                                                zIndex: "1",
                                                backgroundColor:
                                                    displayedImage !== index
                                                        ? "rgba(255,255,255, 0.3)"
                                                        : "transparent",
                                                borderRadius: "5px",
                                            }}
                                        ></div> */}
                                        <img
                                            src={
                                                // "https://scontent.esperoo.fr/cdn-cgi/image/width=1200,quality=75/files/tally/" +
                                                "https://scontent.esperoo.fr/files/tally/" +
                                                item?.file
                                            }
                                            alt="Description of the image"
                                            style={{
                                                // width: "100%",
                                                height: "100%",
                                                border: "1px solid white",
                                                borderRadius: "5px",
                                                transform: rotatePhoto
                                                    ? "rotate(180deg)"
                                                    : "",
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "fit-content",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: "14px",
                                            }}
                                        >
                                            {item?.type === "ARRIVER"
                                                ? "Arrivé"
                                                : item?.type === "ARRIVER_PAUSE"
                                                  ? "Arrivé pause"
                                                  : item?.type ===
                                                      "QUITTER_PAUSE"
                                                    ? "Départ pause"
                                                    : "Départ"}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            {item?.time}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
}

function StripeImage(props: any) {
    const { displayedImage, files } = props;
    return (
        <div className="image-stripe">
            <div className="left-side">
                <div>
                    {files[displayedImage]?.type === "ARRIVER" ||
                    files[displayedImage]?.type === "ARRIVER_PAUSE" ? (
                        <EnterSVG height={30} width={30} color="white" />
                    ) : (
                        <ExistSVG height={30} width={30} color="white" />
                    )}
                </div>
                <div>
                    <div
                        style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                        }}
                    >
                        {files[displayedImage]?.type === "ARRIVER"
                            ? "Arrivé"
                            : files[displayedImage]?.type === "ARRIVER_PAUSE"
                              ? "Arrivé pause"
                              : files[displayedImage]?.type === "QUITTER_PAUSE"
                                ? "Départ pause"
                                : "Départ"}
                    </div>
                    <div>Pointage effectué à {files[displayedImage]?.time}</div>
                </div>
            </div>
            <div className="right-side">
                <div>
                    <CameraSVG height={30} width={30} color="white" />
                </div>
                <div
                    style={{
                        width: "40px",
                    }}
                >
                    {displayedImage + 1}/{files.length}
                </div>
            </div>
        </div>
    );
}
