import React, { useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import Modal from "../../atoms/Modal";
import TextData from "../../atoms/TextData";
import SelectInput from "../../atoms/SelectInput";
import Input from "../../atoms/Input";
import Loading from "../../atoms/Loading";
import PopUpCard from "../../molecules/PopUpCard";
import { useEmployees } from "../../../hooks/useEmployees";
import { ReactComponent as CheckSVG } from "./check.svg";
import "../../../pages/Employees/EmployeesEditPage/style.css";
import Button from "../../atoms/Button";

const permissionLevels = [
    {
        label: "Employés",
        value: "forEmployees",
    },
    {
        label: "Managers",
        value: "forManagers",
    },
    // {
    //     label: "Administrateurs",
    //     value: "forAdministrators",
    // },
];

export default function AddFolderCard(props: AddFolderCardProps) {
    const { display, onClose, folderToEdit } = props;

    const [folderName, setFolderName] = React.useState<string>("");
    const [permissions, setPermissions] = React.useState<
        { label: string; value: string }[]
    >([]);
    const [isTemplateFolder, setIsTemplateFolder] = React.useState(false);

    const [isErrFileName, setIsErrFileName] = React.useState(false);
    const [isErrVisibility, setIsErrVisibility] = React.useState(false);

    const { createFolder, employeeForEdit, editFolder, getEmployeeDocuments } =
        useEmployees();
    useEffect(() => {
        if (folderToEdit) {
            setFolderName(folderToEdit.name);
            setPermissions(
                folderToEdit.permissions?.map(
                    (perm) =>
                        defaultPermissions.find(
                            (item) => item.value === perm,
                        ) ?? { label: perm, value: perm },
                ) ?? [],
            );
            setIsTemplateFolder(folderToEdit.employee === null);
        }
    }, [folderToEdit]);

    const [loading, setLoading] = React.useState(false);

    const submit = async (onlyThisEmployee: boolean = false) => {
        const hasError = false;
        if (hasError) {
            Swal.fire({
                title: "Erreur!",
                text: "Veuillez corriger les champs en rouge pour continuer",
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });

            return;
        }
        if (!folderName) {
            Swal.fire({
                title: "Erreur!",
                text: 'Le champ "nom du dossier" est obligatoire',
                icon: "error",
                timer: 1500,
                timerProgressBar: true,
            });
            return;
        }
        setLoading(true);
        if (folderToEdit) {
            // handle edit folder
            const res = await editFolder(
                folderToEdit._id,
                folderName,
                permissions.map((perm) => perm.value),
                onlyThisEmployee,
                employeeForEdit._id,
            );
            if (onlyThisEmployee) {
                await getEmployeeDocuments({ id: employeeForEdit._id });
            }
        } else {
            // handle create folder
            const res: any = await createFolder(
                folderName,
                permissions.map((perm) => perm.value),
                isTemplateFolder ? null : employeeForEdit._id,
            );
            if (res?.payload?.response?.data?.error) {
                Swal.fire({
                    title: "Erreur!",
                    text: res?.payload?.response?.data?.message,
                    icon: "error",
                    timer: 1500,
                    timerProgressBar: true,
                });
            }
        }

        setLoading(false);
        onClose && onClose();
    };

    const handleVisibilities = (visibility: any) => {
        if (visibility) {
            setIsErrVisibility(false);
        } else {
            setIsErrVisibility(true);
        }
    };
    const defaultPermissions = useMemo(() => {
        if (folderToEdit && Array.isArray(folderToEdit.permissions)) {
            return folderToEdit.permissions.map((perm) => {
                return (
                    permissionLevels.find((level) => level.value === perm) ?? {
                        label: perm,
                        value: perm,
                    }
                );
            });
        }
        return [];
    }, [folderToEdit]);
    // @ts-ignore
    return (
        <Modal modalEnabled={display} onClose={onClose}>
            <div className="duplicate-container">
                <PopUpCard
                    containerWidth="auto"
                    display={display}
                    onClose={onClose}
                    activateFooter={false}
                    header={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "25px auto 25px 40px",
                            }}
                        >
                            <TextData
                                variant="label"
                                width="auto"
                                size="22px"
                                fontWeight="700"
                                color="#2A346D"
                            >
                                {folderToEdit
                                    ? "Modifier un dossier"
                                    : "Ajouter un dossier"}
                            </TextData>
                        </div>
                    }
                    widthCloseIcon="24px"
                    styleCloseIcon={{
                        height: "auto",
                        marginRight: "37px",
                        width: "24px",
                        border: "1px solid #edeff533",
                        borderTopRightRadius: "20px",
                        cursor: "pointer",
                    }}
                    headerStyle={{
                        fontSize: "22px",
                        fontWeight: "700",
                        color: "#2A346D",
                    }}
                    popupHeaderStyle={{
                        height: "80px",
                        backgroundColor: "#edeff54c",
                        borderBottom: "1px solid #C7CBE2",
                    }}
                >
                    {loading ? (
                        <div style={{ height: 400 }}>
                            <Loading width="400px" height="200px" />
                        </div>
                    ) : (
                        <div className="add-document-form-container">
                            <div className="doument-name-container">
                                <Input
                                    type="text"
                                    placeholder="Nom du dossier"
                                    defaultValue={folderToEdit?.name}
                                    value={folderName}
                                    captionText="Nom du dossier"
                                    inputWidth="100%"
                                    borderColor="#C7CBE2"
                                    borderWidth={1}
                                    inputRadius="4px"
                                    textColor="#4C5690"
                                    textSize={14}
                                    iconLocation="right"
                                    style={{
                                        inputStyle: {
                                            minHeight: "48px",
                                            fontWeight: "400",
                                            paddingLeft: "15px",
                                            paddingRight: "15px",
                                        },
                                        captionStyle: {
                                            // fontWeight: "500",
                                        },
                                    }}
                                    maxTextLength={50}
                                    required
                                    regex={
                                        /^[a-zA-ZÉÈÇÀéèçà][a-zA-ZÉÈÇÀéèçà0-9 ]*$/
                                    }
                                    onChange={setFolderName}
                                />
                                {isErrFileName && (
                                    <TextData size="13px" color="#fb3640">
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            <div className="documents-categories-container">
                                <TextData
                                    width="auto"
                                    // fontWeight="700"
                                    variant="label"
                                    size="16px"
                                    color="#6F78AB"
                                >
                                    Droit d'accès
                                </TextData>
                                <SelectInput
                                    isMultiple
                                    title=""
                                    placeholder=""
                                    isSearchable={false}
                                    options={permissionLevels}
                                    //@ts-ignore
                                    selectedValue={defaultPermissions}
                                    onSelectOption={setPermissions}
                                />
                                {isErrVisibility && (
                                    <TextData size="13px" color="#fb3640">
                                        Ce champ est obligatoire
                                    </TextData>
                                )}
                            </div>
                            {!folderToEdit && (
                                <div className="documents-categories-container">
                                    <label
                                        className="custom-checkbox"
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            gap: 3,
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={isTemplateFolder}
                                            onChange={(e) =>
                                                setIsTemplateFolder(
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                        <div className="checkmark">
                                            <span>
                                                <CheckSVG />
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                fontWeight: "500",
                                                color: "#8E96C1",
                                            }}
                                        >
                                            Créer ce dossier pour tous les
                                            collaborateurs.
                                        </div>
                                    </label>
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className="popup-footer"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#2a346d",
                        }}
                    >
                        <Button
                            backgroundColor={"#edeff5"}
                            textColor={"#2a346d"}
                            onClick={onClose}
                            width="120px"
                            height="40px"
                            padding="3px 10px"
                            style={{
                                minWidth: 120,
                            }}
                        >
                            Annuler
                        </Button>
                        <Button
                            backgroundColor={
                                folderToEdit?.employee === null
                                    ? "rgb(250, 206, 168)"
                                    : "#2a8bab"
                            }
                            onClick={() => submit(false)}
                            width="auto"
                            style={{
                                minWidth: 120,
                            }}
                            height="40px"
                            padding="3px 10px"
                        >
                            {folderToEdit
                                ? folderToEdit.employee === null
                                    ? "Modifier pour tout le monde"
                                    : "Modifier"
                                : "Ajouter"}
                        </Button>
                        {folderToEdit && folderToEdit.employee === null && (
                            <Button
                                backgroundColor={"#2a8bab"}
                                onClick={() => submit(true)}
                                width="auto"
                                height="40px"
                                padding="3px 10px"
                                style={{
                                    minWidth: 120,
                                }}
                            >
                                Modifier pour {employeeForEdit.fname}{" "}
                                {employeeForEdit.lname}
                            </Button>
                        )}
                    </div>
                </PopUpCard>
            </div>
        </Modal>
    );
}

interface AddFolderCardProps {
    display: boolean;
    onClose?: () => void;
    folderToEdit?: {
        _id: string;
        name: string;
        permissions: string[];
        employee: null | any;
    };
}
