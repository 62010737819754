import React from "react";
import Employes from "../../components/templates/Employes";
import EmployeesActifTable from "./EmployeesActifTable";
import EmployeesArchiveTable from "./EmployeesArchiveTable";
import EmployeeCreate from "./EmployeesCreate";
import { useNavigate } from "react-router-dom";
import { useEmployees } from "../../hooks/useEmployees";
import RestoreEmployeeDialog from "./RestoreEmployeeDialog";
import ArchiveEmployeeDialog from "./ArchiveEmployeeDialog";
import { useBranch } from "../../hooks/useBranch";

export default function EmployesPage() {
    const navigate = useNavigate();
    const {
        totalArchived,
        totalEmployees,
        getEmployees,
        employees,
        archived,
        onUpdateFilterBy,
        filterBy,
        getPrimesTypes,
        sortBy,
        onUpdateSortBy,
        addEmployee,
    } = useEmployees();
    const { selectedBranchSettings } = useBranch();

    const [showCreateEmployee, setShowCreateEmployee] = React.useState(false);
    const [queryParams, setQueryParams] = React.useState({
        page: 1,
        sizePerPage: 20,
        totalEmployees: 0,
        totalArchived: 0,
    });
    const [employeeRestore, setEmployeeRestore] = React.useState<any>(null);
    const [employeeDelete, setEmployeeDelete] = React.useState<any>(null);

    React.useEffect(() => {
        setQueryParams((prev) => ({
            ...prev,
            page: 1,
            totalArchived,
            totalEmployees,
        }));
    }, [totalArchived, totalEmployees]);

    React.useEffect(() => {
        getEmployees({ archived: false });
        getEmployees({ archived: true });
        if (selectedBranchSettings && selectedBranchSettings._id) {
            getPrimesTypes({ siteId: selectedBranchSettings._id });
        }
        onUpdateFilterBy({
            ...filterBy,
            name: "",
            niveau: [],
            site: selectedBranchSettings._id
                ? selectedBranchSettings._id.toString()
                : undefined,
        });
    }, [selectedBranchSettings]);

    const onQueryParamsChange = (newParams: any) => {
        setQueryParams(newParams);
    };

    const onPageChange = (page: number) => {
        if (page !== queryParams.page) {
            setQueryParams((prev) => ({
                ...prev,
                page,
            }));
        }
    };

    const openCreateEmployeeDialog = () => {
        setShowCreateEmployee(true);
    };

    const openEmployeesRecord = () => {
        navigate(`/employees/register`);
    };

    const openEditEmployee = (id: any) => {
        navigate(`/employees/${id}/edit`);
    };

    const openArchiveEmployeeDialog = (data: any) => {
        setEmployeeDelete(data);
    };

    const openRestoreEmployeeDialog = (data: any) => {
        setEmployeeRestore(data);
    };

    const onHideFunc = async () => {
        getEmployees({ archived: true });
        getEmployees({ archived: false });
        setShowCreateEmployee(false);
    };
    // if (!selectedBranchSettings._id) {
    //     return (
    //         <div
    //             style={{
    //                 marginTop: "20px",
    //                 padding: "20px",
    //                 backgroundColor: "rgb(173 235 255)",
    //                 borderRadius: "8px",
    //                 color: "#4c5690",
    //             }}
    //         >
    //             Veuillez sélectionner un établissement
    //         </div>
    //     );
    // }

    return (
        <>
            <EmployeeCreate show={showCreateEmployee} onHide={onHideFunc} />

            {employeeRestore && (
                <RestoreEmployeeDialog
                    employee={employeeRestore}
                    show={employeeRestore ? true : false}
                    onHide={() => {
                        setEmployeeRestore(null);
                    }}
                />
            )}
            {employeeDelete && (
                <ArchiveEmployeeDialog
                    employee={employeeDelete}
                    show={employeeDelete ? true : false}
                    onHide={() => {
                        setEmployeeDelete(null);
                    }}
                />
            )}

            <Employes
                selectedSite={selectedBranchSettings}
                filterBy={filterBy}
                onUpdateFilterBy={onUpdateFilterBy}
                sortBy={sortBy}
                onUpdateSortBy={onUpdateSortBy}
                employeesCount={employees.length}
                archivedCount={archived.length}
                openAddEmployeePage={openCreateEmployeeDialog}
                openRecordPage={openEmployeesRecord}
                selectedBranch={selectedBranchSettings._id}
                FirstTabCompoenent={
                    <EmployeesActifTable
                        employees={employees}
                        onQueryParamsChange={onQueryParamsChange}
                        queryParams={{
                            ...queryParams,
                            sortField: sortBy.field,
                            sortOrder: sortBy.order,
                        }}
                        openArchiveEmployeeDialog={openArchiveEmployeeDialog}
                        openEditEmployee={openEditEmployee}
                        openCreateEmployeeDialog={openCreateEmployeeDialog}
                        onUpdateSortBy={onUpdateSortBy}
                    />
                }
                SecondTabCompoenent={
                    <EmployeesArchiveTable
                        archived={archived}
                        onQueryParamsChange={onQueryParamsChange}
                        queryParams={{
                            ...queryParams,
                            sortField: sortBy.field,
                            sortOrder: sortBy.order,
                        }}
                        openRestoreEmployeeDialog={openRestoreEmployeeDialog}
                        openEditEmployee={openEditEmployee}
                        openCreateEmployeeDialog={openCreateEmployeeDialog}
                        onUpdateSortBy={onUpdateSortBy}
                    />
                }
                paginationProps={{
                    paginationProps: {
                        ...queryParams,
                        onPageChange,
                        totalSize: 0,
                    },
                }}
            />
        </>
    );
}
