import React from "react";
import "./style.css";
import TextData from "../../components/atoms/TextData";
import { ReactComponent as EmailSVG } from "./email.svg";
import { ReactComponent as PhoneSVG } from "./phone.svg";
import { useBranch } from "../../hooks/useBranch";
import { useLogin } from "../../hooks/useLogin";
export default function Support() {
    const { selectedBranchSettings } = useBranch();
    const { user } = useLogin();

    const countryCode = React.useMemo(() => {
        const site = sessionStorage.getItem("selectedBranch");
        if (site) {
            const siteJSON = JSON.parse(site);
            return siteJSON.country;
        }
        const branchs = sessionStorage.getItem("branchs");
        const branchsJSON = JSON.parse(branchs || "");
        return branchsJSON?.[0]?.country ?? "FR";
    }, [selectedBranchSettings._id]);

    React.useEffect(() => {
        const currentUrl = window.location.href;
        if (
            currentUrl.endsWith("/support") &&
            countryCode === "FR" &&
            user.license._id !== "66a8c3840b6e133bb56e4e85"
        ) {
            if ((window as any).zE) {
                console.log("webWidget show");
                (window as any).zE("webWidget", "show");
            }
        }
        return () => {
            if ((window as any).zE) {
                console.log("webWidget hide");
                (window as any).zE("webWidget", "hide");
            }
        };
    }, [user, countryCode]);

    React.useEffect(() => {
        if (user.license._id === "66a8c3840b6e133bb56e4e85") {
            if ((window as any).zE) {
                console.log("webWidget hide");
                (window as any).zE("webWidget", "hide");
            }
        } else if (countryCode === "FR") {
            if ((window as any).zE) {
                console.log("webWidget show");
                (window as any).zE("webWidget", "show");
            }
        } else if (countryCode === "TN") {
            if ((window as any).zE) {
                console.log("webWidget hide");
                (window as any).zE("webWidget", "hide");
            }
        } else {
            if ((window as any).zE) {
                console.log("webWidget hide");
                (window as any).zE("webWidget", "hide");
            }
            localStorage.clear();
            sessionStorage.clear();
            window.location.reload();
            if (window.caches && window.caches.keys) {
                window.caches.keys().then(function (names) {
                    for (let name of names) {
                        window.caches.delete(name);
                    }
                });
            }
        }
    }, [countryCode, user]);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const setFullHeight = () => {
        if (containerRef.current) {
            containerRef.current.style.height = `${window.innerHeight - 100}px `;
        }
    };

    React.useEffect(() => {
        // Set full height on initial load
        setFullHeight();

        // Add event listeners for resize
        window.addEventListener("resize", setFullHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener("resize", setFullHeight);
        };
    }, []);
    return (
        <div
            ref={containerRef}
            style={{
                width: "100%",

                padding: "20px",

                // height: "calc(100% - 200px)",
                overflow: "auto",
            }}
        >
            <div
                style={{
                    width: "70%",
                    minWidth: "700px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <TextData
                    color="#11106E"
                    size="34px"
                    fontWeight="bold"
                    style={{
                        padding: "0",
                        margin: "15px 0 20px 0",
                    }}
                >
                    Support & assistance
                </TextData>
                <div
                    style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        padding: "25px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        gap: "15px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                >
                    <TextData
                        color="#11106E"
                        size="28px"
                        fontWeight="bold"
                        style={{
                            padding: "0",
                        }}
                    >
                        Contactez-nous
                    </TextData>
                    <TextData
                        color="#11106E"
                        size="22px"
                        style={{
                            padding: "0",
                        }}
                    >
                        Vous avez des questions concernant nos services ?
                        <br />
                        Notre équipe est toujours à votre disposition pour vous
                        aider.
                    </TextData>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "30px",
                        }}
                    >
                        <ContactSquare
                            icon={
                                <EmailSVG
                                    height={35}
                                    width={35}
                                    color="white"
                                />
                            }
                            title="Email"
                            desc="Ecrivez-nous sur l'adresse e-mail :"
                            contact={
                                <a
                                    href={
                                        user.license._id ===
                                        "66a8c3840b6e133bb56e4e85"
                                            ? "mailto:ventes@global-atb.com"
                                            : countryCode === "FR"
                                              ? "mailto:support@esperoo.fr"
                                              : "mailto:contact@esperoo.tn"
                                    }
                                >
                                    {user.license._id ===
                                    "66a8c3840b6e133bb56e4e85"
                                        ? "ventes@global-atb.com"
                                        : countryCode === "FR"
                                          ? "support@esperoo.fr"
                                          : "contact@esperoo.tn"}
                                </a>
                            }
                        />
                        <ContactSquare
                            icon={
                                <PhoneSVG
                                    height={35}
                                    width={35}
                                    color="white"
                                />
                            }
                            title="Appelez-nous"
                            desc={
                                user.license._id ===
                                "66a8c3840b6e133bb56e4e85" ? (
                                    <table>
                                        <tr>
                                            <td style={{ paddingRight: "7px" }}>
                                                Du lundi à samedi
                                            </td>
                                            <td>de 8h30 à 17h30</td>
                                        </tr>

                                        <tr>
                                            <td colSpan={2}>
                                                Fermé le dimanche
                                            </td>
                                        </tr>
                                    </table>
                                ) : countryCode === "FR" ? (
                                    <table>
                                        <tr>
                                            <td style={{ paddingRight: "7px" }}>
                                                Du lundi au jeudi
                                            </td>
                                            <td>de 8h à 18h</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: "7px" }}>
                                                Vendredi
                                            </td>
                                            <td>de 8h à 17h</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                Fermé le samedi et le dimanche
                                            </td>
                                        </tr>
                                    </table>
                                ) : (
                                    <table>
                                        <tr>
                                            <td style={{ paddingRight: "7px" }}>
                                                Du lundi au vendredi
                                            </td>
                                            <td>de 8h à 18h</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingRight: "7px" }}>
                                                Samedi
                                            </td>
                                            <td>de 9h à 13h</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                Fermé le dimanche
                                            </td>
                                        </tr>
                                    </table>
                                )
                            }
                            contact={
                                user.license._id === "66a8c3840b6e133bb56e4e85"
                                    ? "+237 696226472"
                                    : countryCode === "FR"
                                      ? "04 84 45 41 04"
                                      : "28 339 720"
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

const ContactSquare = (props: any) => {
    const { icon, contact, title, desc } = props;
    return (
        <div
            style={{
                backgroundColor: "#edeff5",
                borderRadius: "8px",
                padding: "25px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "15px",
                width: "calc(50% - 15px)",
                height: "315px",
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset",
            }}
        >
            <div
                style={{
                    backgroundColor: "#2a8bab",
                    width: "fit-content",
                    padding: "5px 8px",
                    borderRadius: "8px",
                    marginBottom: "15px",
                }}
            >
                {icon}
            </div>
            <TextData
                color="#11106E"
                size="22px"
                fontWeight="bold"
                style={{
                    padding: "0",
                }}
            >
                {title}
            </TextData>
            <TextData
                color="#11106E"
                size="18px"
                style={{
                    padding: "0",
                    marginBottom: "15px",
                    minHeight: "90px",
                }}
            >
                {desc}
            </TextData>
            <TextData
                color="#2a8bab"
                size="22px"
                fontWeight="bold"
                style={{
                    padding: "0",
                }}
            >
                {contact}
            </TextData>
        </div>
    );
};
