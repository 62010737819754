import moment from "moment";
import SelectInput from "../../../components/atoms/SelectInput";
import { extractNumber } from "../../../_helpers/Functions";

const DatePicker = ({ setUsedYear, usedYear }: DatePickerProps) => {
    const currentYear = moment().year();
    const currentBranchSettings = JSON.parse(
        sessionStorage.getItem("selectedBranch") || `{"created_at":"2018"}`,
    );
    const startYear = moment(currentBranchSettings.created_at).year();

    const years = [
        // { label: "Année", value: "Année" }
    ];

    for (let year = currentYear; year >= startYear; year--) {
        years.push({
            label: String(year),
            value: String(year),
        });
    }
    const handleSelectYear = (selectedOption: any) => {
        // // const selectedOption = e.options[e.selectedIndex];
        const selectedYear = selectedOption.value;

        if (extractNumber(selectedYear) === 0) {
            setUsedYear(null);
            return;
        }

        setUsedYear(selectedYear);
    };
    return (
        <SelectInput
            title=""
            options={years}
            selectedValue={{
                label: usedYear,
                value: usedYear,
            }}
            isSearchable={false}
            onSelectOption={handleSelectYear}
            style={{
                height: "40px",
                width: "130px",
                borderRadius: "9px",
                fontSize: "12px",
            }}
        ></SelectInput>
    );
};

export default DatePicker;
interface DatePickerProps {
    setUsedYear: (value: any) => void;
    usedYear: any;
}
