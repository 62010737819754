export const Pdf = ({ width = 24, height = 24 }: IconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.39999 4C2.39999 1.79086 4.19086 0 6.39999 0H14.4L21.6 7.2V20C21.6 22.2091 19.8091 24 17.6 24H6.39999C4.19085 24 2.39999 22.2091 2.39999 20V4Z"
            fill="#D92D20"
        />
        <path
            opacity="0.3"
            d="M14.4 0L21.6 7.2H18.4C16.1909 7.2 14.4 5.40914 14.4 3.2V0Z"
            fill="white"
        />
        <path
            d="M6 21V16H7.85463C8.21117 16 8.51492 16.0724 8.76588 16.2173C9.01683 16.3605 9.20811 16.5599 9.33971 16.8154C9.47284 17.0693 9.5394 17.3623 9.5394 17.6943C9.5394 18.0264 9.47207 18.3193 9.33741 18.5732C9.20275 18.8271 9.00765 19.0249 8.7521 19.1665C8.49809 19.3081 8.19051 19.3789 7.82938 19.3789H6.64728V18.5317H7.66871C7.85998 18.5317 8.0176 18.4967 8.14155 18.4268C8.26702 18.3551 8.36037 18.2567 8.42158 18.1313C8.48432 18.0044 8.51568 17.8587 8.51568 17.6943C8.51568 17.5283 8.48432 17.3835 8.42158 17.2598C8.36037 17.1344 8.26702 17.0376 8.14155 16.9692C8.01607 16.8993 7.85692 16.8643 7.66412 16.8643H6.99388V21H6Z"
            fill="white"
        />
        <path
            d="M11.8514 21H10.185V16H11.8651C12.338 16 12.745 16.1001 13.0863 16.3003C13.4275 16.4989 13.6899 16.7845 13.8736 17.1572C14.0587 17.5299 14.1513 17.9759 14.1513 18.4951C14.1513 19.016 14.0587 19.4635 13.8736 19.8379C13.6899 20.2122 13.426 20.4995 13.0817 20.6997C12.7389 20.8999 12.3288 21 11.8514 21ZM11.1788 20.0942H11.8101C12.1039 20.0942 12.351 20.0389 12.5515 19.9282C12.7534 19.8159 12.9049 19.6426 13.0059 19.4082C13.1085 19.1722 13.1597 18.8678 13.1597 18.4951C13.1597 18.1257 13.1085 17.8237 13.0059 17.5894C12.9049 17.355 12.7542 17.1825 12.5537 17.0718C12.3533 16.9611 12.1062 16.9058 11.8124 16.9058H11.1788V20.0942Z"
            fill="white"
        />
        <path
            d="M14.8875 21V16H18V16.8716H15.8814V18.063H17.7934V18.9346H15.8814V21H14.8875Z"
            fill="white"
        />
    </svg>
);

interface IconProps {
    width?: number;
    height?: number;
}
