import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnalyticsStateInterface } from "./type";
// import { fetchCountries } from "./service";

const initialState: AnalyticsStateInterface = {
    dateRange: {
        startDate: "",
        endDate: "",
    },
    data: null,
    loading: false,
    configs: null,
    hasConfig: null,
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setDateRange: (state, action) => {
            state.dateRange = action.payload;
        },
        setReportData: (state, action) => {
            if (action.payload?.data !== undefined) {
                state.data = action.payload?.data;
            }
            if (action.payload?.loading !== undefined) {
                state.loading = action.payload?.loading;
            }
        },
        setReportConfigs: (state, action) => {
            state.configs = action.payload;
        },
        setHasConfig: (state, action) => {
            console.log(" set config state =  ", action);
            state.hasConfig = action.payload;
        },
    },
    extraReducers: (build) => {},
});

export const { setDateRange, setReportData, setReportConfigs, setHasConfig } =
    analyticsSlice.actions;
const { reducer } = analyticsSlice;
export default reducer;
