import { useEffect } from "react";
import TimingComponent from "../../../../components/molecules/TimingComponent";
import usePointeuse from "../../../../hooks/usePointeuse";

export const WeekDate = (week: any, test: any) => {
    return (
        <div className="flex space-x-3 items-center justify-start ">
            <div
                className={` ${test?.payload?.data?.validated == true ? "bg-employee-op-2" : "bg-error-op-2"}  px-3 py-[10px]  rounded-lg text-n-700 font-bold`}
            >
                S{week?.weekNumber}
            </div>
            <TimingComponent startTime={week?.start} endTime={week?.end} />
        </div>
    );
};
