export function formatToThreeDecimals(num: number): string {
    // Parse the number to ensure it's a valid number
    const parsedNum: number = parseFloat(num.toString());

    // Check if the number is an integer
    if (Number.isInteger(parsedNum)) {
        return parsedNum.toString(); // Return as string without decimals
    }

    // Use toFixed(3) to format and then convert to a number to eliminate trailing zeros
    return parseFloat(parsedNum.toFixed(3)).toString();
}
