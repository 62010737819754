import { Pdf } from "../../../../Icons/PdfIcon";

interface DownloadButtonProps {
    validWeek: any;
    SetOpen: (value: any) => void;
    week: any;
    setSelectedWeek: (value: any) => void;
}
export const DownloadButton = ({
    validWeek,
    week,
    SetOpen,
    setSelectedWeek,
}: DownloadButtonProps) => {
    return (
        <>
            {validWeek?.payload?.data?.validated == true ? (
                <button
                    onClick={() => {
                        SetOpen((prev: boolean) => !prev);
                        setSelectedWeek(week);
                    }}
                    className="flex space-x-3 items-center justify-center bg-n-0 rounded-lg p-3 w-auto"
                >
                    <Pdf />
                    <p className="text-n-600 font-medium">Voir les documents</p>
                </button>
            ) : (
                <button className="flex items-center justify-center p-3  rounded-md transition space-x-1 bg-red-100 text-red-700 w-auto">
                    Documents non disponible
                </button>
            )}
        </>
    );
};
