import React, { useState } from "react";
import FeuillePopupCard from "../FeuillePopupCard";
import Modal from "../../../../components/atoms/Modal";
import { useReport } from "../../../../hooks/useReport";
import Swal from "sweetalert2";
import ProfilePicture from "../../../../components/molecules/ProfilePicture";
import { ROUTER } from "../../../../constants/env";
import { employeeRoleColor } from "../../../../_helpers/Functions";

const FeuillePopup = ({
    week,
    year,
    siteId,
    SetOpen,
    open,
    pointeuseEmployees,
}: FeuillePopupProps) => {
    const [employeeList, setEmployeeList] = useState([]);
    const [seperate, setSeperate] = useState(false);
    const [error, SetError] = useState(false);
    const [selectedEmployees, SetSelectedEmployees] = useState<any>([]);
    const [selectAll, setselectAll] = useState<boolean>(false);
    const { generateAttendanceSheet } = useReport();

    React.useEffect(() => {
        let newEmps: any = [];
        const sortedEmployees = [...pointeuseEmployees].sort((a, b) => {
            const nameA = `${a.fname} ${a.lname}`;
            const nameB = `${b.fname} ${b.lname}`;
            return nameA.localeCompare(nameB);
        });

        for (let i = 0; i < sortedEmployees?.length; i++) {
            const { fname, lname, _id, picture, role } = sortedEmployees[i];
            newEmps.push({
                name: fname + " " + lname,
                id: _id,
                picture: ROUTER.STATIC_IMG(picture),
                isSelected: false,
            });
        }
        setEmployeeList(newEmps);
    }, [pointeuseEmployees]);

    const onEmployeeItemClick = (employee: any) => {
        if (employee.checked) {
            SetSelectedEmployees((prevEmployees: any) => {
                const newEmp = [
                    ...prevEmployees,
                    {
                        id: employee.id,
                    },
                ];

                return newEmp;
            });
        } else {
            SetSelectedEmployees((prevEmployees: any) => {
                const newEmp = selectedEmployees.filter(
                    (emp: any) => emp.id !== employee.id,
                );

                return newEmp;
            });
        }
    };

    const handleOnSelectAllEmployees = (target: any) => {
        setselectAll(!selectAll);
        if (target.checked) {
            SetSelectedEmployees(() => {
                const empIDs = employeeList.map((user: any) => {
                    return { id: user.id };
                });
                return empIDs;
            });
        } else {
            SetSelectedEmployees(() => {
                return [];
            });
        }
    };

    const handleOnsubmitButton = async () => {
        if (selectAll || selectedEmployees.length > 0) {
            if (selectAll && selectedEmployees.length === employeeList.length) {
                generateAttendanceSheet({
                    weekNumber: week,
                    year,
                    siteId,
                });
            } else if (selectedEmployees.length >= 2) {
                const employeeIds = selectedEmployees.map(
                    (item: any) => item.id,
                );
                await generateAttendanceSheet({
                    weekNumber: week,
                    year,
                    siteId,
                    employeeId: employeeIds,
                    separate: false,
                });
            } else {
                const testing = selectedEmployees.map(async (item: any) => {
                    await generateAttendanceSheet({
                        weekNumber: week,
                        year,
                        siteId,
                        employeeId: item.id,
                        separate: true,
                    });
                });
                await Promise.all(testing);
            }
        } else {
            Swal.fire({
                title: "Erreur!",
                text: "aucun collaborator!",
                icon: "error",
                confirmButtonText: "Ok",
            });
        }
        SetError(false);
    };
    const checkError = () => {
        if (selectedEmployees.length == 0 && selectAll == false) {
            SetError(true);
        } else {
            SetError(false);
        }
        return error;
    };
    return (
        <div className="w-full">
            <Modal
                modalEnabled={open}
                onClose={() => SetOpen((prev: boolean) => !prev)}
            >
                <FeuillePopupCard
                    dispalayToggle={false}
                    seperate={seperate}
                    setSeperate={setSeperate}
                    employeeList={employeeList}
                    departemantInput={false}
                    headerCardTitle="Téléchargement des feuilles d'émargement"
                    onSelectAllEmployees={handleOnSelectAllEmployees}
                    onSubmitButton={handleOnsubmitButton}
                    onClickButton1={() => SetOpen((prev: boolean) => !prev)}
                    onClose={() => SetOpen((prev: boolean) => !prev)}
                    activateFooter={false}
                    currentbutton1Text={"Annuler"}
                    currentbutton2Text={"Télécharger"}
                    onEmployeeItemClick={onEmployeeItemClick}
                    errorHandler={checkError}
                />
            </Modal>
        </div>
    );
};

export default FeuillePopup;

interface FeuillePopupProps {
    week: number;
    year: number;
    siteId: string;
    SetOpen: (value: any) => void;
    open: boolean;
    pointeuseEmployees: any;
}
