import * as React from "react";
import { extractNumber } from "../../../_helpers/Functions";
import Alert from "../../../components/atoms/Alert";
import Input from "../../../components/atoms/Input";
import Radio from "../../../components/atoms/Radio";
import SelectInput from "../../../components/atoms/SelectInput";
import ToggleButton from "../../../components/atoms/Toggle";
import { useTimeTracker } from "../../../hooks/useTimeTracker";
import VisionSVG from "./vision.svg";
import IIcon from "../icons/iIcon.svg";
import { useBranch } from "../../../hooks/useBranch";
export const TimeTrackerProps = () => {
    const { timeTracker, saveTimeTracker } = useTimeTracker();
    const { selectedBranchSettings, branchSettings } = useBranch();
    const PhotoCaptureOnCheckInSettings = () => {
        if (timeTracker.takePhoto) {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Prendre une photo au moment du pointage",
                    optionDescription:
                        "Afin de s’assurer de la présence du salarié, vous pouvez activer cette option qui prendra une photo.Sachez que la photo est automatiquement supprimée au bout d’un mois ou si vous validez le pointage.Important : Assurez-vous d’informer vos salariés de l’activation de cette option et ne l’activez pas en permanence.  ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveTimeTracker({
                                    takePhoto: e.target.checked,
                                })
                            }
                            isChecked={timeTracker.takePhoto}
                        />
                    ),
                    optionInput2: (
                        <Radio
                            selectedValue="each-time"
                            items={[
                                // TODO: when it works we activate it
                                // {
                                //     itemValue: "random",
                                //     itemDesignation: "Aléatoire",
                                //     itemDescription:
                                //         "Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco cillum dolor. ",

                                // },
                                {
                                    itemValue: "each-time",
                                    itemDesignation: "à chaque pointage",
                                    itemDescription:
                                        "Permet de prendre une photo de l'employé à chaque pointage, afin de s'assurer de sa présence physique sur le lieu de travail. ",
                                },
                            ]}
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Prendre une photo au moment du pointage",
                    optionDescription:
                        "Afin de s’assurer de la présence du salarié, vous pouvez activer cette option qui prendra une photo.Sachez que la photo est automatiquement supprimée au bout d’un mois ou si vous validez le pointage.Important : Assurez-vous d’informer vos salariés de l’activation de cette option et ne l’activez pas en permanence.",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveTimeTracker({
                                    takePhoto: e.target.checked,
                                })
                            }
                            isChecked={timeTracker.takePhoto}
                        />
                    ),
                },
            ];
        }
    };
    const employeePauseHoursProps = () => {
        if (!branchSettings.usePause) return [];
        if (timeTracker.mustGoPause) {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Activer 'L'employé' doit partir en pause",
                    optionDescription:
                        "La pointeuse déterminera le moment où l’employé devrait prendre sa pause. Cela facilite la gestion du temps et des ressources. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveTimeTracker({
                                    mustGoPause: e.target.checked,
                                })
                            }
                            isChecked={timeTracker.mustGoPause}
                        />
                    ),
                },
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle:
                        "Nombre d'heures pour partir en pauses (en Heures)",
                    optionDescription:
                        "Exemple concret : Si cette valeur est définie à 6 heures, alors lorsqu'un salarié atteint ce seuil après son pointage d'entrée, la pointeuse émettra un message d'alerte accompagné d'un signal sonore pour lui rappeler qu'il doit partir en pause.",
                    optionInput: (
                        <Input
                            captionText={null}
                            inputWidth="100px"
                            placeholder=""
                            borderColor="gray"
                            borderWidth={1}
                            value={`${timeTracker.mustGoPauseTime}`}
                            type="text"
                            onChange={(value: any) =>
                                saveTimeTracker({
                                    mustGoPauseTime:
                                        extractNumber(value).toString(),
                                })
                            }
                            style={{
                                inputStyle: {
                                    padding: "10px 5px",
                                    outline: "none",
                                },
                            }}
                        />
                    ),
                },
            ];
        } else {
            return [
                {
                    titleColor: "#2A346D",
                    titleSize: "16px",
                    titleWeight: "bolder",
                    descriptionColor: "#6F78AB",
                    descriptionSize: "13",
                    descriptionWeight: "normal",
                    optionTitle: "Activer 'L'employé' doit partir en pause",
                    optionDescription:
                        "la pointeuse déterminera le moment où l’employé devrait prendre sa pause. Cela facilite la gestion du temps et des ressources. ",
                    optionInput: (
                        <ToggleButton
                            onClick={(e: any) =>
                                saveTimeTracker({
                                    mustGoPause: e.target.checked,
                                })
                            }
                            isChecked={timeTracker.mustGoPause}
                        />
                    ),
                },
            ];
        }
    };
    const [serialKeys, setSerialKeys] = React.useState(() => {
        const branchs = sessionStorage.getItem("branchs");
        if (!branchs) {
            return [
                {
                    label: "",
                    value: "",
                    serialKeys: "",
                },
            ];
        }
        const jsonBranchs = JSON.parse(branchs ?? "");
        if (jsonBranchs) {
            return jsonBranchs.map((branch: any) => {
                return {
                    label: branch.name,
                    value: branch._id,
                    serialKeys: branch.serialKey,
                };
            });
        } else {
            return [
                {
                    label: "",
                    value: "",
                    serialKeys: "",
                },
            ];
        }
    });
    const [selectedSerialKey, setselectedSerialKey] = React.useState(() => {
        const selectedBranch = sessionStorage.getItem("selectedBranch");
        if (!selectedBranch) {
            return {
                label: "",
                value: "",
                serialKeys: "",
            };
        }
        const jsonSelectedBranch = JSON.parse(selectedBranch ?? "");
        if (jsonSelectedBranch) {
            const sessionUser = sessionStorage.getItem("user");
            let sessionLicense: any[] = [];
            if (sessionUser) {
                sessionLicense = JSON.parse(sessionUser).realLicense;
            }
            if (sessionLicense.length > 1) {
                const LicenseEnseigne = sessionLicense.find((license: any) => {
                    return license._id === jsonSelectedBranch.license;
                });
                let enseigneName = "";
                if (LicenseEnseigne) {
                    enseigneName = LicenseEnseigne.enseigne;
                }
                return {
                    label: `${enseigneName} - ${jsonSelectedBranch.name}`,
                    value: jsonSelectedBranch._id,
                    serialKeys: "XXXX-XXXX-XXXX-XXXX",
                };
            } else {
                return {
                    label: jsonSelectedBranch.name,
                    value: jsonSelectedBranch._id,
                    serialKeys: "XXXX-XXXX-XXXX-XXXX",
                };
            }
        } else {
            return {
                label: "",
                value: "",
                serialKeys: "",
            };
        }
    });
    React.useEffect(() => {
        if (selectedBranchSettings._id) {
            setSerialKeys(() => {
                const branchs = sessionStorage.getItem("branchs");
                if (!branchs) {
                    return [
                        {
                            label: "",
                            value: "",
                            serialKeys: "",
                        },
                    ];
                }
                const listOfBranchs = JSON.parse(branchs ?? "");
                if (listOfBranchs) {
                    const sessionUser = sessionStorage.getItem("user");
                    let sessionLicense: any[] = [];
                    if (sessionUser) {
                        sessionLicense = JSON.parse(sessionUser).realLicense;
                    }
                    if (listOfBranchs && listOfBranchs !== "[]") {
                        if (listOfBranchs.length > 0) {
                            if (sessionLicense.length > 1) {
                                return [
                                    ...listOfBranchs.map((item: any) => {
                                        const LicenseEnseigne =
                                            sessionLicense.find(
                                                (license: any) => {
                                                    return (
                                                        license._id ===
                                                        item.license
                                                    );
                                                },
                                            );
                                        let enseigneName = "";
                                        if (LicenseEnseigne) {
                                            enseigneName =
                                                LicenseEnseigne.enseigne;
                                        }
                                        return {
                                            label: `${enseigneName} - ${item.name}`,
                                            value: item._id,
                                            serialKeys: item.serialKey,
                                        };
                                    }),
                                ];
                            } else {
                                return [
                                    ...listOfBranchs.map((item: any) => {
                                        return {
                                            label: item.name,
                                            value: item._id,
                                            serialKeys: item.serialKey,
                                        };
                                    }),
                                ];
                            }
                        }
                    }

                    // return jsonBranchs.map((branch: any) => {
                    //     return {
                    //         label: branch.name,
                    //         value: branch._id,
                    //         serialKeys: branch.serialKey,
                    //     };
                    // });
                } else {
                    return [
                        {
                            label: "",
                            value: "",
                            serialKeys: "",
                        },
                    ];
                }
            });
            setselectedSerialKey(() => {
                const selectedBranch = sessionStorage.getItem("selectedBranch");
                if (!selectedBranch) {
                    return {
                        label: "",
                        value: "",
                        serialKeys: "",
                    };
                }
                const jsonSelectedBranch = JSON.parse(selectedBranch ?? "");
                if (jsonSelectedBranch) {
                    const sessionUser = sessionStorage.getItem("user");
                    let sessionLicense: any[] = [];
                    if (sessionUser) {
                        sessionLicense = JSON.parse(sessionUser).realLicense;
                    }
                    if (sessionLicense.length > 1) {
                        const LicenseEnseigne = sessionLicense.find(
                            (license: any) => {
                                return (
                                    license._id === jsonSelectedBranch.license
                                );
                            },
                        );
                        let enseigneName = "";
                        if (LicenseEnseigne) {
                            enseigneName = LicenseEnseigne.enseigne;
                        }
                        return {
                            label: `${enseigneName} - ${jsonSelectedBranch.name}`,
                            value: jsonSelectedBranch._id,
                            serialKeys: "XXXX-XXXX-XXXX-XXXX",
                        };
                    } else {
                        return {
                            label: jsonSelectedBranch.name,
                            value: jsonSelectedBranch._id,
                            serialKeys: "XXXX-XXXX-XXXX-XXXX",
                        };
                    }
                } else {
                    return {
                        label: "",
                        value: "",
                        serialKeys: "",
                    };
                }
            });
        }
    }, [selectedBranchSettings._id]);

    const handleChangeSerialKey = (data: any) => {
        console.log("data", data);
        const foundChange = serialKeys.find(
            (serial: any) => serial.value === data.value,
        );
        if (foundChange) {
            console.log("see change", foundChange);
            setselectedSerialKey(foundChange);
            setTogglKeyVision(true);
        }
    };
    const [togglKeyVision, setTogglKeyVision] = React.useState(true);
    const ToggleSerialKey = () => {
        if (togglKeyVision) {
            handleChangeSerialKey(selectedSerialKey);
        } else {
            setselectedSerialKey({
                label: selectedSerialKey.label,
                value: selectedSerialKey.value,
                serialKeys: "XXXX-XXXX-XXXX-XXXX",
            });
        }
        setTogglKeyVision(!togglKeyVision);
    };
    if (timeTracker.useIt) {
        return [
            {
                headerTitle: "Application pointeuse",
                headerDescription:
                    "Soit vous activez la pointeuse pour avoir automatiquement les heures réellement travaillées et des notifications pour les retards des collaborateurs. Soit vous désactivez la pointeuse et le rapport sera complété par les heures du planning. Bien sûr, ça ne sera pas les heures réelles et il faut corriger manuellement les données.  ",
                configOptions: branchSettings.usePause
                    ? [
                          {
                              optionTitle: "Utiliser la pointeuse",
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionDescription:
                                  "Un employé doit pointer son entrée et sa sortie du travail à l'aide d'un système de pointage électronique. ",
                              optionInput: (
                                  <ToggleButton
                                      onClick={(e: any) =>
                                          saveTimeTracker({
                                              useIt: e.target.checked,
                                          })
                                      }
                                      isChecked={timeTracker.useIt}
                                  />
                              ),
                          },
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle: "Autoriser les pauses",
                              optionDescription:
                                  "Les collaborateurs auront la possibilité de badges les pauses sur la tablette. En plus des boutons (Arrivée, départ) vous aurez (Départ en pause) et (Retour de pause). ",
                              optionInput: (
                                  <ToggleButton
                                      onClick={(e: any) =>
                                          saveTimeTracker({
                                              allowPauses: e.target.checked,
                                          })
                                      }
                                      isChecked={timeTracker.allowPauses}
                                  />
                              ),
                          },
                          ...PhotoCaptureOnCheckInSettings(),
                          {
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionTitle:
                                  "Afficher les notes sur la pointeuse",
                              optionDescription: `A rajouter dans la description de la oage paramètre : Les notes ajoutées au niveau de votre page planning s'affichent directement sur la pointeuse. Chaque salarié peut ainsi consulter des informations importantes au moment de pointer, comme des consignes spécifiques ou des événements à venir (par exemple : "Réservation ce soir : groupe de 40 personnes")`,
                              optionInput: (
                                  <ToggleButton
                                      onClick={(e: any) =>
                                          saveTimeTracker({
                                              commentInPointeuse:
                                                  e.target.checked,
                                          })
                                      }
                                      isChecked={timeTracker.commentInPointeuse}
                                  />
                              ),
                          },
                      ]
                    : [
                          {
                              optionTitle: "Utiliser la pointeuse",
                              titleColor: "#2A346D",
                              titleSize: "16px",
                              titleWeight: "bolder",
                              descriptionColor: "#6F78AB",
                              descriptionSize: "13",
                              descriptionWeight: "normal",
                              optionDescription:
                                  "Un employé doit pointer son entrée et sa sortie du travail à l'aide d'un système de pointage électronique. ",
                              optionInput: (
                                  <ToggleButton
                                      onClick={(e: any) =>
                                          saveTimeTracker({
                                              useIt: e.target.checked,
                                          })
                                      }
                                      isChecked={timeTracker.useIt}
                                  />
                              ),
                          },

                          ...PhotoCaptureOnCheckInSettings(),
                      ],
            },
            {
                headerTitle: "Alerte pointage",
                headerDescription:
                    "Le système vous envoie une notification sur l’application « Esperoo » lorsqu'un pointage n'a pas été effectué afin de vous en informer. Ça peut être pour l’arrivée ou le départ d’un collaborateur. ",
                configOptions: [
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "M'alerter en cas de retard après (en minutes)",
                        optionDescription:
                            "Permet de définir une limite de temps pour les retards de vos employés. Vous pouvez spécifier le nombre de minutes de retard acceptables avant que vous soyez alerté. ",
                        optionInput: (
                            <Input
                                captionText={null}
                                inputWidth="100px"
                                placeholder=""
                                borderColor="gray"
                                borderWidth={1}
                                value={`${timeTracker.alerttime}`}
                                type="number"
                                regex={/^\d+$/}
                                onChange={(value: any) =>
                                    saveTimeTracker({
                                        alerttime:
                                            extractNumber(value).toString(),
                                    })
                                }
                                style={{
                                    inputStyle: {
                                        padding: "10px 5px",
                                        outline: "none",
                                    },
                                }}
                            />
                        ),
                    },
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "M'alerter si l'employé n'a pas dépointé au bout de (en minutes)",
                        optionDescription:
                            "Permet de définir une limite de temps pour le dépointage de vos collaborateurs. Exemple : Sébastien doit dépointer à 18h. Si j’indique 15mn, le système m’envoie une notification à 18h16 si Sébastien n’a pas dépointé.  ",
                        optionInput: (
                            <Input
                                captionText={null}
                                inputWidth="100px"
                                placeholder=""
                                borderColor="gray"
                                borderWidth={1}
                                value={`${timeTracker.alerttimego}`}
                                type="number"
                                regex={/^\d+$/}
                                onChange={(value: any) =>
                                    saveTimeTracker({
                                        alerttimego:
                                            extractNumber(value).toString(),
                                    })
                                }
                                style={{
                                    inputStyle: {
                                        padding: "10px 5px",
                                        outline: "none",
                                    },
                                }}
                            />
                        ),
                    },
                ],
            },
            {
                headerTitle: "Personnalisation des pointages",
                headerDescription:
                    "Afin d’adapter Esperoo à votre fonctionnement, vous avez la possibilité d’activer ou désactiver ces options. Sachez que dans la page pointeuse, la colonne « Shift badgé » affichera toujours le pointage du collaborateur et que les options affectent seulement la colonne « Shift réel ». ",
                configOptions: [
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Si Lara arrive avant l’heure planifiée, l’heure d’arrivée planifiée sera retenue",
                        optionDescription:
                            "Exemple : si Lara commence à 8h et qu’elle pointe à 7h30. Le système comptabilise 8h. Si vous décochez cette option, le système comptabilisera 7h30. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({
                                        allowCameBefore: e.target.checked,
                                    })
                                }
                                isChecked={timeTracker.allowCameBefore}
                            />
                        ),
                    },
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Si Lara arrive en retard, l’heure d'arrivée planifiée sera retenue",
                        optionDescription:
                            "Exemple : si Lara commence à 8h et qu’elle pointe à 8h15. Le système comptabilise 8h. Si vous décochez cette option, le système comptabilisera 8h15.",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({
                                        allowCameAfter: e.target.checked,
                                    })
                                }
                                isChecked={timeTracker.allowCameAfter}
                            />
                        ),
                    },
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Si Lara part avant l’heure planifiée, l’heure planifiée sera retenue",
                        optionDescription:
                            "Si Lara travaille selon un horaire planifié et qu'elle quitte son travail avant l'heure prévue, l'heure prévue sera toujours utilisée.",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({
                                        allowGoBefore: e.target.checked,
                                    })
                                }
                                isChecked={timeTracker.allowGoBefore}
                            />
                        ),
                    },
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Si Lara part après l’heure planifiée, l’heure planifiée sera retenue",
                        optionDescription:
                            " Si Lara travaille selon un horaire planifié et qu'elle quitte son travail après l'heure prévue, l'heure prévue sera toujours utilisée. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({
                                        allowGoAfter: e.target.checked,
                                    })
                                }
                                isChecked={timeTracker.allowGoAfter}
                            />
                        ),
                    },
                ],
            },
            {
                headerTitle: "Tolérance pointage",
                headerDescription:
                    "Permet de définir des seuils de tolérance pour le pointage des employés. Cette fonctionnalité est utile lorsque les employés arrivent en retard ou partent en avance par rapport à leur horaire de travail. Vous pouvez définir une limite de temps pour la tolérance. Par exemple, au-delà de 5mn le pointage sera considéré comme un retard ou un départ anticipé. ",
                configOptions: [
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Tolérance sur l’heure d’arrivée (en minutes)",
                        optionDescription:
                            "Correspond à une période de temps pendant laquelle un employé peut arriver en retard sans que cela soit considéré comme une absence ou un retard. ",
                        optionInput: (
                            <Input
                                captionText={null}
                                inputWidth="100px"
                                placeholder=""
                                borderColor="gray"
                                borderWidth={1}
                                value={`${timeTracker.toleranceCame}`}
                                type="number"
                                regex={/^\d+$/}
                                onChange={(value: any) =>
                                    saveTimeTracker({
                                        toleranceCame:
                                            extractNumber(value).toString(),
                                    })
                                }
                                style={{
                                    inputStyle: {
                                        padding: "10px 5px",
                                        outline: "none",
                                    },
                                }}
                            />
                        ),
                    },
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Tolérance sur l’heure de départ (en minutes)",
                        optionDescription:
                            "Correspond à une période de temps pendant laquelle un employé peut partir en avance sans que cela soit considéré comme une absence ou un départ anticipé.",
                        optionInput: (
                            <Input
                                captionText={null}
                                inputWidth="100px"
                                placeholder=""
                                borderColor="gray"
                                borderWidth={1}
                                value={`${timeTracker.toleranceGo}`}
                                type="number"
                                regex={/^\d+$/}
                                onChange={(value: any) =>
                                    saveTimeTracker({
                                        toleranceGo:
                                            extractNumber(value).toString(),
                                    })
                                }
                                style={{
                                    inputStyle: {
                                        padding: "10px 5px",
                                        outline: "none",
                                    },
                                }}
                            />
                        ),
                    },
                ],
            },
            {
                headerTitle: "",
                headerDescription: "",
                configOptions: [
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle:
                            "Activer 'Les commentaires' lors du pointage",
                        optionDescription:
                            "Permet lors du pointage des employés de laisser des notes ou des commentaires supplémentaires lorsqu'ils pointent, ce qui peut être utile pour fournir des informations importantes sur leur travail ou pour signaler des problèmes potentiels. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({
                                        comments: e.target.checked,
                                    })
                                }
                                isChecked={timeTracker.comments}
                            />
                        ),
                    },
                ],
            },
            //TODO: add other options
            {
                headerTitle: "Paramètres de Temps et Présence",
                headerDescription:
                    "Les Paramètres de Temps et Présence permettent de configurer les règles de gestion du temps de travail et du pointage dans Esperoo, afin d'assurer un suivi précis des heures travaillées et optimiser la gestion des présences dans votre équipe. ",
                configOptions:
                    branchSettings.usePause && branchSettings.useRepas
                        ? [
                              {
                                  titleColor: "#2A346D",
                                  titleSize: "16px",
                                  titleWeight: "bolder",
                                  descriptionColor: "#6F78AB",
                                  descriptionSize: "13",
                                  descriptionWeight: "normal",
                                  optionTitle:
                                      "Activer 'repas' lors du pointage",
                                  optionDescription:
                                      "Lorsque vos employés pointent en fin de journée, ils seront invités à indiquer s’ils ont pris un repas, en choisissant entre 0, 1 ou 2 repas. Ces données seront ensuite incluses dans les rapports pour une meilleure gestion.",
                                  optionInput: (
                                      <ToggleButton
                                          onClick={(e: any) =>
                                              saveTimeTracker({
                                                  repas: e.target.checked,
                                              })
                                          }
                                          isChecked={timeTracker.repas}
                                      />
                                  ),
                              },
                              ...employeePauseHoursProps(),
                              {
                                  titleColor: "#2A346D",
                                  titleSize: "16px",
                                  titleWeight: "bolder",
                                  descriptionColor: "#6F78AB",
                                  descriptionSize: "13",
                                  descriptionWeight: "normal",
                                  optionTitle:
                                      "Activer la signature pour l'arrivée et le départ",
                                  optionDescription:
                                      "Activez la fonction de signature pour enregistrer l’arrivée et le départ de l’employé. Une manière simple et sécurisée de valider les pointages. ",
                                  optionInput: (
                                      <ToggleButton
                                          onClick={(e: any) =>
                                              saveTimeTracker({
                                                  signatureDepartArriver:
                                                      e.target.checked,
                                              })
                                          }
                                          isChecked={
                                              timeTracker.signatureDepartArriver
                                          }
                                      />
                                  ),
                              },

                              {
                                  titleColor: "#2A346D",
                                  titleSize: "16px",
                                  titleWeight: "bolder",
                                  descriptionColor: "#6F78AB",
                                  descriptionSize: "13",
                                  descriptionWeight: "normal",
                                  optionTitle:
                                      "Activer la signature pour les pauses",
                                  optionDescription:
                                      "Activez la fonction de signature pour les pauses.Un moyen pratique de vérifier les temps de pause de l’employé. ",
                                  optionInput: (
                                      <ToggleButton
                                          onClick={(e: any) =>
                                              saveTimeTracker({
                                                  signaturePauses:
                                                      e.target.checked,
                                              })
                                          }
                                          isChecked={
                                              timeTracker.signaturePauses
                                          }
                                      />
                                  ),
                              },
                          ]
                        : !branchSettings.usePause && branchSettings.useRepas
                          ? [
                                {
                                    titleColor: "#2A346D",
                                    titleSize: "16px",
                                    titleWeight: "bolder",
                                    descriptionColor: "#6F78AB",
                                    descriptionSize: "13",
                                    descriptionWeight: "normal",
                                    optionTitle:
                                        "Activer 'repas' lors du pointage",
                                    optionDescription:
                                        "Lorsque vos employés pointent en fin de journée, ils seront invités à indiquer s’ils ont pris un repas, en choisissant entre 0, 1 ou 2 repas. Ces données seront ensuite incluses dans les rapports pour une meilleure gestion.",
                                    optionInput: (
                                        <ToggleButton
                                            onClick={(e: any) =>
                                                saveTimeTracker({
                                                    repas: e.target.checked,
                                                })
                                            }
                                            isChecked={timeTracker.repas}
                                        />
                                    ),
                                },

                                {
                                    titleColor: "#2A346D",
                                    titleSize: "16px",
                                    titleWeight: "bolder",
                                    descriptionColor: "#6F78AB",
                                    descriptionSize: "13",
                                    descriptionWeight: "normal",
                                    optionTitle:
                                        "Activer la signature pour l'arrivée et le départ",
                                    optionDescription:
                                        "Activez la fonction de signature pour enregistrer l’arrivée et le départ de l’employé. Une manière simple et sécurisée de valider les pointages. ",
                                    optionInput: (
                                        <ToggleButton
                                            onClick={(e: any) =>
                                                saveTimeTracker({
                                                    signatureDepartArriver:
                                                        e.target.checked,
                                                })
                                            }
                                            isChecked={
                                                timeTracker.signatureDepartArriver
                                            }
                                        />
                                    ),
                                },
                            ]
                          : branchSettings.usePause && !branchSettings.useRepas
                            ? [
                                  ...employeePauseHoursProps(),
                                  {
                                      titleColor: "#2A346D",
                                      titleSize: "16px",
                                      titleWeight: "bolder",
                                      descriptionColor: "#6F78AB",
                                      descriptionSize: "13",
                                      descriptionWeight: "normal",
                                      optionTitle:
                                          "Activer la signature pour l'arrivée et le départ",
                                      optionDescription:
                                          "Activez la fonction de signature pour enregistrer l’arrivée et le départ de l’employé. Une manière simple et sécurisée de valider les pointages. ",
                                      optionInput: (
                                          <ToggleButton
                                              onClick={(e: any) =>
                                                  saveTimeTracker({
                                                      signatureDepartArriver:
                                                          e.target.checked,
                                                  })
                                              }
                                              isChecked={
                                                  timeTracker.signatureDepartArriver
                                              }
                                          />
                                      ),
                                  },

                                  {
                                      titleColor: "#2A346D",
                                      titleSize: "16px",
                                      titleWeight: "bolder",
                                      descriptionColor: "#6F78AB",
                                      descriptionSize: "13",
                                      descriptionWeight: "normal",
                                      optionTitle:
                                          "Activer la signature pour les pauses",
                                      optionDescription:
                                          "Activez la fonction de signature pour les pauses.Un moyen pratique de vérifier les temps de pause de l’employé. ",
                                      optionInput: (
                                          <ToggleButton
                                              onClick={(e: any) =>
                                                  saveTimeTracker({
                                                      signaturePauses:
                                                          e.target.checked,
                                                  })
                                              }
                                              isChecked={
                                                  timeTracker.signaturePauses
                                              }
                                          />
                                      ),
                                  },
                              ]
                            : [
                                  {
                                      titleColor: "#2A346D",
                                      titleSize: "16px",
                                      titleWeight: "bolder",
                                      descriptionColor: "#6F78AB",
                                      descriptionSize: "13",
                                      descriptionWeight: "normal",
                                      optionTitle:
                                          "Activer la signature pour l'arrivée et le départ",
                                      optionDescription:
                                          "Activez la fonction de signature pour enregistrer l’arrivée et le départ de l’employé. Une manière simple et sécurisée de valider les pointages. ",
                                      optionInput: (
                                          <ToggleButton
                                              onClick={(e: any) =>
                                                  saveTimeTracker({
                                                      signatureDepartArriver:
                                                          e.target.checked,
                                                  })
                                              }
                                              isChecked={
                                                  timeTracker.signatureDepartArriver
                                              }
                                          />
                                      ),
                                  },
                              ],
            },
            {
                headerTitle: "Licence pointeuse",
                headerDescription:
                    "Si vous changez de tablette ou si vous souhaitez rajouter une autre pointeuse dans l’établissement. Vous pouvez retrouver facilement votre code licence. ",
                configOptions: [
                    {
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionTitle: "",
                        optionDescription: "",
                        optionInput: (
                            <SelectInput
                                options={serialKeys}
                                selectedValue={selectedSerialKey}
                                onSelectOption={handleChangeSerialKey}
                                style={{ width: "300px" }}
                            />
                        ),
                        optionInput2: (
                            <Input
                                iconImg={VisionSVG}
                                iconLocation="right"
                                onClickIconImg={ToggleSerialKey}
                                captionText="Clé de licence"
                                captionSize="12px"
                                captionColor="#8E96C1"
                                placeholder=""
                                value={selectedSerialKey.serialKeys}
                                // onChange={(value: any) =>
                                //     saveTimeTracker({
                                //         alerttime: extractNumber(value).toString(),
                                //     })
                                // }
                                inputWidth="300px"
                                borderColor="#C7CBE2"
                                borderWidth={1}
                            />
                        ),
                    },
                ],
            },
        ];
    } else {
        return [
            {
                headerTitle: "Application pointeuse",
                headerDescription:
                    "Permet aux employés de pointer leur arrivée et leur départ en utilisant une application mobile ou un appareil de pointage. Cette fonctionnalité permet à l'employeur de suivre facilement les heures de travail des employés, de calculer les heures supplémentaires, de générer des rapports pour le traitement de la paie, et de garantir que les employés respectent les heures de travail convenues. Les employés peuvent également utiliser l'application pour consulter leur horaire de travail, enregistrer leur temps de pause, et signaler toute absence ou retard. ",
                configOptions: [
                    {
                        optionTitle: "Utiliser la pointeuse",
                        titleColor: "#2A346D",
                        titleSize: "16px",
                        titleWeight: "bolder",
                        descriptionColor: "#6F78AB",
                        descriptionSize: "13",
                        descriptionWeight: "normal",
                        optionDescription:
                            "Un employé doit pointer son entrée et sa sortie du travail à l'aide d'un système de pointage électronique. ",
                        optionInput: (
                            <ToggleButton
                                onClick={(e: any) =>
                                    saveTimeTracker({ useIt: e.target.checked })
                                }
                                isChecked={timeTracker.useIt}
                            />
                        ),
                        optionInput2: (
                            <Alert
                                HeaderIcon={null}
                                HeaderTitle={null}
                                positionAbsolute={false}
                                backgroundColor="var(--t-0)"
                                borderRadius="9px"
                                borderWidth="0px"
                                icon={IIcon}
                                style={{
                                    bodyStyles: {
                                        color: "var(--t-600)",
                                        fontSize: "14px",
                                        fontWeight: "regular",
                                        marginRight: "10px",
                                    },
                                    titleStyles: {
                                        color: "var(--t-600)",
                                        fontSize: "22px",
                                        fontWeight: "bold",
                                    },
                                }}
                                title="Application pointeuse"
                                body="La ponctualité est une qualité professionnelle essentielle. Encouragez vos employés à arriver à l'heure en utilisant notre système de pointage."
                                width="90%"
                                closable={false}
                            />
                        ),
                    },
                ],
            },
        ];
    }
};
