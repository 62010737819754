import React from "react";
import { Moment } from "moment";
import {
    MONTHS,
    YEARS,
    BIRTH_YEARS,
    CONTRACT_YEARS,
    DOC_EXP_YEARS,
} from "../../../_helpers/CalendarHelpers";
import { ChevronRight, ChevronLeft } from "../../../Icons";
// import SelectInput from '../SelectInput';
import SelectInput from "../../atoms/SelectInput";

export default function CalendarHeader({
    value,
    nextMonth,
    prevMonth,
    selectYear,
    selectMonth,
    showMonthArrows = true,
    yearsType,
}: CalendarHearderInterface) {
    const onYearChange = (value: string | number) => {
        selectYear(value);
    };

    const onMonthChange = (value: string | number) => {
        selectMonth(value);
    };

    return (
        <div className="mb-2 flex px-3 gap-4 items-center">
            <div className="month">
                <SelectInput
                    title=""
                    options={MONTHS}
                    selectedValue={MONTHS[value.month()]}
                    onSelectOption={(opt) => onMonthChange(opt.value)}
                />
            </div>
            <div className="years">
                <SelectInput
                    title=""
                    options={
                        yearsType === "birth"
                            ? BIRTH_YEARS
                            : yearsType === "contract"
                              ? CONTRACT_YEARS
                              : yearsType === "doc-exp"
                                ? DOC_EXP_YEARS
                                : YEARS
                    }
                    selectedValue={{ label: value.year(), value: value.year() }}
                    onSelectOption={(opt) => onYearChange(opt.value)}
                />
            </div>
            <div
                className={`arrows flex ml-auto ${showMonthArrows ? "" : "hidden"}`}
            >
                <a
                    className="cursor-pointer text-n-700"
                    onClick={() => prevMonth()}
                >
                    <ChevronLeft />
                </a>
                <a
                    className="cursor-pointer text-n-700"
                    onClick={() => nextMonth()}
                >
                    <ChevronRight />
                </a>
            </div>
        </div>
    );
}

interface CalendarHearderInterface {
    value: Moment;
    nextMonth(): void;
    prevMonth(): void;
    selectMonth(month: string | number): void;
    selectYear(year: string | number): void;
    showMonthArrows?: boolean;
    yearsType?: string;
}
